import { HttpClient, HttpParams } from '@angular/common/http';
import { Market } from './market';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Underwriter } from './underwriter';
import { SearchResults } from './search-results';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MarketService = /** @class */ (function () {
    function MarketService(http) {
        this.http = http;
        this.savedCovers = [];
    }
    MarketService.prototype.searchMarkets = function (search) {
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http
            .post(environment.legacyRiskCoachApiBase + "/Market/MarketSearch", search, options)
            .pipe(map(function (d) {
            var results = new SearchResults();
            results.totalResults = d.totalResults;
            results.covers = d.covers.map(function (m) { return new Market().deserialize(m); });
            return results;
        }));
    };
    MarketService.prototype.getMarket = function (marketId) {
        var options = {
            params: new HttpParams().set('coverId', '' + marketId),
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get(environment.legacyRiskCoachApiBase + "/Market/GetCover", options).pipe(map(function (data) {
            return new Market().deserialize(data);
        }));
    };
    MarketService.prototype.getRecentCovers = function () {
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get(environment.legacyRiskCoachApiBase + "/Market/RecentCovers", options).pipe(map(function (d) {
            return d.map(function (data) {
                return new Market().deserialize(data);
            });
        }));
    };
    MarketService.prototype.getPopularCovers = function () {
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get(environment.legacyRiskCoachApiBase + "/Market/PopularCovers", options).pipe(map(function (d) {
            return d.map(function (data) {
                return new Market().deserialize(data);
            });
        }));
    };
    MarketService.prototype.saveCover = function (coverId, unsave) {
        var options = {
            params: new HttpParams().set('coverId', '' + coverId).set('unsave', '' + unsave),
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.put(environment.legacyRiskCoachApiBase + "/Market/SaveCover", '', options);
    };
    MarketService.prototype.getSavedCovers = function () {
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get(environment.legacyRiskCoachApiBase + "/Market/SavedCovers", options).pipe(map(function (d) {
            return d.map(function (data) {
                return new Market().deserialize(data);
            });
        }));
    };
    MarketService.prototype.getMarketsForInsurer = function () {
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get(environment.legacyRiskCoachApiBase + "/Market/GetCovers?insurer=true", options).pipe(map(function (res) {
            return res.map(function (item) {
                return new Market().deserialize(item);
            });
        }));
    };
    MarketService.prototype.getMarketsForInsurerId = function (insurerId) {
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get(environment.legacyRiskCoachApiBase + "/Market/GetCovers?insurerId=" + insurerId, options).pipe(map(function (res) {
            return res.map(function (item) {
                return new Market().deserialize(item);
            });
        }));
    };
    MarketService.prototype.getMarketsAwaitingApproval = function () {
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http
            .get(environment.legacyRiskCoachApiBase + "/Market/GetCovers?submittedForApproval=true", options)
            .pipe(map(function (res) {
            return res.map(function (item) {
                return new Market().deserialize(item);
            });
        }));
    };
    MarketService.prototype.getMarkets = function () {
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get(environment.legacyRiskCoachApiBase + "/Market/GetCovers?submittedForApproval=false&insurer=false", options)
            .pipe(map(function (res) {
            return res.map(function (item) {
                return new Market().deserialize(item);
            });
        }));
    };
    MarketService.prototype.deleteCover = function (coverId) {
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.delete(environment.legacyRiskCoachApiBase + "/Market/DeleteCover?coverId=" + coverId, options);
    };
    MarketService.prototype.getUnderwritersForInsurer = function (insurerId) {
        var options = {
            headers: { 'X-Legacy': 'true' },
            params: new HttpParams()
        };
        if (insurerId) {
            options.params = options.params.set('insurerId', '' + insurerId);
        }
        return this.http.get(environment.legacyRiskCoachApiBase + "/Market/GetMarketUnderwriters", options)
            .pipe(map(function (res) {
            return res.map(function (item) {
                return new Underwriter().deserialize(item);
            });
        }));
    };
    MarketService.prototype.deleteMarketUnderwriter = function (underwriterId) {
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.delete(environment.legacyRiskCoachApiBase + "/Market/DeleteMarketUnderwriter?underwriterId=" + underwriterId, options);
    };
    MarketService.prototype.replaceUnderwriter = function (oldUnderwriterId, newUnderwriterId) {
        var options = {
            headers: { 'X-Legacy': 'true' },
            params: new HttpParams().set('oldUnderwriterId', '' + oldUnderwriterId)
                .set('newUnderwriterId', '' + newUnderwriterId)
        };
        return this.http.put(environment.legacyRiskCoachApiBase + "/Market/ReplaceUnderwriter", '', options);
    };
    MarketService.prototype.updateMarketUnderwriter = function (underwriter) {
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.put(environment.legacyRiskCoachApiBase + "/Market/UpdateMarketUnderwriter", underwriter, options);
    };
    MarketService.prototype.addMarketUnderwriter = function (underwriter, insurerId) {
        var options = {
            headers: { 'X-Legacy': 'true' },
            params: new HttpParams()
        };
        if (insurerId) {
            options.params = options.params.set('insurerId', '' + insurerId);
        }
        return this.http.post(environment.legacyRiskCoachApiBase + "/Market/AddMarketUnderwriter", underwriter, options)
            .pipe(map(function (item) {
            return new Underwriter().deserialize(item);
        }));
    };
    MarketService.prototype.createMarketCover = function (market) {
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.post(environment.legacyRiskCoachApiBase + "/Market/CreateCover", market, options);
    };
    MarketService.prototype.updateMarketCover = function (market) {
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.put(environment.legacyRiskCoachApiBase + "/Market/UpdateCover", market, options);
    };
    //TODO: Move to state service
    MarketService.prototype.getStates = function (countryId) {
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get(environment.legacyRiskCoachApiBase + "/Market/GetStates?countryId=" + countryId, options);
    };
    MarketService.prototype.getIndustryHierarchy = function () {
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get(environment.legacyRiskCoachApiBase + "/Market/GetIndustryHierarchy", options);
    };
    MarketService.prototype.submitCover = function (coverId) {
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.put(environment.legacyRiskCoachApiBase + "/Market/SubmitCover?coverId=" + coverId, '', options);
    };
    MarketService.prototype.approveCover = function (market, approve) {
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.put(environment.legacyRiskCoachApiBase + "/Market/ApproveCover?approve=" + approve, market, options);
    };
    MarketService.prototype.contactUs = function (contactForm) {
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.post(environment.legacyRiskCoachApiBase + "/Market/ContactUs", contactForm, options);
    };
    MarketService.ngInjectableDef = i0.defineInjectable({ factory: function MarketService_Factory() { return new MarketService(i0.inject(i1.HttpClient)); }, token: MarketService, providedIn: "root" });
    return MarketService;
}());
export { MarketService };
