<div class="container">
  <h2>My Underwriters</h2>
  <p>Set up your underwriters who can then be assigned to your market covers.</p>
  <!-- add market button-->
  <div nz-row class="btn-container" nzType="flex" nzJustify="end">
    <button nz-button nzType="primary" nzSize="large" (click)="underwriterDefaultsVisible=true">
      <i nz-icon type="setting"></i>
      Default Settings
    </button>

    <button nz-button nzType="primary" nzSize="large" (click)="addUnderwriter()">
      <i nz-icon type="plus"></i>Add New
    </button>
  </div>
  <!-- end add market button-->

  <div>
    <app-insurer-select *ngIf="userService.isUserInRole('Administrator')" [(ngModel)]="insurer"></app-insurer-select>
  </div>

  <div *ngIf="underwriters && underwriters.length == 0" class="alert alert-warning text-center" role="alert">
    <b>You haven't created any underwriters! Add a new underwriter to get started.</b>
  </div>

  <nz-table #underwritersTable *ngIf="underwriters && underwriters.length > 0 && isMobile === false"
            [nzData]="underwriters">
    <thead>
      <tr class="row-head">
        <th>Name</th>
        <th>Email</th>
        <th>Phone</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let underwriter of underwritersTable.data" class="row-state">
        <td>{{ underwriter.name }}</td>
        <td>{{ underwriter.email }}</td>
        <td>{{ underwriter.phone }}</td>
        <td>
          <button nz-button nzShape="circle" (click)="editUnderwriter(underwriter)">
            <i nz-icon type="edit"></i>
          </button>
        </td>
        <td>
          <button nz-button nzShape="circle" (click)="confirmUserDelete(underwriter)">
            <i nz-icon type="delete"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>


  <!-- underwritter table for mobile screens -->
  <nz-table #underwritersTable *ngIf="underwriters && underwriters.length > 0 && isMobile === true"
            [nzData]="underwriters" class="ur-table">

    <tbody *ngFor="let underwriter of underwritersTable.data" class="ur-table-body">
      <tr class="ur-table-row-head">
        <td colspan="2">{{ underwriter.name }}</td>
      </tr>
      <tr>
        <td class="ur-table-header">Email</td>
        <td>{{ underwriter.email }}</td>
      </tr>
      <tr>
        <td class="ur-table-header">Phone</td>
        <td>{{ underwriter.phone }}</td>
      </tr>
      <tr>
        <td>
          <button nz-button nzShape="circle" (click)="editUnderwriter(underwriter)">
            <i nz-icon type="edit"></i>
          </button>
        </td>
        <td>
          <button nz-button nzShape="circle" (click)="confirmUserDelete(underwriter)">
            <i nz-icon type="delete"></i>
          </button>
        </td>
      </tr>
      <tr class="ur-table-divider">
        <td colspan="2"></td>
      </tr>
    </tbody>
  </nz-table>
</div>
<!-- end underwritter table for mobile screens -->
<!-- Delete confirm and underwriter replacement -->
<nz-modal [(nzVisible)]="replaceUnderwriterVisible" nzTitle="Replace underwriter"
          (nzOnCancel)="replaceUnderwriterVisible=false" (nzOnOk)="deleteUnderwriter()">
  <p>This underwriter is assigned to active markets. Please select a replacement underwriter:</p>
  <select name="underwriters" [(ngModel)]="newUnderwriter">
    <option *ngFor="let und of underwritersSelect" [ngValue]="und">
      {{ und.name }}, {{ und.email }}, {{ und.phone }}
    </option>
  </select>
</nz-modal>

<!-- Underwriter Defaults Edit -->
<nz-modal [(nzVisible)]="underwriterDefaultsVisible" (nzOnCancel)="cancelDefaultUnderwriters()"
          (nzOnOk)="saveDefaultUnderwriters()">
  <div nz-row nzType="flex" nzJustify="center">
    <div nz-col nzSpan="24">
      <nz-table #underwriterDefaults [nzData]="insuranceClasses" nzSize="small" nzFrontPagination="false"
                nzShowPagination="false">
        <thead>
          <tr class="row-head">
            <th>Insurance Class</th>
            <th>Underwriter</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let ic of underwriterDefaults.data" class="row-state">
            <td>{{ ic.name }}</td>
            <td>
              <label class="custom-select">
                <select *ngIf="defaultUnderwriters" name="underwriters2" [(ngModel)]="defaultUnderwriters[ic.id]">
                  <option *ngFor="let und of underwriters" [ngValue]="und">
                    {{ und.name }}, {{ und.email }}, {{ und.phone }}
                  </option>
                </select>
              </label>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</nz-modal>

<app-add-underwriter-modal [visible]="addUnderwriterVisible" [underwriter]="underwriterEdit"
                           (save)="onSaveUnderwriter($event)" (cancel)="onCancelUnderwriter($event)" [isOkLoading]="underwriterLoading">
</app-add-underwriter-modal>
