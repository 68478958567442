import { Component, OnInit, Input } from '@angular/core';
import { MarketService } from 'src/app/shared/market.service';
import { Market } from 'src/app/shared/market';

@Component({
  selector: 'app-save-cover',
  templateUrl: './save-cover.component.html',
  styleUrls: ['./save-cover.component.scss']
})

export class SaveCoverComponent implements OnInit {
  @Input() cover: Market;

  constructor(private marketService:MarketService) { }

  ngOnInit() {
  }

  saveCover(unsave:boolean): void {
    
    this.marketService.saveCover(this.cover.id, unsave)
      .subscribe();
    if (unsave) {
      this.marketService.savedCovers = this.marketService.savedCovers.filter(a => a.id != this.cover.id);
    }
    else {
      this.marketService.savedCovers.push(this.cover);
    }
    
  }

  isSaved(): boolean {
    if (this.marketService.savedCovers) {
      if (this.marketService.savedCovers.filter(c => c.id == this.cover.id).length > 0) {
        return true;
      }
    }
    return false;
  }

}
