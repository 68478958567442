<nz-select style="width: 100%"
           nzSize="large"
           nzMode="multiple"
           nzPlaceHolder="Select policies"
           [ngModel]="getSelectedPolicies()"
           (ngModelChange)="setSelectedPolicies($event)"
           [compareWith]="compareFn"
           nzNotFoundContent="No current policies found...">
  <nz-option *ngFor="let option of policyOptions" [nzLabel]="option.name" [nzValue]="option"></nz-option>
</nz-select>
