import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Policy } from './policy';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {

  constructor(private http: HttpClient) { }

  getPoliciesForInsurer(insurerId:number): Observable<Policy[]> {
    const options = {
      headers: { 'X-Legacy': 'true' }
    };
    return this.http.get<Policy[]>(`${environment.legacyRiskCoachApiBase}/Market/GetPolicies?insurerId=${insurerId}`, options); 
  }

}
