import { Injectable } from '@angular/core';
import { SearchRequest } from './search-request';

@Injectable({
  providedIn: 'root'
})
export class SearchFiltersService {
  searchRequest : SearchRequest;

  constructor() {
    this.searchRequest = new SearchRequest();
  }

  
}
