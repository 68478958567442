import { User } from './user';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserRole } from './user-role';
import { AppSettings } from './app-settings';
import { map, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./app-settings";
var UserService = /** @class */ (function () {
    function UserService(http, appSettings) {
        this.http = http;
        this.appSettings = appSettings;
    }
    UserService.prototype.login = function (user) {
        var _this = this;
        var body = "grant_type=password&username=" + user.userName + "&password=" + user.password + "&client_id=" + this.appSettings.clientId;
        var headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*'
        });
        return this.http.post(environment.authApiBase + "/token", body, { headers: headers }).pipe(map(function (d) {
            _this.setToken(d.access_token);
            return d.access_token;
        }));
    };
    UserService.prototype.setToken = function (token) {
        var expiryTime = new Date();
        var object = { value: token, timestamp: expiryTime.setHours(expiryTime.getHours() + 24) };
        window.localStorage.setItem(this.appSettings.authTokenKey, JSON.stringify(object));
    };
    UserService.prototype.getToken = function () {
        if (!this.isAuthenticated()) {
            return '';
        }
        return window.localStorage.getItem(this.appSettings.authTokenKey);
    };
    UserService.prototype.getTokenFromLinkgen = function (linkgenPayload) {
        var _this = this;
        var body = "grant_type=LinkgenUrl&client_id=" + this.appSettings.clientId + "&Encrypted_Data=" + linkgenPayload;
        var headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*'
        });
        //console.log('getting token');
        return this.http.post(environment.authApiBase + "/token", body, { headers: headers }).pipe(map(function (d) {
            //console.log(d.access_token);
            _this.setToken(d.access_token);
            return d.access_token;
        }));
    };
    UserService.prototype.isAuthenticated = function () {
        try {
            if (window.localStorage.getItem(this.appSettings.authTokenKey) != null) {
                var object = JSON.parse(window.localStorage.getItem(this.appSettings.authTokenKey));
                var date = Date.now();
                if (date <= object.timestamp) {
                    return true;
                }
            }
        }
        catch (e) {
            return false;
        }
        return false;
    };
    UserService.prototype.logout = function () {
        window.localStorage.removeItem(this.appSettings.authTokenKey);
        this.currentUser = undefined;
    };
    UserService.prototype.getUser = function () {
        var _this = this;
        var getHeaders = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });
        return this.http
            .get(environment.authApiBase + "/api/user", { headers: getHeaders })
            .pipe(map(function (r) {
            if (!_this.currentUser) {
                _this.currentUser = new User();
            }
            _this.currentUser.email = r.email;
            _this.currentUser.userName = r.userName;
            _this.currentUser.userRoles = r.roles.map(function (role) {
                return new UserRole(role.roleId, role.roleName);
            });
        }))
            .pipe(catchError(this.handleError));
    };
    UserService.prototype.isUserInRole = function (role) {
        if (!this.currentUser) {
            //user isn't yet loaded
            return false;
        }
        return this.currentUser.userRoles.filter(function (r) { return r.name == role; }).length > 0;
    };
    UserService.prototype.isAdmin = function () {
        return this.isUserInRole("Administrator");
    };
    UserService.prototype.refreshUserFromToken = function () {
        if (this.isAuthenticated()) {
            return this.getUser()
                .toPromise()
                .catch(function (err) { return Promise.resolve(); });
        }
        return Promise.resolve();
    };
    UserService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " + ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    };
    ///Gets current users' associated insurer ID (if entity exists)
    UserService.prototype.getAssociatedInsurerId = function () {
        var _this = this;
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get(environment.legacyRiskCoachApiBase + "/Market/GetAssociatedInsurerId", options).pipe(map(function (id) {
            if (_this.currentUser) {
                _this.currentUser.insurerId = id;
            }
            return id;
        }));
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.HttpClient), i0.inject(i2.AppSettings)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
