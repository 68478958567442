<app-banner></app-banner>
<div nz-row class="admin-menu-container" nzType="flex" nzJustify="space-around" nzAlign="middle">
  <div nz-col nzMd="24" nzLg="20">
    <ul class="cate" nz-menu [nzMode]="'horizontal'" nzTheme="dark">
      <li *ngIf="isUserInRole('MarketAdmin')" nz-menu-item routerLink="/admin/my-markets"><i nz-icon type="shop"></i> <span>My
          Markets</span></li>
      <li *ngIf="isUserInRole('MarketAdmin')" nz-menu-item routerLink="/admin/underwriters"><i nz-icon
          type="idcard"></i><span>My Underwriters</span></li>
  <li *ngIf="isUserInRole('Administrator')" nz-menu-item routerLink="/admin/approvals"><i nz-icon
          type="check-circle"></i> <span>Approvals</span></li>
    </ul>
  </div>
</div>
<nz-layout class="layout">
  <nz-content class="layout-content">
    <router-outlet></router-outlet>
  </nz-content>
</nz-layout>
