import { Component, OnInit } from '@angular/core';
import { Cover } from 'src/app/shared/cover';
import { Underwriter } from 'src/app/shared/underwriter';
import { State } from 'src/app/shared/state';
import { UnderwriterClassState } from 'src/app/shared/underwriter-class-state';
import { Market } from 'src/app/shared/market';
import { MarketService } from 'src/app/shared/market.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-add-market-detail',
  templateUrl: './add-market-detail.component.html',
  styleUrls: ['./add-market-detail.component.scss']
})

export class AddMarketDetailComponent implements OnInit {
  allUnderwriters: Underwriter[];
  editUnderwriter: UnderwriterClassState;
  originalUnderwriter: Underwriter;
  cover: Market = new Market(0, '');
  addStatesVisible: boolean = false;
  addClassesVisible: boolean = false;
  addUnderwriterVisible: boolean = false;
  underwriterLoading: boolean= false;

  editClass: Cover;
  activeStates: State[];
  activeClasses: Cover[];
  classError: boolean = false;
  stateError: boolean = false;

  validateForm: FormGroup;

  //get diagnostic() { return JSON.stringify(this.cover); }

  constructor(private marketService: MarketService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder) {
  }
  isMobile = false;
  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 992;
    console.log(w);
    if (w < breakpoint) {
      return true;
    } else {
      return false;
    }
  }

 

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.cover.id = Number(params['id']);
      this.getCover();
    });

    this.validateForm = this.fb.group({
      email: [null, [Validators.email, Validators.required]],
      name: [null, [Validators.required]],
      phone: [null, [Validators.required]],
    });

    this.isMobile = this.getIsMobile();
    window.onresize = () => {
      this.isMobile = this.getIsMobile();
    };
  }

  getCover(): void {
    this.marketService.getMarket(this.cover.id)
      .subscribe(c => {
        this.cover = c.getEditVersion();
        this.getAllUnderwriters(c.insurer.id);
      });
  }


  showAddStates(insuranceClass:Cover): void {
    this.addStatesVisible = true;
    this.activeStates = this.cover.getStatesForInsuranceClass(insuranceClass.id);
    this.editClass = insuranceClass;
  }

  addStatesOk(): void {
    if (this.activeStates.length == 0) {
      this.stateError = true;
      return;
    }
    this.addStatesVisible = false;
    this.stateError = false;
    let underwriterSetsforClass = this.cover.getUnderwritersForClass(this.editClass.id);  
    let defaultUnderwriter = this.getDefaultUnderwriterForClass(this.editClass.id);
    let activeStateCodes = this.activeStates.map(s => s.shortName);

    //remove states that aren't in new set
    let deletedSets = underwriterSetsforClass.filter(set => !activeStateCodes.includes(set.state.shortName));
    this.cover.classStateUnderwriters = this.cover.classStateUnderwriters.filter(set => !deletedSets.includes(set));

    //add states that are new
    let existingStateCodes = underwriterSetsforClass.map(a => a.state.shortName);
    let newStates = this.activeStates.filter(s => !existingStateCodes.includes(s.shortName));

    newStates.forEach(s => {
      this.cover.classStateUnderwriters.push(new UnderwriterClassState(defaultUnderwriter, this.editClass, s));
    })
  }

  cancelStates(): void {
    this.stateError = false
    this.addStatesVisible = false;
  }

  showAddClasses(): void {
    this.addClassesVisible = true;
    this.activeClasses = this.cover.getCovers();
  }

  addClassesOk(): void {
    if (this.activeClasses.length == 0) {
      this.classError = true;
      return;
    }
    this.addClassesVisible = false;
    this.classError = false;
    //remove classes that aren't in new set
    let updatedClassIds = this.activeClasses.map(a => a.id);
    let deletedSets = this.cover.classStateUnderwriters.filter(a => !updatedClassIds.includes(a.insuranceClass.id));
    this.cover.classStateUnderwriters = this.cover.classStateUnderwriters.filter(set => !deletedSets.includes(set));

    //add classes that are in new set
    let existingClassIds = this.cover.classStateUnderwriters.map(a => a.insuranceClass.id);
    let newClasses = this.activeClasses.filter(c => !existingClassIds.includes(c.id));
    
    newClasses.forEach(c => {
      let underwriter = this.getDefaultUnderwriterForClass(c.id);
      let state = this.cover.classStateUnderwriters[0].state;
      this.cover.classStateUnderwriters.push(new UnderwriterClassState(underwriter, c, state));
    })
  }

  addClassesCancel(): void {
    this.addClassesVisible = false;
    this.classError = false;
  }

  getAllUnderwriters(insurerId:number): void {
    this.marketService.getUnderwritersForInsurer(insurerId)
      .subscribe(u => this.allUnderwriters = u);
  }

  getDefaultUnderwriterForClass(classId: number): Underwriter {
    if (!this.allUnderwriters || this.allUnderwriters.length === 0) return new Underwriter();

    var underwriters = this.allUnderwriters.filter(u => u.isDefaultForInsuranceClass(classId));
    if (underwriters && underwriters.length > 0) {
      return underwriters[0];
    }
    else {
      return this.allUnderwriters[0];
    }
  }

  onSaveUnderwriter(u: Underwriter): void {
    this.underwriterLoading = true;
    this.marketService.addMarketUnderwriter(u, this.cover.insurer.id)
      .subscribe(() => {
        this.marketService.getUnderwritersForInsurer(this.cover.insurer.id)
          .subscribe(und => {
            this.allUnderwriters = und;
            this.underwriterLoading = false;
            this.addUnderwriterVisible = false;
            this.editUnderwriter.underwriter = this.allUnderwriters.filter(a => a.name == u.name && a.email == u.email && a.phone == u.phone)[0];
          });
      });
  }


  onCancelUnderwriter(c: boolean): void {
    this.addUnderwriterVisible = false;
  }

  saveAndExit(): void {
    //if (!this.isCoverValid()) return;
    this.marketService.updateMarketCover(this.cover).subscribe();
    this.router.navigate(['admin']);
  }

  saveAndNext(): void {
    //if (!this.isCoverValid()) return;
    this.marketService.updateMarketCover(this.cover)
      .subscribe(() => {
        this.router.navigate(['admin/add-market-industry', this.cover.id]);
      });
  }

  saveAndBack(): void {
    //if (!this.isCoverValid()) return;
    this.marketService.updateMarketCover(this.cover)
      .subscribe(() => {
        this.router.navigate(['admin/edit-market', this.cover.id]);
      });
  }

  //isCoverValid(): boolean {
  //  let invalidUnderwriters = this.cover.classStateUnderwriters.filter(set => !set.underwriter.id);
  //  return invalidUnderwriters.length === 0;
  //}

  setEditUnderwriter(ucs: UnderwriterClassState): void {
    this.editUnderwriter = ucs;
    this.originalUnderwriter = ucs.underwriter;
  }

  cancelEdit(): void {
    this.editUnderwriter.underwriter = this.originalUnderwriter;
    this.editUnderwriter = null
  }

  cancel(): void {
    this.router.navigate(['admin']);
  }

  compareFn = (o1: Underwriter, o2: Underwriter) => o1 && o2 ? o1.id === o2.id : o1 === o2;

}
