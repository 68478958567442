<nz-table #marketTable *ngIf="markets && markets.length > 0 && !isMobile" [nzData]="markets"
  class="market-table">
  <thead>
    <tr class="row-head">
      <th>Name</th>
      <th>Provider</th>
      <th>Status</th>
      <th>Approval Notes</th>
      <th></th>
      <th></th>
      <th></th>
      <th colspan="2" *ngIf="allowApprovals">Approve</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let market of marketTable.data" class="row-state">
      <td *ngIf="showDraftData">{{ market.draftName() }}</td>
      <td *ngIf="!showDraftData">{{ market.name }}</td>
      <td>{{market.insurer.name}}</td>
      <td>{{ market.getStatus() }}</td>
      <td>{{ market.approverNote }}</td>
      <td>
        <button *ngIf="showView" nz-button nzShape="circle" [routerLink]="['/admin/cover-changes',market.id]" ><i nz-icon type="eye"></i></button>
      </td>
      <td>
        <button nz-button nzShape="circle" [routerLink]="['/admin/edit-market', market.id]">
          <i nz-icon type="edit"></i>
        </button>
      </td>
      <td>
        <button nz-button nzShape="circle" (click)="deleteCover(market)"><i nz-icon type="delete"></i></button>
        
      </td>
      <td *ngIf="allowApprovals">
        <button  *ngIf="market.submittedForApproval" nz-button nzShape="circle" class='btn-check' (click)="approve(market,true)"><i nz-icon type="check"></i></button>
      </td> 
      <td *ngIf="allowApprovals">  
        <button *ngIf="market.submittedForApproval" nz-button nzShape="circle" nzType="danger"  (click)="approve(market,false)"><i nz-icon type="close"></i></button>
      </td>
    </tr>
  </tbody>
</nz-table>

<nz-modal [(nzVisible)]="selectedMarket" (nzOnCancel)="selectedMarket=undefined" (nzOnOk)="submitApproval()">
  <h2 *ngIf="isApprove">Approve changes?</h2>
  <h2 *ngIf="!isApprove">Reject changes?</h2>
  Comments: <textarea *ngIf="selectedMarket" nz-input [(ngModel)]="selectedMarket.approverNote"></textarea>
</nz-modal>

<!-- market table for mobile screens -->
<nz-table #marketTable *ngIf="markets && markets.length > 0 && isMobile" [nzData]="markets" class="market-table">
  <tbody *ngFor="let market of marketTable.data"  class="market-table-body">
    <tr class="market-table-row-head">      
      <td colspan="2" *ngIf="showDraftData">{{ market.draftName() }}</td>
      <td colspan="2" *ngIf="!showDraftData">{{ market.name }}</td>
    </tr>
    <tr>
      <td class="market-table-header">Provider</td>
      <td>{{market.insurer.name}}</td>
    </tr>
    <tr>
      <td class="market-table-header">Status</td>
      <td>{{ market.getStatus() }}</td>
    </tr>
    <tr>
      <td class="market-table-header">Approval Notes</td>
      <td>{{ market.approverNote }}</td>
    </tr>
    <tr>
      <td>
        <button nz-button nzShape="circle" [routerLink]="['/admin/edit-market', market.id]">
          <i nz-icon type="edit"></i>
        </button>
      </td>
      <td>
        <button nz-button nzShape="circle" (click)="deleteCover(market)"><i nz-icon type="delete"></i></button>
      </td>
      
    </tr>
    <tr>     
    <td *ngIf="allowApprovals && market.submittedForApproval">
        <button nz-button nzShape="circle" class='btn-check' (click)="approve(market,true)"><i  nz-icon type="check"></i></button>
      </td>
      <td *ngIf="allowApprovals && market.submittedForApproval">   
        <button nz-button nzShape="circle"  nzType="danger" (click)="approve(market,false)"><i nz-icon type="close"></i></button>
      </td>
    </tr>
    <tr class="market-table-divider">
        <td></td>
        <td></td>
      </tr>
  </tbody>
</nz-table>
<!-- end market table for mobile screens -->
