import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    constructor() { }

    removeDuplicatesById(arr: any[]): any[] {
        return arr.filter((obj, index, self) => self.findIndex(t => t.id === obj.id) === index);
    }

    getUrlParameter(url: string, parameter: string): string {
        let querystring = url.split("?");
        if (querystring.length > 1) {
            let vars = querystring[1].split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == parameter) {
                    return pair[1];
                }
            }
        }
        return undefined;
    }

    ///Parameter list in format of x=somedata&y=moredata. Supports linkgen payload extraction.
    getParameterFromParameterList(parameterList: string, parameter: string): string {
        let vars = parameterList.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == parameter) {
                return pair[1];
            }
        }
        return undefined;
    }


    removeUrlParameter(url: string, parameter: string): string {
        let querystring = url.split("?");
        let newUrl = querystring[0];
        let isFirst = true;
        if (querystring.length > 1) {
            let vars = querystring[1].split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] != parameter) {
                    if (isFirst) {
                        newUrl = newUrl + '?';
                        isFirst = false;
                    }
                    else {
                        newUrl = newUrl + '&';
                    }
                    newUrl = newUrl + pair[0] + '=' + pair[1];
                }
            }
        }
        return newUrl;
    }

    addUrlParameter(url: string, parameter: string, value: string) {
        let querystring = url.split("?");
        if (querystring.length > 1) {
            return url + "&" + parameter + "=" + value;
        }
        return url + "?" + parameter + "=" + value;
    }
}
