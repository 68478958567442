import { OnInit } from '@angular/core';
import { MarketService } from 'src/app/shared/market.service';
import { UserService } from '../../shared/user.service';
import { Insurer } from '../../shared/insurer';
import { Router } from '@angular/router';
var MarketsComponent = /** @class */ (function () {
    function MarketsComponent(marketService, userService, router) {
        this.marketService = marketService;
        this.userService = userService;
        this.router = router;
    }
    Object.defineProperty(MarketsComponent.prototype, "insurer", {
        get: function () {
            return this._insurer;
        },
        set: function (insurer) {
            this._insurer = insurer;
            this.getMarkets();
        },
        enumerable: true,
        configurable: true
    });
    MarketsComponent.prototype.ngOnInit = function () {
        this.getMarkets();
        this.insurer = new Insurer(this.userService.currentUser.insurerId);
    };
    MarketsComponent.prototype.getMarkets = function () {
        var _this = this;
        if (this.userService.isUserInRole('Administrator') && this.insurer) {
            this.marketService.getMarketsForInsurerId(this.insurer.id)
                .subscribe(function (markets) {
                _this.markets = markets;
            });
        }
        else {
            this.marketService.getMarketsForInsurer()
                .subscribe(function (markets) {
                _this.markets = markets;
            });
        }
    };
    MarketsComponent.prototype.addMarket = function () {
        if (this.userService.isUserInRole('Administrator') && this.insurer) {
            this.router.navigate(['/admin/add-market'], { queryParams: { insurer: this.insurer.id } });
        }
        else {
            this.router.navigate(['/admin/add-market']);
        }
    };
    return MarketsComponent;
}());
export { MarketsComponent };
