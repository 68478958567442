import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { Cover } from "../../shared/cover";
import { CoverService } from "../../shared/cover.service";

import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';


@Component({
  selector: 'app-cover-search',
  templateUrl: './cover-search.component.html',
  styleUrls: ['./cover-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoverSearchComponent),
      multi: true
    }
  ]
})

export class CoverSearchComponent implements OnInit, ControlValueAccessor { 
  @Input()
  _selected: Cover[] = undefined;

  coverOptions:Cover[] = [];

  constructor(private coverService: CoverService) {
    this.onChange = (_: any) => { };
  }

  //get diagnostic() { return JSON.stringify(this._selected); }

ngOnInit(): void {
  this.getCovers();
  }

  getCovers(): void {
    this.coverService.getCovers()
      .subscribe(ics => this.coverOptions=ics);
  }

  getSelectedCovers() {
    return this._selected;
  }

  setSelectedCovers(_: Cover[]) {
    this._selected = _.filter(c=>c);  //remove nulls from select all option
    if (typeof _ === "object") {
      this.onChange(this._selected);
    }
  }

  private onChange: (_: any) => void;

  writeValue(value: any) {
    if (value !== undefined) {
      this._selected = value;
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  selectAll(): void {
    if (this._selected && this._selected.length === this.coverOptions.length) {
      this._selected = null;
    }
    else {
      this._selected = this.coverOptions;
    }
  }

  registerOnTouched() { }

  
  compareFn = (o1: Cover, o2: Cover) => o1 && o2 ? o1.id === o2.id : o1 === o2;
}
