import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { SearchModule } from './search/search.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faTwitter, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N, en_US } from 'ng-zorro-antd';
import { AppRoutingModule } from './app-routing.module';
import { TokenInterceptor } from './core/token.interceptor';
import { SharedModule } from './shared/shared.module';
import { AdminModule } from './admin/admin.module';
import { ContactModule } from './contact/contact.module';
import { UserService } from './shared/user.service';
import { Location } from '@angular/common';
import { UtilityService } from './shared/utility.service';
import { HttpResponseInterceptor } from './core/http-response.interceptor';

library.add(faFacebookF, faTwitter, faLinkedin, faYoutube);


///Check for auth token or linkgen payload at app init and authenticate user
export function checkUserToken(userService: UserService, location: Location, utilityService: UtilityService) {
    return () => {
        let token = utilityService.getUrlParameter(location.path(), "tok");
        if (token) {
            userService.setToken(token);
            var url = utilityService.removeUrlParameter(location.path(), "tok");
            url = utilityService.addUrlParameter(url, "tokLogin", "true");
            location.replaceState(url);
        }
        else {
            let linkgen = utilityService.getUrlParameter(location.path(), "linkgen");
            if (linkgen) {
                //console.log(linkgen);
                let linkgenDecoded = atob(linkgen);
                //console.log(linkgenDecoded);
                let payload = utilityService.getParameterFromParameterList(linkgenDecoded, 'EncryptedData');
                userService.getTokenFromLinkgen(payload).subscribe(() => {
                    return userService.refreshUserFromToken();
                })
            }
        }
        return userService.refreshUserFromToken();
    };
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FontAwesomeModule,
        AppRoutingModule,
        AdminModule,
        SearchModule,
        SharedModule,
        ContactModule
    ],
    providers: [{
        provide: NZ_I18N,
        useValue: en_US
    }, {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpResponseInterceptor,
        multi: true
    },
    {
        provide: APP_INITIALIZER,
        useFactory: checkUserToken,
        deps: [UserService, Location, UtilityService],
        multi: true
    }],
    bootstrap: [AppComponent]
})
export class AppModule { }
