import { Component, OnInit } from '@angular/core';
import { Market } from '../../shared/market';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { MarketService } from '../../shared/market.service';
import { Policy } from '../../shared/policy';

@Component({
  selector: 'app-cover-changes',
  templateUrl: './cover-changes.component.html',
  styleUrls: ['./cover-changes.component.scss']
})
export class CoverChangesComponent implements OnInit {
  cover: Market;

  constructor(private marketService: MarketService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        this.getCover(Number(params['id']));
      }
    });
  }

  getCover(id: number): void {
    this.marketService.getMarket(id)
      .subscribe(cov => {
        this.cover = cov;
      });
  }

  arePoliciesDifferent(): boolean {
    if (!this.cover || !this.cover.draft) {
      return true;
    }
    let ids1 = this.cover.policies.map(p => p.id);
    let ids2 = this.cover.draft.policies.map(p => p.id);
    let difference = ids1.filter(x => !ids2.includes(x)).concat(ids2.filter(x => !ids1.includes(x)));
    return difference.length > 0;
  }

  areUnderwritersDifferent(): boolean {
    if (!this.cover || !this.cover.draft) {
      return true;
    }
    let ids1 = this.cover.classStateUnderwriters.map(p => p.insuranceClass.id.toString + p.state.shortName + p.underwriter.id.toString());
    let ids2 = this.cover.draft.classStateUnderwriters.map(p => p.insuranceClass.id.toString + p.state.shortName + p.underwriter.id.toString());
    let difference = ids1.filter(x => !ids2.includes(x)).concat(ids2.filter(x => !ids1.includes(x)));
    return difference.length > 0 || ids1.length!=ids2.length;
  }

  areIndustriesDifferent(): boolean {
    if (!this.cover || !this.cover.draft) {
      return true;
    }
    let ids1 = this.cover.industries.map(p => p.id);
    let ids2 = this.cover.draft.industries.map(p => p.id);
    let difference = ids1.filter(x => !ids2.includes(x)).concat(ids2.filter(x => !ids1.includes(x)));
    return difference.length > 0;
  }

}
