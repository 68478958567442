<div nz-row nzType="flex" nzJustify="center">
 <div nz-col  nzXs="22" nzSm="20" nzMd="18" nzLg="12" nzXl="12" id="home" class="loginPage">
  <div class="overlay">
    <!-- Overlay Color -->
    <div class="container">
      <!-- container -->
      <div class="content-heading text-center">

        <div class="login-page">
          <!-- LOGIN SECTION -->
          <div class="form login-form">
            <h2>Login</h2>
            
            <form nz-form (ngSubmit)="submitForm()" [formGroup]="validateForm">
              <nz-form-item>
                <nz-form-control>
                  <nz-input-group [nzPrefix]="prefixUser">
                    <input type="text" nz-input formControlName="userName" placeholder="Username">
                  </nz-input-group>
                  <nz-form-explain *ngIf="validateForm.get('userName').dirty && validateForm.get('userName').errors">Please input your username!</nz-form-explain>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control>
                  <nz-input-group [nzPrefix]="prefixLock">
                    <input type="password" nz-input formControlName="password" placeholder="Password">
                  </nz-input-group>
                  <nz-form-explain *ngIf="validateForm.get('password').dirty && validateForm.get('password').errors">Please input your Password!</nz-form-explain>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control>
                  <!--<label nz-checkbox formControlName="remember">
    <span>Remember me</span>
  </label>-->
                  <!--<a class="login-form-forgot" class="login-form-forgot">Forgot password</a>-->
                  
                  <button nz-button class="login-form-button" [nzType]="'primary'">Log in</button>
                </nz-form-control>
              </nz-form-item>
              {{message}}
              <p>Please login with your LMI RiskCoach credentials.</p>
            <a style="text-decoration:underline;" nz-tooltip nzTitle="Login to LMI RiskCoach via your members portal, select an industry and click the link to The Market.">Don't have credentials?</a>
            </form>
            <ng-template #prefixUser><i nz-icon type="user"></i></ng-template>
            <ng-template #prefixLock><i nz-icon type="lock"></i></ng-template>

          </div>
        </div>
        <!-- End LOGIN Here -->
      </div>
    </div>
  </div>
</div>
</div>

