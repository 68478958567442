import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { State } from 'src/app/shared/state';

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationSearchComponent),
      multi: true
    }
  ]
})
export class LocationSearchComponent implements OnInit, ControlValueAccessor {
  @Input()
  _selected: State[] = undefined;
  allChecked: boolean = false;

  constructor() {
    this.onChange = (_: any) => { };
  }

  ngOnInit() {
  }


  selectState(state: string) {
    if (!this._selected) {
      this._selected = [];
    }
    if (this._selected.filter(s => s.shortName === state).length>0) {
      this._selected = this._selected.filter(s => s.shortName != state);
    }
    else {
      this._selected.push(new State(state));
    }
    this.onChange(this._selected);
    this.allChecked = this._selected.length === 8;
  }

  fillState(state: string) {
     if (this._selected && this._selected.filter(s=>s.shortName===state).length>0) {
      return "#f1c400";
    }
  }

  private onChange: (_: any) => void;

  writeValue(value: any) {
    if (value !== undefined) {
      this._selected = value;
      if (this._selected) {
        this._selected.forEach(s => this.fillState(s.shortName));
      }
    }
  }

  selectAll(): void {
    
    if (this._selected && this._selected.length === 8) {
      this._selected = [];
    }
    else {
      this._selected = [];
      this._selected.push(new State('NSW'));
      this._selected.push(new State('VIC'));
      this._selected.push(new State('TAS'));
      this._selected.push(new State('QLD'));
      this._selected.push(new State('ACT'));
      this._selected.push(new State('WA'));
      this._selected.push(new State('SA'));
      this._selected.push(new State('NT'));
    }
    this.onChange(this._selected);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched() { }

}
