import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Observable, Subject } from "rxjs";

import { Industry } from "../../shared/industry";
import { IndustryService } from "../../shared/industry.service";
import { debounceTime, distinctUntilChanged, switchMap, finalize, tap } from "rxjs/operators";

@Component({
  selector: "app-industry-search",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./industry-search.component.html",
  styleUrls: ["./industry-search.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IndustrySearchComponent),
      multi: true
    }
  ]
})
export class IndustrySearchComponent implements OnInit, ControlValueAccessor {
  industries$: Observable<Industry[]>;
  isLoading: boolean;
  searchNoData: string = "Start typing to search...";

  //get diagnostic() { return JSON.stringify(this.industries$); }

  @Input()
  _selected: Industry[] = undefined;

  @Input() size: string = "large";

  getSelectedIndustries() {
    return this._selected;
  }

  setSelectedIndustries(_: any) {
    this._selected = _;
    if (typeof _ === "object") {
      this.onChange(this._selected);
    }
  }

  private searchTerms = new Subject<string>();

  private onChange: (_: any) => void;


  constructor(private industryService: IndustryService) {
    this.onChange = (_: any) => { };
  }


  search(term: string): void {
    if (term) {
      this.searchNoData = "No industries found";
    }
    this.searchTerms.next(term);
  }

  displayIndustryName(industry?: Industry): string | undefined {
    return industry ? industry.name : undefined;
  }

  ngOnInit(): void {
    this.industries$ = this.searchTerms.pipe(
      debounceTime(300),
      tap(() => this.isLoading = true),
      distinctUntilChanged(),
      switchMap((term: string) => this.industryService.searchIndustries(term)
        .pipe(
          finalize(() => this.isLoading = false),
        )
      )
    );
  }

  searchMessage(): string {
    return "Nothing";
  }


  writeValue(value: any) {
    if (value !== undefined) {
      this._selected = value;
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched() { }
}
