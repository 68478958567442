import { Cover } from "./cover";
import { Serializable } from "./serializable";

export class Underwriter implements Serializable<Underwriter> {
  constructor(
    public id?: number,
    public name?: string,
    public email?: string,
    public phone?: string,
    public insuranceClasses?: Cover[],
    public approved?: boolean,
    public hasMarketsAssigned?: boolean
  ) { }

  deserialize(object: any): Underwriter {
    this.id = object.id;
    this.name = object.name;
    this.email = object.email;
    this.phone = object.phone;
    this.insuranceClasses = object.insuranceClasses ? object.insuranceClasses.map(ic => new Cover().deserialize(ic)):null;
    this.approved = object.approved;
    this.hasMarketsAssigned = object.hasMarketsAssigned;
    return this;
  }

  isDefaultForInsuranceClass(classId: number): boolean {
    let classIds = this.insuranceClasses.map(a => a.id);
    return classIds.includes(classId);

  }
}
