import { Serializable } from "./serializable";

export class State implements Serializable<State> {
  constructor(
    public shortName?: string,
    public name?: string,
    public id?: number    //TODO: refactor out ID or if needed, put first in constructor
  ) { }

  deserialize(object: any): State {
    this.shortName = object.shortName;
    this.name = object.name;
    this.id = object.id;
    return this;
  }

  
}
