import { OnInit } from '@angular/core';
import { IndustryTree } from 'src/app/shared/industry';
import { MarketService } from 'src/app/shared/market.service';
import { NzTreeNode, NzMessageService } from 'ng-zorro-antd';
import { ControlValueAccessor } from '@angular/forms';
var IndustryMultiselectComponent = /** @class */ (function () {
    //get diagnostic() { return JSON.stringify(this.readOnly); }
    function IndustryMultiselectComponent(marketService, message) {
        this.marketService = marketService;
        this.message = message;
        this._selected = undefined;
        this.enableSearch = true;
        this.readOnly = false;
        this.industries = new IndustryTree(); //full data model
        this.isLoading = true;
        this.onChange = function (_) { };
    }
    Object.defineProperty(IndustryMultiselectComponent.prototype, "searchIndustries", {
        get: function () {
            return this._searchIndustries;
        },
        set: function (searchIndustries) {
            this._searchIndustries = [];
            if (searchIndustries && searchIndustries.length === 1) {
                this.selectIndustryFromSearch(searchIndustries[0]);
            }
        },
        enumerable: true,
        configurable: true
    });
    IndustryMultiselectComponent.prototype.ngOnInit = function () {
        this.getIndustries();
    };
    IndustryMultiselectComponent.prototype.getIndustries = function () {
        var _this = this;
        this.marketService.getIndustryHierarchy()
            .subscribe(function (i) {
            _this.industries.IndustryTreeNodes = i;
            _this.setTreeSelections();
            _this.isLoading = false;
        });
    };
    IndustryMultiselectComponent.prototype.generateParentNodes = function () {
        var _this = this;
        var tnArray = [];
        this.industries.IndustryTreeNodes.forEach(function (i) {
            var tn = new NzTreeNode({ 'key': i.id.toString(), 'title': i.name });
            tn.isSelectable = false;
            tn.setChecked(i.selected, i.halfSelected);
            tn.isDisableCheckbox = _this.readOnly;
            tnArray.push(tn);
        });
        return tnArray;
    };
    //Check the selected industry from search results in data models
    IndustryMultiselectComponent.prototype.selectIndustryFromSearch = function (industry) {
        this.industries.setSelected(true, industry.id);
        if (this.updateUIIndustryModel(industry.id)) {
            this.message.success("Industry added!");
            this._selected = this.industries.getSelected();
            this.onChange(this._selected);
        }
        else {
            this.message.error("Industry not available.");
        }
    };
    IndustryMultiselectComponent.prototype.updateUIIndustryModel = function (industryId) {
        var anzsic = this.industries.getParent(industryId);
        if (!anzsic) { //industry not found
            return false;
        }
        var alpha = this.industries.getParent(anzsic.id);
        for (var _i = 0, _a = this.nodes; _i < _a.length; _i++) {
            var alphaNode = _a[_i];
            if (alphaNode.key === alpha.id.toString()) {
                alphaNode.setChecked(alpha.selected);
                alphaNode.isHalfChecked = alpha.halfSelected;
            }
            if (alphaNode.children) {
                for (var _b = 0, _c = alphaNode.children; _b < _c.length; _b++) {
                    var anzsicNode = _c[_b];
                    if (anzsicNode.key === anzsic.id.toString()) {
                        anzsicNode.setChecked(anzsic.selected);
                        anzsicNode.isHalfChecked = anzsic.halfSelected;
                    }
                    if (anzsicNode.children) {
                        for (var _d = 0, _e = anzsicNode.children; _d < _e.length; _d++) {
                            var riscNode = _e[_d];
                            if (riscNode.key === industryId.toString()) {
                                riscNode.setChecked(true);
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return true;
    };
    IndustryMultiselectComponent.prototype.writeValue = function (value) {
        if (value) {
            this._selected = value;
            this.setTreeSelections();
        }
    };
    //Setup initial top level nodes and whether selected (or half-selected, etc)
    IndustryMultiselectComponent.prototype.setTreeSelections = function () {
        var _this = this;
        if (this.industries.IndustryTreeNodes && this._selected) {
            this._selected.forEach(function (i) {
                _this.industries.setSelected(true, i.id);
            });
        }
        this.nodes = this.generateParentNodes();
    };
    IndustryMultiselectComponent.prototype.setCheckedIndustries = function (e) {
        this.industries.setSelected(e.node.isChecked, Number(e.node.key)); //sync data model with changes on the UI
        this._selected = this.industries.getSelected();
        this.onChange(this._selected);
    };
    IndustryMultiselectComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    IndustryMultiselectComponent.prototype.registerOnTouched = function () { };
    //Add/remove child nodes to UI model as user expands/shrinks to keep UI performant
    IndustryMultiselectComponent.prototype.nzEvent = function (event) {
        if (event.eventName === 'expand') {
            var node = event.node;
            if (node && node.getChildren().length === 0 && node.isExpanded) {
                node.addChildren(this.getChildNodes(Number(node.key)));
            }
            if (node && !node.isExpanded) {
                node.clearChildren();
            }
        }
    };
    //Retrieve child nodes from data model and add to UI model
    IndustryMultiselectComponent.prototype.getChildNodes = function (industryId) {
        var _this = this;
        var childNodes = this.industries.getChildren(industryId);
        var nodeTree = [];
        childNodes.forEach(function (c) {
            var tn = new NzTreeNode({ 'key': c.id.toString(), 'title': c.name });
            tn.isSelectable = false;
            tn.setChecked(c.selected, c.halfSelected);
            tn.isDisableCheckbox = _this.readOnly;
            if (!c.childIndustries) {
                tn.isLeaf = true;
            }
            nodeTree.push(tn);
        });
        return nodeTree;
    };
    return IndustryMultiselectComponent;
}());
export { IndustryMultiselectComponent };
