import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Underwriter } from 'src/app/shared/underwriter';

@Component({
  selector: 'app-add-underwriter-modal',
  templateUrl: './add-underwriter-modal.component.html',
  styleUrls: ['./add-underwriter-modal.component.scss']
})
export class AddUnderwriterModalComponent implements OnInit {
  @Input() visible: boolean;
  private _underwriter: Underwriter = new Underwriter(0, '', '', '');
  @Input()
  set underwriter(underwriter: Underwriter) {
    this._underwriter = underwriter;
    this.setValues();
  }
  get underwriter(): Underwriter { return this._underwriter;}
    
  validateForm: FormGroup;
  @Input() isOkLoading: boolean;
  @Output() save = new EventEmitter<Underwriter>();
  @Output() cancel = new EventEmitter<boolean>();


  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.validateForm = this.fb.group({
      email: [null, [Validators.email, Validators.required]],
      name: [null, [Validators.required]],
      phone: [null, [Validators.required]],
    });

    
  }

  setValues(): void {
    if (this.validateForm) {
      this.validateForm.get('name').setValue(this.underwriter.name);
      this.validateForm.get('email').setValue(this.underwriter.email);
      this.validateForm.get('phone').setValue(this.underwriter.phone);
    }
  }

  onSubmit(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.valid) {
      this.underwriter.name = this.validateForm.get('name').value;
      this.underwriter.email = this.validateForm.get('email').value;
      this.underwriter.phone = this.validateForm.get('phone').value;
      this.save.emit(this.underwriter);
      this.validateForm.reset();
    }
  }

  resetForm(): void {
    this.cancel.emit(true);
    this.validateForm.reset();
  }

}
