import { Industry } from "./industry";
import { Policy } from "./policy";
import { UnderwriterClassState } from "./underwriter-class-state";
import { Underwriter } from "./underwriter";
import { Cover } from "./cover";
import { State } from "./state";
import { Serializable } from "./serializable";
import { Insurer } from "./insurer";

export class Market implements Serializable<Market> {
  constructor(
    public id?: number,
    public name?: string,
    public insurer? : Insurer,
    public brochureURL?: string,
    public note?: string,
    public industries?: Industry[],
    public classStateUnderwriters?: UnderwriterClassState[],
    public policies?: Policy[],
    public approverNote?: string,
    public draft?: Market,
    public approved?: boolean,
    public submittedForApproval?: boolean,
    
  ) { }

  deserialize(object: any): Market {
    this.id = object.id;
    this.name = object.name;
    this.insurer = object.insurer;
    this.brochureURL = object.brochureUrl;
    this.note = object.note;
    this.industries = object.industries;
    this.classStateUnderwriters = object.classStateUnderwriters ? object.classStateUnderwriters.map(c => new UnderwriterClassState().deserialize(c)) : null;
    this.policies = object.policies;
    this.approverNote = object.approverNote;
    this.draft = object.draft? new Market().deserialize(object.draft):null;
    this.approved = object.approved;
    this.submittedForApproval = object.submittedForApproval;
    return this;
  }


  getUnderwriters(): Underwriter[] {
    if (!this.classStateUnderwriters || this.classStateUnderwriters.length == 0) {
      return null;
    }
    return this.removeDuplicatesById(this.classStateUnderwriters.map(u => u.underwriter));
  }

  getCovers(): Cover[] {
    if (!this.classStateUnderwriters || this.classStateUnderwriters.length == 0) {
      return null;
    }
    var covers = this.removeDuplicatesById(this.classStateUnderwriters.map(ic => ic.insuranceClass));
    return covers.sort((c1, c2) => {
      if (c1.sequence > c2.sequence) {
        return 1;
      }
      if (c1.sequence < c2.sequence) {
        return -1;
      }
      return 0;
    });
  }

  getStates(): State[] {
    if (!this.classStateUnderwriters || this.classStateUnderwriters.length == 0) {
      return null;
    }
    return this.removeDuplicatesById(this.classStateUnderwriters.map(s => s.state));
  }

  getStatesForInsuranceClass(classId:number): State[] {
    if (!this.classStateUnderwriters || this.classStateUnderwriters.length == 0) {
      return null;
    }
    return this.classStateUnderwriters.filter(set => set.insuranceClass.id === classId).map(s => s.state);
  }

  getUnderwritersForClass(insuranceClassId: number): UnderwriterClassState[] {
    return this.classStateUnderwriters.filter(a => a.insuranceClass.id === insuranceClassId);
  }


  //TODO: consider injecting the utility class and removing this function from here
  private removeDuplicatesById(arr: any[]): any[] {
    return arr.filter((obj, index, self) => self.findIndex(t => t.id === obj.id) === index);
  }

  getStatus(): string {
    if (this.approved && !this.draft) {
      return "Approved";
    }
    if (this.submittedForApproval) {
      return "Pending moderator review";
    }
    if (this.draft && !this.draft.approved) {
      return "Requires adjustment";
    }
    if (this.approved && this.draft) {
      return "Approved | In progress changes"
    }
    return "In progress changes";
  }

  getEditVersion(): Market {
    if (!this.draft) {
      return this;
    }
    return new Market(this.id, this.draft.name, this.insurer, null, this.draft.note, this.draft.industries, this.draft.classStateUnderwriters, this.draft.policies, null, null, null, null);
  }

  draftName(): string {
    return this.draft ? this.draft.name : this.name;
  }
}
