import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from './shared/google-analytics.service';
import { UtilityService } from './shared/utility.service';
import { Location } from '@angular/common';

declare var gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'the-market';

  constructor(private router: Router, private googleAnalyticsService: GoogleAnalyticsService, private route: ActivatedRoute, private utilityService: UtilityService, private location: Location) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-3571806-21',
          {
            'page_path': event.urlAfterRedirects
          }
        );
      }
    }
    );
  }

  ngOnInit() {
    this.checkTokenLogin();
  }

  checkTokenLogin() {
    this.route.queryParams.subscribe(params => {
      const tokLogin = params['tokLogin'];
      if (tokLogin) {
        this.googleAnalyticsService.eventEmitter('Login', 'Cross login');
        let url = this.utilityService.removeUrlParameter(this.location.path(), 'tokLogin');
        this.location.replaceState(url);
      }
    });
  }

}

