import { State } from './state';
import * as i0 from "@angular/core";
var StateService = /** @class */ (function () {
    function StateService() {
    }
    StateService.prototype.GetAustralianStates = function () {
        var states = [];
        states.push(new State("ACT", "Australian Capital Territory"));
        states.push(new State("NT", "Northern Territory"));
        states.push(new State("NSW", "New South Wales"));
        states.push(new State("QLD", "Queensland"));
        states.push(new State("SA", "South Australia"));
        states.push(new State("TAS", "Tasmania"));
        states.push(new State("VIC", "Victoria"));
        states.push(new State("WA", "Western Australia"));
        return states;
    };
    StateService.ngInjectableDef = i0.defineInjectable({ factory: function StateService_Factory() { return new StateService(); }, token: StateService, providedIn: "root" });
    return StateService;
}());
export { StateService };
