import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Market } from '../../shared/market';
import { MarketService } from '../../shared/market.service';

@Component({
  selector: 'app-market-table',
  templateUrl: './market-table.component.html',
  styleUrls: ['./market-table.component.scss']
})
export class MarketTableComponent implements OnInit {
  @Input() markets: Market[];
  @Input() allowApprovals: boolean = false;
  @Input() showDraftData: boolean = true;
  @Input() showView: boolean = false;
  selectedMarket: Market = undefined;
  isApprove: boolean = undefined;
  

  constructor(private marketService: MarketService) { }
  isMobile = false;
  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 992;
    console.log(w);
    if (w < breakpoint) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.isMobile = this.getIsMobile();
    window.onresize = () => {
      this.isMobile = this.getIsMobile();
    };
  }

  

  deleteCover(market: Market): void {
    if (confirm("Are you sure you want to delete this cover?")) {
      this.markets = this.markets.filter(m => m !== market);
      this.marketService.deleteCover(market.id).subscribe();
    }
  }

  approve(m: Market, approve: boolean): void {
    this.isApprove = approve;
    this.selectedMarket = m;
  }

  submitApproval(): void {
    this.marketService.approveCover(this.selectedMarket, this.isApprove).subscribe();

    this.selectedMarket.approved = this.isApprove;
    this.selectedMarket.submittedForApproval = false;
    if (this.isApprove) {
      this.selectedMarket.name = this.selectedMarket.draftName();
      this.selectedMarket.draft = null;
    }
    this.selectedMarket = undefined;
  }

}
