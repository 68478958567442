<app-banner></app-banner>
<div class="center">

  <div nz-row nzType="flex" nzJustify="center">
    <div nz-col nzSm="24" nzMd="16" class="contact-container align-center">
      <h3 class="align-center">Contact us</h3>

      <nz-divider></nz-divider>
      <div class="contact-content-header">
      <h4>We would <i class="anticon anticon-heart"></i> to help!</h4>
      <p>Please contact us for any questions and queries and we will respond to you shortly.</p>
      </div>
      <div class="contact-content-wrapper">
        <div nz-col nzSm="24" nzMd="12" class="contact-section">
          <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
            <nz-form-item>

              <nz-form-control [nzSpan]="24" nzRequired>
                <input formControlName="userName" nz-input placeholder="Your Name *">
                <nz-form-explain *ngIf="validateForm.get('userName').dirty && validateForm.get('userName').errors">Please
                  input
                  your name!</nz-form-explain>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>

              <nz-form-control [nzSpan]="24" nzRequired>
                <input nz-input formControlName="email" id="email" placeholder="Your Email *">
                <nz-form-explain *ngIf="validateForm.get('email').dirty && validateForm.get('email').errors">The input
                  is
                  not
                  valid E-mail!</nz-form-explain>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>

              <nz-form-control [nzSpan]="24" nzRequired>
                <input formControlName="phoneNumber" id="'phoneNumber'" nz-input placeholder="Your Phone No">
                <nz-form-explain *ngIf="validateForm.get('phoneNumber').dirty && validateForm.get('phoneNumber').errors">Please
                  input your phone number!</nz-form-explain>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>

              <nz-form-control [nzSpan]="24" nzRequired>
                <textarea formControlName="comment" nz-input rows="2" placeholder="Your Message *"></textarea>
                <nz-form-explain *ngIf="validateForm.get('comment').dirty && validateForm.get('comment').errors">Please
                  input
                  a comment here!</nz-form-explain>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <button nz-button nzType="primary" [disabled]="!validateForm.valid">Send a message</button>
              </nz-form-control>
            </nz-form-item>
          </form>
        </div>
        <div nz-col nzSm="24" nzMd="12" class="contact-section">
          <div class="contact-detail">
            <i class="anticon anticon-home"></i>
            <h4>LMI Group House, 428 Burke Road, Camberwell Vic 3124 <br />
                PO Box 2116, Camberwell Vic 3124</h4> <!-- address -->
          </div>
          <div class="contact-detail">
            <i class="anticon anticon-mail"></i>
            <h4>market@lmigroup.com</h4><!-- email add -->
          </div>
          <div class="contact-detail">
            <i class="anticon anticon-phone"></i>
            <h4>+61 3 9835 9900</h4> <!-- phone no. -->
          </div>
          <nz-divider></nz-divider>
         <div class="corporate-contact">
          <ul class="social-links">
              <li>
                <a href="https://www.facebook.com/pages/LMI-Group-Pty-Ltd/144988182266476" target="_blank" rel="noopener noreferrer">
                  <i class="anticon anticon-facebook"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/lmigroup" target="_blank" rel="noopener noreferrer">
                  <i class="anticon anticon-twitter"></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/lmi-group" target="_blank" rel="noopener noreferrer">
                  <i class="anticon anticon-linkedin"></i>
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/user/LMIGROUP" target="_blank" rel="noopener noreferrer">
                  <i class="anticon anticon-youtube"></i>
                </a>
              </li>
            </ul>
            <a href="www.lmigroup.io" target="_blank" rel="noopener noreferrer">
                LMI Group
              </a>
            </div>
        </div>
      </div>

   
  </div>
</div>





<ng-template #prefixUser><i nz-icon type="user"></i></ng-template>
<ng-template #prefixLock><i nz-icon type="lock"></i></ng-template>
