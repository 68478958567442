import { SearchRequest } from './search-request';
import * as i0 from "@angular/core";
var SearchFiltersService = /** @class */ (function () {
    function SearchFiltersService() {
        this.searchRequest = new SearchRequest();
    }
    SearchFiltersService.ngInjectableDef = i0.defineInjectable({ factory: function SearchFiltersService_Factory() { return new SearchFiltersService(); }, token: SearchFiltersService, providedIn: "root" });
    return SearchFiltersService;
}());
export { SearchFiltersService };
