import { Injectable } from '@angular/core';
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  public eventEmitter(
    eventAction: string,
    eventLabel: string = null,
    eventCategory: string=null,
    eventValue: number = null) {
    gtag('event', eventAction, {
      event_label: eventLabel,
      event_category: eventCategory,
      value: eventValue
    })
  }
}
