import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { UserService } from '../shared/user.service';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {

  constructor(private userService: UserService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has('X-Legacy')) {
      request = request.clone({
        headers: request.headers.delete('X-Legacy')
      });
    }
    else {
      request = request.clone({
        setHeaders: {
              'X-Api-Key': environment.apiKey,
              'Accept': 'application/json; v=1.0'
        }
      });
    }

    if (request.headers.has('NoAuth')) {
      request = request.clone({
        headers: request.headers.delete('NoAuth')
      })
    }
    else {
      if (this.userService.getToken()) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${JSON.parse(this.userService.getToken()).value}`
          }
        })
      }
    }
    return next.handle(request);
  }
}
