<h2>My Markets</h2>
<p>
  Here you can edit covers offered, for which insurance classes and across which industries. Any changes made to the
  market covers will be submitted for approval by an LMI moderator.
</p>
<div class="btn-container">
  <button nz-button
          nzType="primary"
          [nzSize]="'large'"
          (click)="addMarket()"
          >
    <i nz-icon type="plus"></i>ADD NEW
  </button>
</div>
<div *ngIf="userService.isUserInRole('Administrator')">
  <app-insurer-select [(ngModel)]="insurer"></app-insurer-select>
</div>
<div>
  <div *ngIf="markets && markets.length == 0" class="alert alert-warning text-center" role="alert">
    <b>You haven't created any markets! Start by adding your underwriters or creating your first market.</b>
  </div>
  <app-market-table [markets]="markets" ></app-market-table>  
</div>

