<nz-checkbox-wrapper>
  <div nz-row>
    <div nz-col nzSpan="24">
      <label nz-checkbox [(ngModel)]="allChecked" (ngModelChange)="selectAll()">Select All</label>
      </div>
      <div *ngFor="let option of options" nz-col nzSpan="24">
        <label nz-checkbox [nzValue]="option.value" [(ngModel)]="option.selected" (ngModelChange)="changed()">
          {{ option.label }}
        </label>
      </div>
    </div>
</nz-checkbox-wrapper>
