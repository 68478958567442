import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Market } from './market';
import { environment } from '../../environments/environment';
import { SearchRequest } from './search-request';
import { map } from 'rxjs/operators';
import { Underwriter } from './underwriter';
import { State } from './state';
import { Cover } from './cover';
import { Industry } from './industry';
import { SearchResults } from './search-results';
import { ContactUs } from './contact-us';

@Injectable({
    providedIn: 'root'
})
export class MarketService {
    savedCovers: Market[] = [];

    constructor(private http: HttpClient) { }

    searchMarkets(search: SearchRequest): Observable<SearchResults> {
        const options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http
            .post<SearchResults>(`${environment.legacyRiskCoachApiBase}/Market/MarketSearch`, search, options)
            .pipe(
                map(d => {
                    let results: SearchResults = new SearchResults();
                    results.totalResults = d.totalResults;
                    results.covers = d.covers.map(m => new Market().deserialize(m));
                    return results;
                })
            );
    }

    getMarket(marketId: number): Observable<Market> {
        const options = {
            params: new HttpParams().set('coverId', '' + marketId),
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get<Market>(`${environment.legacyRiskCoachApiBase}/Market/GetCover`, options).pipe(
            map(data => {
                return new Market().deserialize(data);
            })
        );
    }

    getRecentCovers(): Observable<Market[]> {
        const options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get<Market[]>(`${environment.legacyRiskCoachApiBase}/Market/RecentCovers`, options).pipe(
            map(d => {
                return d.map(data => {
                    return new Market().deserialize(data);
                });
            })
        );
    }

    getPopularCovers(): Observable<Market[]> {
        const options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get<Market[]>(`${environment.legacyRiskCoachApiBase}/Market/PopularCovers`, options).pipe(
            map(d => {
                return d.map(data => {
                    return new Market().deserialize(data);
                });
            })
        );
    }

    saveCover(coverId: number, unsave: boolean): Observable<{}> {
        const options = {
            params: new HttpParams().set('coverId', '' + coverId).set('unsave', '' + unsave),
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.put(`${environment.legacyRiskCoachApiBase}/Market/SaveCover`, '', options);
    }

    getSavedCovers(): Observable<Market[]> {
        const options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get<Market[]>(`${environment.legacyRiskCoachApiBase}/Market/SavedCovers`, options).pipe(
            map(d => {
                return d.map(data => {
                    return new Market().deserialize(data);
                });
            })
        );
    }

    getMarketsForInsurer(): Observable<Market[]> {
        const options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get<Market[]>(`${environment.legacyRiskCoachApiBase}/Market/GetCovers?insurer=true`, options).pipe(
            map(res => {
                return res.map(item => {
                    return new Market().deserialize(item);
                });
            })
        );
    }

    getMarketsForInsurerId(insurerId: number): Observable<Market[]> {
        const options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get<Market[]>(`${environment.legacyRiskCoachApiBase}/Market/GetCovers?insurerId=${insurerId}`, options).pipe(
            map(res => {
                return res.map(item => {
                    return new Market().deserialize(item);
                });
            })
        );
    }

    getMarketsAwaitingApproval(): Observable<Market[]> {
        const options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http
            .get<Market[]>(`${environment.legacyRiskCoachApiBase}/Market/GetCovers?submittedForApproval=true`, options)
            .pipe(
                map(res => {
                    return res.map(item => {
                        return new Market().deserialize(item);
                    });
                })
            );
    }

    getMarkets(): Observable<Market[]> {
        const options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get<Market[]>(`${environment.legacyRiskCoachApiBase}/Market/GetCovers?submittedForApproval=false&insurer=false`, options)
            .pipe(map(res => {
                return res.map(item => {
                    return new Market().deserialize(item);
                })
            }));
    }

    deleteCover(coverId: number): Observable<{}> {
        const options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.delete(`${environment.legacyRiskCoachApiBase}/Market/DeleteCover?coverId=${coverId}`, options);
    }

    getUnderwritersForInsurer(insurerId: number): Observable<Underwriter[]> {
        const options = {
            headers: { 'X-Legacy': 'true' },
            params: new HttpParams()
        };
        if (insurerId) {
            options.params = options.params.set('insurerId', '' + insurerId);
        }
        return this.http.get<Underwriter[]>(`${environment.legacyRiskCoachApiBase}/Market/GetMarketUnderwriters`, options)
            .pipe(map(res => {
                return res.map(item => {
                    return new Underwriter().deserialize(item);
                });
            }));
    }

    deleteMarketUnderwriter(underwriterId: number) {
        const options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.delete<null>(
            `${environment.legacyRiskCoachApiBase}/Market/DeleteMarketUnderwriter?underwriterId=${underwriterId}`, options);
    }

    replaceUnderwriter(oldUnderwriterId: number, newUnderwriterId: number): Observable<{}> {
        const options = {
            headers: { 'X-Legacy': 'true' },
            params: new HttpParams().set('oldUnderwriterId', '' + oldUnderwriterId)
                .set('newUnderwriterId', '' + newUnderwriterId)
        };
        return this.http.put(`${environment.legacyRiskCoachApiBase}/Market/ReplaceUnderwriter`, '', options);
    }

    updateMarketUnderwriter(underwriter: Underwriter): Observable<{}> {
        const options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.put(`${environment.legacyRiskCoachApiBase}/Market/UpdateMarketUnderwriter`, underwriter, options);
    }


    addMarketUnderwriter(underwriter: Underwriter, insurerId: number): Observable<Underwriter> {
        const options = {
            headers: { 'X-Legacy': 'true' },
            params: new HttpParams()
        };
        if (insurerId) {
            options.params = options.params.set('insurerId', '' + insurerId);
        }
        return this.http.post<Underwriter>(`${environment.legacyRiskCoachApiBase}/Market/AddMarketUnderwriter`, underwriter, options)
            .pipe(map(item => {
                return new Underwriter().deserialize(item);
            }));
    }

    createMarketCover(market: Market): Observable<Market> {
        const options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.post<Market>(`${environment.legacyRiskCoachApiBase}/Market/CreateCover`, market, options);
    }

    updateMarketCover(market: Market): Observable<{}> {
        const options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.put(`${environment.legacyRiskCoachApiBase}/Market/UpdateCover`, market, options);
    }

    //TODO: Move to state service
    getStates(countryId: number): Observable<State[]> {
        const options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get<State[]>(`${environment.legacyRiskCoachApiBase}/Market/GetStates?countryId=${countryId}`, options);
    }

    getIndustryHierarchy(): Observable<Industry[]> {
        const options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get<Industry[]>(`${environment.legacyRiskCoachApiBase}/Market/GetIndustryHierarchy`, options);
    }

    submitCover(coverId: number): Observable<{}> {
        const options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.put(`${environment.legacyRiskCoachApiBase}/Market/SubmitCover?coverId=${coverId}`, '', options);
    }

    approveCover(market: Market, approve: boolean): Observable<{}> {
        const options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.put(`${environment.legacyRiskCoachApiBase}/Market/ApproveCover?approve=${approve}`, market, options);
    }

    contactUs(contactForm: ContactUs): Observable<{}> {
        const options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.post(`${environment.legacyRiskCoachApiBase}/Market/ContactUs`, contactForm, options);
    }
}
