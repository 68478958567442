export class Industry {
  constructor(
    public id: number,
    public name: string,
    public code: string,
    public childIndustries?: Industry[],
    public alias?:number
  ) { }


  public getAlpha(): string {
    let prefix = Number(this.code.substring(0, 2));
    switch (true) {
      case (prefix <= 5):
        return 'A';
      case (prefix <= 10):
        return 'B';
      case (prefix <= 25):
        return 'C';
      case (prefix <= 29):
        return 'D';
      case (prefix <= 32):
        return 'E';
      case (prefix <= 38):
        return 'F';
      case (prefix <= 43):
        return 'G';
      case (prefix <= 45):
        return 'H';
      case (prefix <= 53):
        return 'I';
      case (prefix <= 60):
        return 'J';
      case (prefix <= 64):
        return 'K';
      case (prefix <= 67):
        return 'L';
      case (prefix <= 70):
        return 'M';
      case (prefix <= 73):
        return 'N';
      case (prefix <= 77):
        return 'O';
      case (prefix <= 82):
        return 'P';
      case (prefix <= 87):
        return 'Q';
      case (prefix <= 92):
        return 'R';
      case (prefix <= 96):
        return 'S';
    }
  }
}

export class IndustryTreeNode extends Industry {
  public selected?: boolean
  public halfSelected?: boolean

}

export class IndustryTree {
  public IndustryTreeNodes: IndustryTreeNode[];

  getChildren(industryId: number): IndustryTreeNode[] {
    for (let alphaNode of this.IndustryTreeNodes) {
      if (alphaNode.id === industryId) {
        return (alphaNode.childIndustries as IndustryTreeNode[]);
      }
      for (let anzsicNode of alphaNode.childIndustries) {
        if (anzsicNode.id === industryId) {
          return (anzsicNode.childIndustries as IndustryTreeNode[]);
        }
      }
    }
    return null;
  }

  getParent(industryId: number): IndustryTreeNode {
    for (let alphaNode of this.IndustryTreeNodes) {
      if (alphaNode.id === industryId) {
        return null;    //root node
      }
      for (let anzsicNode of alphaNode.childIndustries) {
        if (anzsicNode.id === industryId) {
          return alphaNode;
        }
        for (let riscNode of anzsicNode.childIndustries) {
          if (riscNode.id === industryId) {
            return anzsicNode;
          }
        }
      }
    }
  }

  setSelected(selected: boolean, industryId: number): void {
    for (let alpha of this.IndustryTreeNodes) {
      if (alpha.id === industryId) {  //set alpha node checked, check all children and grandchildren
        alpha.selected = selected;
        alpha.halfSelected = false;
        alpha.childIndustries.forEach(anzsic => {
          (anzsic as IndustryTreeNode).selected = selected;
          (anzsic as IndustryTreeNode).halfSelected = false;
          anzsic.childIndustries.forEach(risc => {
            (risc as IndustryTreeNode).selected = selected;
          });
        });
        return;
      }
      for (let anzsic of alpha.childIndustries) { //set ANZSIC node checked, check all children, update parent checked status
        if (anzsic.id === industryId) {
          (anzsic as IndustryTreeNode).selected = selected;
          (anzsic as IndustryTreeNode).halfSelected = false;
          anzsic.childIndustries.forEach(risc => {
            (risc as IndustryTreeNode).selected = selected;
          });
          this.updateAlphaSelectedStatus(alpha as IndustryTreeNode);
          return;
        }
        for (let risc of anzsic.childIndustries) {  //set RISC industry checked, update parent/grandparent checked status
          if (risc.id === industryId) {
            (risc as IndustryTreeNode).selected = selected;
            this.updateAnzsicSelectedStatus(anzsic as IndustryTreeNode);
            this.updateAlphaSelectedStatus(alpha as IndustryTreeNode);
            return;
          }
        }
      }
    }
  }
  
  //Checks child nodes to determine checked status of anzsic node
  private updateAnzsicSelectedStatus(anzsicNode: IndustryTreeNode) {
    let numberSelected = anzsicNode.childIndustries.filter(r => (r as IndustryTreeNode).selected).length;
    if (numberSelected === 0) {
      anzsicNode.selected = false;
      anzsicNode.halfSelected = false;
      return;
    }
    if (numberSelected === anzsicNode.childIndustries.length) {
      anzsicNode.selected = true;
      anzsicNode.halfSelected= false;
      return;
    }
    anzsicNode.halfSelected = true;
    anzsicNode.selected = false;
  }

  //Checks child nodes to determine checked status of alpha node
  private updateAlphaSelectedStatus(alphaNode: IndustryTreeNode) {
    let numberSelected = alphaNode.childIndustries.filter(r => (r as IndustryTreeNode).selected).length;
    let numberhalfSelected = alphaNode.childIndustries.filter(r => (r as IndustryTreeNode).halfSelected).length;
    if (numberSelected === 0 && numberhalfSelected === 0) {
      alphaNode.selected = false;
      alphaNode.halfSelected = false;
      return;
    }
    if (numberSelected === alphaNode.childIndustries.length) {
      alphaNode.selected = true;
      alphaNode.halfSelected = false;
      return;
    }
    alphaNode.selected = false;
    alphaNode.halfSelected = true;
  }

  getSelected(): IndustryTreeNode[] {
    let selectedNodes: IndustryTreeNode[] = [];
    this.IndustryTreeNodes.forEach(alpha => {
      if (alpha.selected || alpha.halfSelected) {
        alpha.childIndustries.forEach(anzsic => {
          if ((anzsic as IndustryTreeNode).selected || (anzsic as IndustryTreeNode).halfSelected) {
            selectedNodes = selectedNodes.concat((anzsic.childIndustries as IndustryTreeNode[]).filter(n => n.selected));
          }
        });
      }
    });
    return selectedNodes;
  }

}
