import { Injectable } from '@angular/core';
import { User } from './user';
import { Observable, empty, throwError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { UserRole } from './user-role';
import { AppSettings } from './app-settings';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    public currentUser: User;
    public companyLogo: any;
    public authApi: any;
    public redirectUrl: string;
    public loginMessage: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) { }

    public login(user: User): Observable<any> {
        const body = `grant_type=password&username=${user.userName}&password=${user.password}&client_id=${
            this.appSettings.clientId
            }`;
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*'
        });
        return this.http.post<any>(`${environment.authApiBase}/token`, body, { headers: headers }).pipe(
            map(d => {
                this.setToken(d.access_token);
                return d.access_token;
            })
        );
    }

    public setToken(token: string): void {
        var expiryTime = new Date();
        var object = { value: token, timestamp: expiryTime.setHours(expiryTime.getHours() + 24) };
        window.localStorage.setItem(this.appSettings.authTokenKey, JSON.stringify(object));
    }

    public getToken(): string {
        if (!this.isAuthenticated()) {
            return '';
        }
        return window.localStorage.getItem(this.appSettings.authTokenKey);
    }

    public getTokenFromLinkgen(linkgenPayload: string):Observable<any> {
        const body = `grant_type=LinkgenUrl&client_id=${this.appSettings.clientId}&Encrypted_Data=${linkgenPayload}`;
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*'
        });
        //console.log('getting token');
        return this.http.post<any>(`${environment.authApiBase}/token`, body, { headers: headers }).pipe(
            map(d => {
                //console.log(d.access_token);
                this.setToken(d.access_token);
                return d.access_token;
            })
        );
    }

    public isAuthenticated(): boolean {
        try {
            if (window.localStorage.getItem(this.appSettings.authTokenKey) != null) {
                let object = JSON.parse(window.localStorage.getItem(this.appSettings.authTokenKey));
                let date = Date.now();
                if (date <= object.timestamp) {
                    return true;
                }
            }
        } catch (e) {
            return false;
        }
        return false;
    }

    public logout() {
        window.localStorage.removeItem(this.appSettings.authTokenKey);
        this.currentUser = undefined;
    }

    public getUser(): Observable<any> {
        const getHeaders = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });
        return this.http
            .get<any>(`${environment.authApiBase}/api/user`, { headers: getHeaders })
            .pipe(
                map((r: any) => {
                    if (!this.currentUser) {
                        this.currentUser = new User();
                    }
                    this.currentUser.email = r.email;
                    this.currentUser.userName = r.userName;
                    this.currentUser.userRoles = r.roles.map(role => {
                        return new UserRole(role.roleId, role.roleName);
                    });
                })
            )
            .pipe(catchError(this.handleError));
    }

    public isUserInRole(role: string): boolean {
        if (!this.currentUser) {
            //user isn't yet loaded
            return false;
        }
        return this.currentUser.userRoles.filter(r => r.name == role).length > 0;
    }

    public isAdmin(): boolean {
        return this.isUserInRole("Administrator");
    }

    public refreshUserFromToken(): Promise<any> {
        if (this.isAuthenticated()) {
            return this.getUser()
                .toPromise()
                .catch((err: any) => Promise.resolve());
        }
        return Promise.resolve();
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }

    ///Gets current users' associated insurer ID (if entity exists)
    public getAssociatedInsurerId(): Observable<number> {
        const options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get<number>(`${environment.legacyRiskCoachApiBase}/Market/GetAssociatedInsurerId`, options).pipe(
            map(id => {
                if (this.currentUser) {
                    this.currentUser.insurerId = id;
                }
                return id;
            })
        )}
}
