import { Serializable } from "./serializable";

export class Cover implements Serializable<Cover> {
  constructor(
    public id?: number,
    public name?: string,
    public sequence?: number
  ) { }

  deserialize(object: any): Cover {
    this.id = object.id;
    this.name = object.name;
    this.sequence = object.sequence;
    return this;
  }

  getIcon(): string {
    switch (this.name) {
      case 'Property Damage': {
        return 'assets/property-damage.svg';
      }
      case 'Business Interruption': {
        return 'assets/business-interruption.svg';
      }
      case 'Crime': {
        return 'assets/crime.svg';
      }
      case 'Marine (including Inland Transit)': {
        return 'assets/marine.svg';
      }
      case 'Equipment Breakdown': {
        return 'assets/equipment-breakdown.svg';
      }
      case 'Motor': {
        return 'assets/motor.svg';
      }
      case 'Public Liability': {
        return 'assets/public-liability.svg';
      }
      case 'Product Liability': {
        return 'assets/product-liability.svg';
      }
      case 'Product Recall / Product Contamination': {
        return 'assets/product-recall.svg';
      }
      case 'Professional Indemnity': {
        return 'assets/professional-indemnity.svg';
      }
      case 'Management Liability': {
        return 'assets/management-liability.svg';
      }
      case "Employer Liability / Worker's Compensation": {
        return 'assets/employer-liability.svg';
      }
      case "Cyber Risks": {
        return 'assets/cyber.svg';
      }
      case "Corporate Travel": {
        return 'assets/corporate-travel.svg';
      }
      default: {
        return '';
      }

    }
  }
}
