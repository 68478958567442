import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SearchComponent } from './search.component';
import { ResultsComponent } from './results/results.component';
import { ProfileComponent } from './profile/profile.component';
import { LoginComponent } from '../shared/login/login.component';
import { AuthGuard } from './auth.guard';
import { SavedMarketsComponent } from './saved-markets/saved-markets.component';
import { AdminAuthGuard } from './admin-auth.guard';

const routes: Routes = [
  { path: '', component: SearchComponent, pathMatch: 'full' },
  { path: 'results/:industryId', component: ResultsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  //{ path: 'results', component: ResultsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'results', component: ResultsComponent, pathMatch: 'full', canActivate:[AuthGuard]},
  { path: 'profile/:id', component: ProfileComponent, pathMatch: 'full', canActivate:[AuthGuard] },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'saved-markets', component: SavedMarketsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'admin', loadChildren: '../admin/admin.module#AdminModule'}
]

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class SearchRoutingModule { }

