<div nz-row nzType="flex" nzJustify="center" class="add-market-industry-container">
  <div nz-col nzXs="23" nzSm="23" nzMd="20" nzLg="18" nzXl="16">
    <h3>Add a Market</h3>
    <!-- Progress speps header -->
    <ul class="steps">
      <li><a>Market Details</a></li>
      <li class="dots">
        <i class="fa fa-circle" style="font-size:10px"></i>
        <i class="fa fa-circle" style="font-size:8px"></i>
        <i class="fa fa-circle" style="font-size:6px;"></i>
      </li>
      <li><a>Underwriters</a></li>
      <li class="dots">
        <i class="fa fa-circle" style="font-size:10px"></i>
        <i class="fa fa-circle" style="font-size:8px"></i>
        <i class="fa fa-circle" style="font-size:6px;"></i>
      </li>
      <li><a class="active">Industry Details</a></li>
    </ul>
    <!-- End Progress speps header -->
    <div>
      <app-industry-multiselect [(ngModel)]="cover.industries"></app-industry-multiselect>
    </div>
    <!--new Buttons Styling-->
    <div class="btn-container">
      <button nz-button nzSize="large" class="btn-cancel" (click)="cancel()">Cancel</button>
      <button nz-button nzSize="large" (click)="save(false)" class="btn-back"
        style="margin-left:10px;margin-right:auto;">
        <i nz-icon type="left" theme="outline"></i>Back
      </button>
      <button nz-button nzType="primary" nzSize="large" (click)="save(true)">Finish<i nz-icon type="right"></i></button>
    </div>
  </div>
</div>