import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faTwitter, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { en_US } from 'ng-zorro-antd';
import { UserService } from './shared/user.service';
import { Location } from '@angular/common';
import { UtilityService } from './shared/utility.service';
library.add(faFacebookF, faTwitter, faLinkedin, faYoutube);
///Check for auth token or linkgen payload at app init and authenticate user
export function checkUserToken(userService, location, utilityService) {
    return function () {
        var token = utilityService.getUrlParameter(location.path(), "tok");
        if (token) {
            userService.setToken(token);
            var url = utilityService.removeUrlParameter(location.path(), "tok");
            url = utilityService.addUrlParameter(url, "tokLogin", "true");
            location.replaceState(url);
        }
        else {
            var linkgen = utilityService.getUrlParameter(location.path(), "linkgen");
            if (linkgen) {
                //console.log(linkgen);
                var linkgenDecoded = atob(linkgen);
                //console.log(linkgenDecoded);
                var payload = utilityService.getParameterFromParameterList(linkgenDecoded, 'EncryptedData');
                userService.getTokenFromLinkgen(payload).subscribe(function () {
                    return userService.refreshUserFromToken();
                });
            }
        }
        return userService.refreshUserFromToken();
    };
}
var ɵ0 = en_US;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
