import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import _ from 'lodash';
import { MarketService } from '../../shared/market.service';
import { Market } from '../../shared/market';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss', '../market-card/market-card.component.scss']
})
export class ProfileComponent {
  id: number;
  market: Market;

  constructor(private activatedRoute: ActivatedRoute, private marketService: MarketService) {}

  //get diagnostic() { return JSON.stringify(this.market); }
  isMobile = false;
  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 992;
    console.log(w);
    if (w < breakpoint) {
      return true;
    } else {
      return false;
    }
  }
  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params['id'];
      this.getMarket();
    });
    this.isMobile = this.getIsMobile();
    window.onresize = () => {
      this.isMobile = this.getIsMobile();
    };
  }

  getMarket() {
    this.marketService.getMarket(this.id).subscribe(m => {
      this.market = m;
    });
  }

  getUnderwriters(classId: number): object[] {
    return _.chain(this.market.classStateUnderwriters)
      .filter(item => item.insuranceClass.id === classId)
      .groupBy(item => item.underwriter.email)
      .map((value, email) => ({
        email,
        name: value[0].underwriter.name,
        phone: value[0].underwriter.phone,
        states: value.map(item => item.state.shortName)
      }))
      .value();
  }
}
