<nz-modal [(nzVisible)]="visible" nzTitle="Add an underwriter" (nzOnCancel)="resetForm()" (nzOnOk)="onSubmit()"
  [nzOkLoading]="isOkLoading">

  <form nz-form (ngSubmit)="onSubmit()" [formGroup]="validateForm">
    <p>Create a new underwriter who can be listed as a designated contact under a market cover.</p>

    <nz-form-item>
      <nz-form-label nzRequired nzFor="name">Full Name</nz-form-label>
      <nz-form-control>
        <input nz-input formControlName="name" />
        <nz-form-explain *ngIf="validateForm.get('name').dirty && validateForm.get('name').errors">Underwriter name is
          required</nz-form-explain>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzRequired nzFor="email">Email</nz-form-label>
      <nz-form-control>
        <input nz-input formControlName="email" />
        <nz-form-explain *ngIf="validateForm.get('email').dirty && validateForm.get('email').errors">Please enter a
          valid email</nz-form-explain>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzRequired nzFor="phone">Phone</nz-form-label>
      <nz-form-control>
        <input nz-input formControlName="phone" />
        <nz-form-explain *ngIf="validateForm.get('phone').dirty && validateForm.get('phone').errors">Please enter a
          valid phone</nz-form-explain>
      </nz-form-control>
    </nz-form-item>
    <div>

    </div>
  </form>
</nz-modal>