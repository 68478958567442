import { environment } from '../../environments/environment';
import { UserService } from '../shared/user.service';
import * as i0 from "@angular/core";
import * as i1 from "../shared/user.service";
var TokenInterceptor = /** @class */ (function () {
    function TokenInterceptor(userService) {
        this.userService = userService;
    }
    TokenInterceptor.prototype.intercept = function (request, next) {
        if (request.headers.has('X-Legacy')) {
            request = request.clone({
                headers: request.headers.delete('X-Legacy')
            });
        }
        else {
            request = request.clone({
                setHeaders: {
                    'X-Api-Key': environment.apiKey,
                    'Accept': 'application/json; v=1.0'
                }
            });
        }
        if (request.headers.has('NoAuth')) {
            request = request.clone({
                headers: request.headers.delete('NoAuth')
            });
        }
        else {
            if (this.userService.getToken()) {
                request = request.clone({
                    setHeaders: {
                        Authorization: "Bearer " + JSON.parse(this.userService.getToken()).value
                    }
                });
            }
        }
        return next.handle(request);
    };
    TokenInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function TokenInterceptor_Factory() { return new TokenInterceptor(i0.inject(i1.UserService)); }, token: TokenInterceptor, providedIn: "root" });
    return TokenInterceptor;
}());
export { TokenInterceptor };
