import { NgModule } from '@angular/core';
import { NZ_ICONS } from 'ng-zorro-antd'
import { IndustrySearchComponent } from './industry-search/industry-search.component';
import { SearchComponent } from './search.component';
import { SearchRoutingModule } from './search-routing.module';
import { CoverSearchComponent } from './cover-search/cover-search.component';
import { MarketCardComponent } from './market-card/market-card.component';
import { ResultsComponent } from './results/results.component';
import { ProfileComponent } from './profile/profile.component';
import { LocationSearchComponent } from './location-search/location-search.component';
import { SearchTagsComponent } from './search-tags/search-tags.component';
import { CoverFilterComponent } from './cover-filter/cover-filter.component';
import { LocationFilterComponent } from './location-filter/location-filter.component';
import { IconDefinition } from '@ant-design/icons-angular';
import { MailOutline, PhoneOutline, StarOutline, LoginOutline, ShopOutline, EnvironmentOutline, WarningOutline, SearchOutline, UserOutline, LockOutline, StarFill } from '@ant-design/icons-angular/icons';
import { SharedModule } from '../shared/shared.module';
import { SaveCoverComponent } from './save-cover/save-cover.component';
import { SavedMarketsComponent } from './saved-markets/saved-markets.component';

const icons: IconDefinition[] = [MailOutline, PhoneOutline, StarOutline, LoginOutline, ShopOutline, EnvironmentOutline, WarningOutline, SearchOutline, UserOutline, LockOutline, StarFill];

@NgModule({
  imports: [
    SharedModule,
    SearchRoutingModule
  ],
  providers: [
    { provide: NZ_ICONS, useValue: icons }
  ],
  declarations: [
    IndustrySearchComponent,
    SearchComponent, 
    CoverSearchComponent, 
    MarketCardComponent,
    ResultsComponent,
    ProfileComponent,
    LocationSearchComponent,
    SearchTagsComponent,
    CoverFilterComponent,
    LocationFilterComponent,
    SaveCoverComponent,
    SavedMarketsComponent
  ],
  exports: [
    CoverSearchComponent,
    LocationSearchComponent,
    IndustrySearchComponent
  ],
  entryComponents: [SearchComponent]
})
export class SearchModule { }
