<div class="industry-search-container">
  <nz-select
    style="width:100%;"
    nzMode="multiple"
    [nzSize]="size"
    [ngModel]="getSelectedIndustries()"
    (ngModelChange)="setSelectedIndustries($event)"
    nzPlaceHolder="Search for an industry"
    nzAllowClear="true"
    nzShowSearch="true"
    [nzServerSearch]="true"
    (nzOnSearch)="search($event)"
    [nzNotFoundContent]="searchNoData"
  >
    <ng-container *ngFor="let industry of (industries$ | async)">
      <nz-option *ngIf="!isLoading" [nzValue]="industry" [nzLabel]="industry.name"> </nz-option>
    </ng-container>
    <ng-container *ngFor="let industry of _selected">
      <nz-option *ngIf="!isLoading" [nzValue]="industry" [nzLabel]="industry.name"> </nz-option>
    </ng-container>
    <nz-option *ngIf="isLoading" nzDisabled nzCustomContent><i nz-icon type="loading"></i> Loading...</nz-option>
  </nz-select>
</div>
