import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Cover } from '../../shared/cover';
import { CoverService } from '../../shared/cover.service';

@Component({
  selector: 'app-cover-filter',
  templateUrl: './cover-filter.component.html',
  styleUrls: ['./cover-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoverFilterComponent),
      multi: true
    }
  ]
})

export class CoverFilterComponent implements OnInit, ControlValueAccessor {
  @Input() _selected: Cover[] = undefined;
  options: any[] = [];
  allChecked: boolean = false;

  constructor(private coverService: CoverService) {
    this.onChange = (_: any) => { };
  }

  ngOnInit() {
  }

  loadCoverOptions() {
    if (!this.options || this.options.length === 0) {
      this.coverService.getCovers()
        .subscribe(ics => {
          if (!this.options || this.options.length === 0) {   //don't bind if another concurrent request has already been bound
            ics.forEach(item => {
              this.options.push({ label: item.name, value: item.id });
            });
          }
          this.setSelectedCovers();
        });
    }
    else {
      this.setSelectedCovers();
    }
  }

  setSelectedCovers() {
    let selectedCoverIds = [];
    if (this._selected) {
      selectedCoverIds = this._selected.map(c => c.id);
    }
    this.options.forEach(c => {
      if (selectedCoverIds.includes(c.value)) {
        c.selected = true;
      }
      else {
        delete c.selected;
      }
    })
    this.allChecked = this.options.filter(o => o.selected).length === this.options.length;
  }

  private onChange: (_: any) => void;

  writeValue(value: any) {
    if (value !== undefined) {
      this._selected = value;
      this.loadCoverOptions();
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched() { }

  changed() {
    this.updateModal();
    this.onChange(this._selected);
  }

  updateModal() {
    let covers: Cover[] = [];
    this.options.forEach(c => {
      if (c.selected) {
        covers.push(new Cover(c.value, c.label));
      }
    })
    this._selected = covers;
    this.allChecked = this.options.filter(o=>o.selected).length === this.options.length;
  }

  selectAll(): void {
    if (this._selected && this._selected.length === this.options.length) {
      this.options.map(c => delete c.selected );
    }
    else {
      this.options.map(c => c.selected = true);
    }
    this.changed();
  }

}

