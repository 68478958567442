import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { Industry, IndustryTree, IndustryTreeNode } from 'src/app/shared/industry';
import { MarketService } from 'src/app/shared/market.service';
import { NzTreeNode, NzFormatEmitEvent, NzMessageService } from 'ng-zorro-antd';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'app-industry-multiselect',
  templateUrl: './industry-multiselect.component.html',
  styleUrls: ['./industry-multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IndustryMultiselectComponent),
      multi: true
    }
  ]
})
export class IndustryMultiselectComponent implements OnInit, ControlValueAccessor {
  @Input()
  _selected: Industry[] = undefined;

  @Input() enableSearch: boolean = true;
  @Input() readOnly: boolean = false;

  nodes: NzTreeNode[];  //UI data model
  industries: IndustryTree = new IndustryTree();  //full data model
  isLoading: boolean = true;

  private _searchIndustries: Industry[];
  set searchIndustries(searchIndustries: Industry[]) {
    this._searchIndustries = [];
    if (searchIndustries && searchIndustries.length === 1) {
      this.selectIndustryFromSearch(searchIndustries[0]);
    }
  }
  get searchIndustries(): Industry[] {
    return this._searchIndustries;
  }
  //get diagnostic() { return JSON.stringify(this.readOnly); }

  constructor(private marketService: MarketService, private message: NzMessageService) {
    this.onChange = (_: any) => { };
  }

  ngOnInit() {
    this.getIndustries();
  }

  getIndustries(): void {
    this.marketService.getIndustryHierarchy()
      .subscribe(i => {
        this.industries.IndustryTreeNodes = i as IndustryTreeNode[];
        this.setTreeSelections();
        this.isLoading = false;
      });
  }

  generateParentNodes(): NzTreeNode[] {
    let tnArray: NzTreeNode[] = [];
    this.industries.IndustryTreeNodes.forEach(i => {
      let tn = new NzTreeNode({ 'key': i.id.toString(), 'title': i.name });
      tn.isSelectable = false;
      tn.setChecked(i.selected, i.halfSelected);
      tn.isDisableCheckbox = this.readOnly;
      tnArray.push(tn);
    })
    return tnArray;
  }

  //Check the selected industry from search results in data models
  selectIndustryFromSearch(industry: Industry): void {
    this.industries.setSelected(true, industry.id);
    if (this.updateUIIndustryModel(industry.id)) {
      this.message.success("Industry added!");
      this._selected = this.industries.getSelected();
      this.onChange(this._selected);
    }
    else {
      this.message.error("Industry not available.");
    }

  }

  updateUIIndustryModel(industryId: number): boolean {
    let anzsic = this.industries.getParent(industryId);
    if (!anzsic) {  //industry not found
      return false;
    }
    let alpha = this.industries.getParent(anzsic.id);
    
    for (let alphaNode of this.nodes) {
      if (alphaNode.key === alpha.id.toString()) {
        alphaNode.setChecked(alpha.selected);
        alphaNode.isHalfChecked = alpha.halfSelected;
      }
      if (alphaNode.children) {
        for (let anzsicNode of alphaNode.children) {
          if (anzsicNode.key === anzsic.id.toString()) {
            anzsicNode.setChecked(anzsic.selected);
            anzsicNode.isHalfChecked = anzsic.halfSelected;
          }
          if (anzsicNode.children) {
            for (let riscNode of anzsicNode.children) {
              if (riscNode.key === industryId.toString()) {
                riscNode.setChecked(true);
                return true;
              }
            }
          }
        }
      }
    }
    return true;
  }


  private onChange: (_: any) => void;

  writeValue(value: any) {
    if (value) {
      this._selected = value;
      this.setTreeSelections();
    }
  }

  //Setup initial top level nodes and whether selected (or half-selected, etc)
  setTreeSelections(): void {
    if (this.industries.IndustryTreeNodes && this._selected) {
      this._selected.forEach(i => {
        this.industries.setSelected(true, i.id);
      })
    }
    this.nodes = this.generateParentNodes();
  }

  setCheckedIndustries(e: NzFormatEmitEvent) {
    this.industries.setSelected(e.node.isChecked, Number(e.node.key));    //sync data model with changes on the UI
    this._selected = this.industries.getSelected();
    this.onChange(this._selected);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched() { }

  //Add/remove child nodes to UI model as user expands/shrinks to keep UI performant
  nzEvent(event: Required<NzFormatEmitEvent>): void {
    if (event.eventName === 'expand') {
      const node = event.node;
      if (node && node.getChildren().length === 0 && node.isExpanded) {
        node.addChildren(this.getChildNodes(Number(node.key)));
      }
      if (node && !node.isExpanded) {
        node.clearChildren();
      }
    }
  }

  //Retrieve child nodes from data model and add to UI model
  getChildNodes(industryId: number): NzTreeNode[] {
    let childNodes = this.industries.getChildren(industryId);  
    let nodeTree: NzTreeNode[] = [];
    childNodes.forEach(c => {
      let tn = new NzTreeNode({ 'key': c.id.toString(), 'title': c.name });
      tn.isSelectable = false;
      tn.setChecked(c.selected, c.halfSelected);
      tn.isDisableCheckbox = this.readOnly;
      if (!c.childIndustries) {
        tn.isLeaf = true;
      }
      nodeTree.push(tn);
    });
    return nodeTree;
  }

}
