import { DeleteOutline, EditOutline, CaretDownOutline, LoginOutline, CheckCircleOutline } from '@ant-design/icons-angular/icons';
var icons = [DeleteOutline, EditOutline, CaretDownOutline, LoginOutline, CheckCircleOutline];
var ɵ0 = icons;
var AdminModule = /** @class */ (function () {
    function AdminModule() {
    }
    return AdminModule;
}());
export { AdminModule };
export { ɵ0 };
