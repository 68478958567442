import { NgModule } from '@angular/core';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { SharedModule } from '../shared/shared.module';
import { NZ_ICONS } from 'ng-zorro-antd';
import { UnderwritersComponent } from './underwriters/underwriters.component';
import { MarketsComponent } from './markets/markets.component';
import { IconDefinition } from '@ant-design/icons-angular';
import { DeleteOutline, EditOutline, CaretDownOutline, LoginOutline, CheckCircleOutline } from '@ant-design/icons-angular/icons';
import { AddMarketComponent } from './add-market/add-market.component';
import { SearchModule } from '../search/search.module';
import { PolicySelectComponent } from './policy-select/policy-select.component';
import { AddMarketDetailComponent } from './add-market-detail/add-market-detail.component';
import { AddMarketIndustryComponent } from './add-market-industry/add-market-industry.component';
import { AddUnderwriterModalComponent } from './add-underwriter-modal/add-underwriter-modal.component';
import { IndustryMultiselectComponent } from './industry-multiselect/industry-multiselect.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { MarketTableComponent } from './market-table/market-table.component';
import { InsurerSelectComponent } from './insurer-select/insurer-select.component';
import { CoverChangesComponent } from './cover-changes/cover-changes.component';

const icons: IconDefinition[] = [DeleteOutline, EditOutline, CaretDownOutline, LoginOutline, CheckCircleOutline];

@NgModule({
  imports: [
    AdminRoutingModule,
    SharedModule,
    SearchModule
  ],
  providers: [
    { provide: NZ_ICONS, useValue: icons }
  ],
  declarations: [
    AdminComponent,
    UnderwritersComponent,
    MarketsComponent,
    AddMarketComponent,
    PolicySelectComponent,
    AddMarketDetailComponent,
    AddMarketIndustryComponent,
    AddUnderwriterModalComponent,
    IndustryMultiselectComponent,
    ApprovalsComponent,
    MarketTableComponent,
    InsurerSelectComponent,
    CoverChangesComponent
  ]
})
export class AdminModule { }
