import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { StateService } from '../../shared/state.service';
import { State } from 'src/app/shared/state';

@Component({
  selector: 'app-location-filter',
  templateUrl: './location-filter.component.html',
  styleUrls: ['./location-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationFilterComponent),
      multi: true
    }
  ]
})

export class LocationFilterComponent implements OnInit, ControlValueAccessor {
  @Input() _selected: State[] = undefined;
  options: any[] = [];
  allChecked: boolean = false;


  constructor(private stateService: StateService) {
    this.onChange = (_: any) => { };
  }
  

  ngOnInit() {
  }


  private onChange: (_: any) => void;

  writeValue(value: any) {
    if (value !== undefined) {
      this._selected = value;
      this.loadStateOptions();
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched() { }

  changed() {
    this.updateModal();
    this.onChange(this._selected);
  }

  loadStateOptions() {
    if (!this.options || this.options.length === 0) {
      let states = this.stateService.GetAustralianStates();
      states.forEach(s => {
        this.options.push({ label: s.name, value: s.shortName })
      })
    }
    this.setSelected();
  }

  setSelected() {
    this.options.forEach(s => {
      if (this._selected && this._selected.map(s=>s.shortName).includes(s.value)) {
        s.selected = true;
      }
      else {
        delete s.selected;
      }
    })
    this.allChecked = this.options.filter(o => o.selected).length === this.options.length;
  }

  updateModal() {
    let states: State[] = [];
    this.options.forEach(s => {
      if (s.selected) {
        states.push(new State(s.value, ''));
      }
    })
    this._selected = states;
    this.allChecked = this.options.filter(o => o.selected).length === this.options.length;
  }

  selectAll(): void {
    if (this._selected && this._selected.length === this.options.length) {
      this.options.map(c => delete c.selected);
    }
    else {
      this.options.map(c => c.selected = true);
    }
    this.changed();
  }
}
