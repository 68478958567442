<nz-card [nzTitle]="market?.insurer.name"
         [nzBordered]="false"
         [nzHoverable]="true"
         [nzExtra]="extraTemplate"
         class="market-card">
  <!--<div [routerLink]="['/profile', market?.id]">-->
  <div (click)="goToProfile(market)">
  <div class="market-card-content">
    <div class="market-card-logo">
      <img src="{{ market?.insurer.logoUrl }}" alt="market logo" />
    </div>
    <div class="market-card-info">
      <h3>{{ market?.name }}</h3>
      <p>{{ market?.note }}</p>
    </div>
  </div>
  <hr />
  <div class="market-card-link"><a>Read More</a></div>
  </div>
</nz-card>
<ng-template #extraTemplate>
  <app-save-cover [cover]="market"></app-save-cover>
</ng-template>
