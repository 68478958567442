import { Router } from '@angular/router';
import { UserService } from '../shared/user.service';
import * as i0 from "@angular/core";
import * as i1 from "../shared/user.service";
import * as i2 from "@angular/router";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(userService, router) {
        this.userService = userService;
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        var url = next.url[0].path;
        var params = next.paramMap.get("id");
        var linkgen = next.queryParamMap.get('linkgen');
        var industryId = next.queryParamMap.get('industryId');
        if (params) {
            url = url + '/' + params;
        }
        //console.log(linkgen);
        //console.log('authenticated? '+this.userService.isAuthenticated())
        this.checkLinkgen(linkgen, url, industryId);
        return this.checkLogin(url);
    };
    AuthGuard.prototype.checkLogin = function (url) {
        if (this.userService.isAuthenticated() && ((this.userService.isUserInRole('Subscriber') || this.userService.isUserInRole('MarketAdmin')))) {
            return true;
        }
        else {
            this.userService.redirectUrl = url;
            this.router.navigate(['login']);
            return false;
        }
    };
    //Workaround to recheck authentication once linkgen login has completed
    AuthGuard.prototype.checkLinkgen = function (linkgen, url, industryId) {
        var _this = this;
        if (linkgen && !this.userService.isAuthenticated()) {
            console.log('Waiting for linkgen authentication');
            setTimeout(function () {
                //console.log('Checking authentication' + new Date().getSeconds());
                //console.log('authenticated ' + this.userService.isAuthenticated())
                if (_this.userService.isAuthenticated()) {
                    _this.router.navigate([url], { queryParams: { industryId: industryId } });
                    return;
                }
            }, 2000);
        }
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.UserService), i0.inject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
