import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fixUrl'
})
export class FixurlPipe implements PipeTransform {

    transform(href: string): string {
        if (!href) {
            return;
        }
        let url = href.trim();
        return url.startsWith("http://") || url.startsWith("https://") ? url : "http://" + url;
  }

}
