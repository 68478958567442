/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./save-cover.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-zorro-antd/icon";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/common";
import * as i5 from "./save-cover.component";
import * as i6 from "../../shared/market.service";
var styles_SaveCoverComponent = [i0.styles];
var RenderType_SaveCoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SaveCoverComponent, data: {} });
export { RenderType_SaveCoverComponent as RenderType_SaveCoverComponent };
function View_SaveCoverComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveCover(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["nz-icon", ""], ["type", "star"]], null, null, null, null, null)), i1.ɵdid(2, 2834432, null, 0, i2.NzIconDirective, [i2.NzIconService, i1.ElementRef, i1.Renderer2, i3.Platform], { type: [0, "type"] }, null), (_l()(), i1.ɵted(-1, null, ["Save this Market"]))], function (_ck, _v) { var currVal_0 = "star"; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SaveCoverComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveCover(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["nz-icon", ""], ["style", "color:#f1c400;"], ["theme", "fill"], ["type", "star"]], null, null, null, null, null)), i1.ɵdid(2, 2834432, null, 0, i2.NzIconDirective, [i2.NzIconService, i1.ElementRef, i1.Renderer2, i3.Platform], { type: [0, "type"], theme: [1, "theme"] }, null), (_l()(), i1.ɵted(-1, null, ["Saved "]))], function (_ck, _v) { var currVal_0 = "star"; var currVal_1 = "fill"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_SaveCoverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "save-link"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SaveCoverComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SaveCoverComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isSaved(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isSaved(); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_SaveCoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-save-cover", [], null, null, null, View_SaveCoverComponent_0, RenderType_SaveCoverComponent)), i1.ɵdid(1, 114688, null, 0, i5.SaveCoverComponent, [i6.MarketService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SaveCoverComponentNgFactory = i1.ɵccf("app-save-cover", i5.SaveCoverComponent, View_SaveCoverComponent_Host_0, { cover: "cover" }, {}, []);
export { SaveCoverComponentNgFactory as SaveCoverComponentNgFactory };
