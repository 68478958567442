import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../shared/user.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let url: string = next.url[0].path;
    let params: string = next.paramMap.get("id");
    if (params) {
      url = url + '/' + params;
    }
      if (this.checkLogin(url)) {
          if (this.userService.currentUser.insurerId) {
              return true;
          }
          return this.userService.getAssociatedInsurerId().pipe(
              map(res => {
                  if (!res) {
                      this.userService.loginMessage = "No insurer associated with this user account - please contact techsupport@lmigroup.com";
                      this.router.navigate(['login']);
                      console.log('No insurer associated');
                      return false;
                  }
                  console.log('Has associated insurer');
                  return true;
              }))
      }
      return false;
  }

  checkLogin(url: string): boolean {
    if (this.userService.isAuthenticated() && ((this.userService.isUserInRole('Administrator') || this.userService.isUserInRole('MarketAdmin')))) {
      return true;
    }
    else {
      this.userService.redirectUrl = url;
      this.router.navigate(['login']);
      return false;
    }
    }



  canLoad(route: Route): boolean {
    return this.checkLogin(`/${route.path}`);
  }
}
