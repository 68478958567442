import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Policy } from 'src/app/shared/policy';
import { PolicyService } from 'src/app/shared/policy.service';

@Component({
  selector: 'app-policy-select',
  templateUrl: './policy-select.component.html',
  styleUrls: ['./policy-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PolicySelectComponent),
      multi: true
    }
  ]
})

export class PolicySelectComponent implements OnInit, ControlValueAccessor {
  @Input()
  _selected: Policy[] = undefined;

  @Input()
  set insurerId(insurerId: number) {
    this.getPolicies(insurerId);
  }

  policyOptions: Policy[] = [];

  //get diagnostic() { return JSON.stringify(this.insurerId); }

  constructor(private policyService: PolicyService) {
    this.onChange = (_: any) => { };
  }

  ngOnInit() {
  }

  getPolicies(insurerId: number): void {
    if (!insurerId) {
      return;
    }
    this.policyService.getPoliciesForInsurer(insurerId)
        .subscribe(p => this.policyOptions = p);
    
  }

  getSelectedPolicies() {
    return this._selected;
  }

  setSelectedPolicies(_: any) {
    this._selected = _;
    if (typeof _ === "object") {
      this.onChange(this._selected);
    }
  }

  private onChange: (_: any) => void;

  writeValue(value: any) {
    if (value !== undefined) {
      this._selected = value;
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched() { }

  compareFn = (o1: Policy, o2: Policy) => o1 && o2 ? o1.id === o2.id : o1 === o2;
}
