import { Component, OnInit } from '@angular/core';
import { MarketService } from 'src/app/shared/market.service';

@Component({
  selector: 'app-saved-markets',
  templateUrl: './saved-markets.component.html',
  styleUrls: ['./saved-markets.component.scss',
    '../results/results.component.scss']
  
})
export class SavedMarketsComponent implements OnInit {

  constructor(public marketService:MarketService) { }

  //get diagnostic() { return JSON.stringify(this.marketService.savedCovers); }

  ngOnInit() {
  }

}
