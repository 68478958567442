import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Insurer } from './insurer';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InsurerService {

  constructor(private http: HttpClient) { }

  getInsurers(countryId: number, hasCovers: boolean): Observable<Insurer[]> {
    const options = {
        headers: { 'X-Legacy': 'true' },
        params: new HttpParams()
            .set('countryId', countryId.toString())
            .set('hasCovers', hasCovers.toString())
    };
    return this.http.get<Insurer[]>(`${environment.legacyRiskCoachApiBase}/Market/GetInsurers`, options);
  }
}
