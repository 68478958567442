<app-banner>
  <div nz-row nzType="flex" nzJustify="center" nzAlign="middle">
    <div nz-col nzXs="24" nzSm="22" nzMd="22" nzLg="16" nzXl="16">
      <div class="search-container">
        <form class="search-form" nz-form nzLayout="vertical">
          <div nz-row  [nzGutter]="{ xs: 8, sm: 8, md: 24, lg: 32, xl: 32, xxl: 32 }">
            <div nz-col nzMd="12" nzSm="24">
              <nz-form-item class="search-form-item">
                <nz-form-label nzFor="industry">
                  <span class="search-form-item-label">INDUSTRY</span>
                </nz-form-label>
                <nz-form-control>
                  <app-industry-search [(ngModel)]="searchRequest.industries" name="industry" ></app-industry-search>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item class="search-form-item">
                <nz-form-label nzFor="coverType">
                  <span class="search-form-item-label">COVER TYPE</span>
                </nz-form-label>
                <nz-form-control>
                  <app-cover-search [(ngModel)]="searchRequest.covers" name="coverType" ></app-cover-search>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzMd="12" nzSm="24" class="align-center">
              <nz-form-item class="search-form-item">
                <nz-form-label nzFor="location">
                  <span class="search-form-item-label">LOCATION</span>
                </nz-form-label>
                <nz-form-control>
                  <app-location-search [(ngModel)]="searchRequest.states" name="location" ></app-location-search>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
      </div>
      <button class="btn-search" nz-button nzType="primary" nzSize="large" (click)="search()">Let's Start</button>
      <div nz-col nzSpan="24" class="saved-container">
        <div>
          <a routerLink="/saved-markets"><div class="right saved-link"><i nz-icon type="star"></i> Saved Markets</div></a>
        </div>
      </div>
    </div>
    
  </div>
</app-banner>

<!--<div class="market-container">
  <h3>Popular Markets</h3>
   <div nz-row nzType="flex" nzJustify="center">
    <div nz-col nzSpan="4" *ngFor="let m of popularCovers">
      <app-market-card [market]="m" class="listing-vertical"></app-market-card>
    </div>
  </div> 
</div>-->

<div class="banner-about">
  <div nz-row nzType="flex" nzJustify="center" nzAlign="middle">
    <div nz-col nzXs="24" nzSm="22" nzMd="10" nzLg="8" class="banner-about-container">
      <h2><span>Discover </span>The Market</h2>
      <div nz-col nzSpan="24">
        <h3>The Purpose</h3>
        <p>It is no secret that the Australian insurance market is hardening. Many risks that were once very placeable are becoming near impossible to find a home for. While it is becoming harder the fact still remains that there is often an Insurance provider with an appropriate risk appetite for your risk, however, with 100’s of Insurance providers in the Australian market finding the right person to talk to is half the battle.</p>
        <p>
          The Market has been designed to help you with this task by matching the risk appetite of Insurance providers to particular industries and activities, allowing you to quickly find the contact details of the right person to place that risk.
        </p>
        <button class="btn-about" nz-button nzType="primary" routerLink="/contact">Learn more</button>
      </div>
    </div>
      <div nz-col nzXs="24" nzSm="22" nzMd="12" nzLg="8">
        <div class="image-wrap" >
      <!--<img src="../../assets/laptop-mockup.png" alt="market logo" width="">-->  
        <img src="../../assets/about-image.png" alt="market logo" width="">
      </div>
      </div>
   
  </div>
</div>

<!--<div class="market-container">
  <h3>Recent Markets</h3>
  <div nz-row nzType="flex" nzJustify="center">
     <div nz-col nzSpan="4" *ngFor="let m of recentCovers">
      <app-market-card [market]="m" class="listing-vertical"></app-market-card>
    </div>
  </div>
</div>-->
