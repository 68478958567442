import { OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { PolicyService } from 'src/app/shared/policy.service';
var PolicySelectComponent = /** @class */ (function () {
    //get diagnostic() { return JSON.stringify(this.insurerId); }
    function PolicySelectComponent(policyService) {
        this.policyService = policyService;
        this._selected = undefined;
        this.policyOptions = [];
        this.compareFn = function (o1, o2) { return o1 && o2 ? o1.id === o2.id : o1 === o2; };
        this.onChange = function (_) { };
    }
    Object.defineProperty(PolicySelectComponent.prototype, "insurerId", {
        set: function (insurerId) {
            this.getPolicies(insurerId);
        },
        enumerable: true,
        configurable: true
    });
    PolicySelectComponent.prototype.ngOnInit = function () {
    };
    PolicySelectComponent.prototype.getPolicies = function (insurerId) {
        var _this = this;
        if (!insurerId) {
            return;
        }
        this.policyService.getPoliciesForInsurer(insurerId)
            .subscribe(function (p) { return _this.policyOptions = p; });
    };
    PolicySelectComponent.prototype.getSelectedPolicies = function () {
        return this._selected;
    };
    PolicySelectComponent.prototype.setSelectedPolicies = function (_) {
        this._selected = _;
        if (typeof _ === "object") {
            this.onChange(this._selected);
        }
    };
    PolicySelectComponent.prototype.writeValue = function (value) {
        if (value !== undefined) {
            this._selected = value;
        }
    };
    PolicySelectComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    PolicySelectComponent.prototype.registerOnTouched = function () { };
    return PolicySelectComponent;
}());
export { PolicySelectComponent };
