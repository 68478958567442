import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { BannerComponent } from './banner/banner.component';
import { MenuComponent } from './menu/menu.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AppSettings } from './app-settings';
import { FixurlPipe } from './fix-url.pipe';


@NgModule({
  imports: [
    NgZorroAntdModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ],
  declarations: [
    BannerComponent,
    MenuComponent,
    LoginComponent,
    FixurlPipe
  ],
  exports: [
    BannerComponent,
    MenuComponent,
    CommonModule,
    FormsModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    FixurlPipe
  ],
  providers: [
    AppSettings
  ]
})
export class SharedModule { }
