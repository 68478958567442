import { Component, OnInit } from "@angular/core";
import { SearchFiltersService } from "../shared/search-filters.service";
import { SearchRequest } from "../shared/search-request";
import { Router } from "@angular/router";
import { Market } from "../shared/market";
import { MarketService } from "../shared/market.service";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"]
})
export class SearchComponent implements OnInit {
  searchRequest: SearchRequest;
  recentCovers: Market[];
  popularCovers: Market[];

  constructor(private searchFiltersService: SearchFiltersService, private router: Router, private marketService: MarketService) { }
  visible: boolean;

  open(): void  {
    this.visible = true;
    //console.log ("alert")
  }

  close(): void {
    this.visible = false;
  }

  ngOnInit() {
    this.searchRequest = new SearchRequest();
    //this.getPopularCovers();
    //this.getRecentCovers();

  }

  search() {
    this.searchFiltersService.searchRequest = this.searchRequest;
    this.router.navigate(['/results']);
  }

  getRecentCovers(): void {
    this.marketService.getRecentCovers()
      .subscribe(c=>this.recentCovers = c.slice(0,4));
  }

  getPopularCovers(): void {
    this.marketService.getPopularCovers()
      .subscribe(c => this.popularCovers = c.slice(0, 4));
  }
}
