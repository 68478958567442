<label nz-checkbox [(ngModel)]="checked" >Only show insurers with covers</label>
<nz-select style="width: 100%"
           nzSize="large"
           nzPlaceHolder="Select insurer"
           [ngModel]="getSelectedInsurer()"
           (ngModelChange)="setSelectedInsurer($event)"
           nzNotFoundContent="No insurers found..."
           [compareWith]="compareFn"
           nzShowSearch
           nzAllowClear>
  <nz-option *ngFor="let option of insurerOptions" [nzLabel]="option.name" [nzValue]="option"></nz-option>
</nz-select>
