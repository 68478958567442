import * as i0 from "@angular/core";
var UtilityService = /** @class */ (function () {
    function UtilityService() {
    }
    UtilityService.prototype.removeDuplicatesById = function (arr) {
        return arr.filter(function (obj, index, self) { return self.findIndex(function (t) { return t.id === obj.id; }) === index; });
    };
    UtilityService.prototype.getUrlParameter = function (url, parameter) {
        var querystring = url.split("?");
        if (querystring.length > 1) {
            var vars = querystring[1].split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == parameter) {
                    return pair[1];
                }
            }
        }
        return undefined;
    };
    ///Parameter list in format of x=somedata&y=moredata. Supports linkgen payload extraction.
    UtilityService.prototype.getParameterFromParameterList = function (parameterList, parameter) {
        var vars = parameterList.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == parameter) {
                return pair[1];
            }
        }
        return undefined;
    };
    UtilityService.prototype.removeUrlParameter = function (url, parameter) {
        var querystring = url.split("?");
        var newUrl = querystring[0];
        var isFirst = true;
        if (querystring.length > 1) {
            var vars = querystring[1].split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] != parameter) {
                    if (isFirst) {
                        newUrl = newUrl + '?';
                        isFirst = false;
                    }
                    else {
                        newUrl = newUrl + '&';
                    }
                    newUrl = newUrl + pair[0] + '=' + pair[1];
                }
            }
        }
        return newUrl;
    };
    UtilityService.prototype.addUrlParameter = function (url, parameter, value) {
        var querystring = url.split("?");
        if (querystring.length > 1) {
            return url + "&" + parameter + "=" + value;
        }
        return url + "?" + parameter + "=" + value;
    };
    UtilityService.ngInjectableDef = i0.defineInjectable({ factory: function UtilityService_Factory() { return new UtilityService(); }, token: UtilityService, providedIn: "root" });
    return UtilityService;
}());
export { UtilityService };
