<app-banner></app-banner>
<nz-layout class="layout results">
  <nz-content class="results-content">
    <div class="results-container">
      <div nz-row>
        <div nz-col nzSpan="12">
          <h5>Saved Markets</h5>
          <p>You have {{ marketService.savedCovers.length }} saved markets</p>
        </div>
        <div nz-col nzSpan="12">
          <a routerLink="/results" class="saved-link"><i nz-icon type="search"></i>Back to Search</a>
        </div>
      </div>
    </div>
    <nz-list [nzDataSource]="marketService.savedCovers" [nzRenderItem]="item" [nzGrid]="{ gutter: 20, xs: 24, xl: 12 }">
      <ng-template #item let-item>
        <nz-list-item [nzContent]="nzContent">
          <ng-template #nzContent>
            <app-market-card [market]="item"></app-market-card>
          </ng-template>
        </nz-list-item>
      </ng-template>
    </nz-list>
  </nz-content>
</nz-layout>
