<button nz-button nzSize="large" class="btn-back"
        style="margin-left:10px;margin-right:auto;" routerLink="/admin/approvals">
  <i nz-icon type="left"></i>Back
</button>
<br /><br />
<span style="font-weight:bold" *ngIf="!cover?.draft">No previous version to compare.</span>
<nz-table #coverTable [nzData]="[0]" [nzShowPagination]="false" class="market-table" *ngIf="cover">
  <thead>
    <tr class="row-head">
      <th>Field</th>
      <th>Original</th>
      <th>Draft</th>
    </tr>
  </thead>
  <tbody>
    <tr [ngClass]="{'match':cover.insurer.name===cover.draft?.insurer.name}">
      <td>Provider</td>
      <td>{{cover.insurer.name}}</td>
      <td>{{cover.draft?.insurer.name}}</td>
    </tr>
    <tr [ngClass]="{'match':cover.name===cover.draft?.name}">
      <td>Cover name</td>
      <td>{{cover.name}}</td>
      <td>{{cover.draft?.name}}</td>
    </tr>
    <tr [ngClass]="{'match':!arePoliciesDifferent()}">
      <td>Policies</td>
      <td>
        <span *ngFor="let p of cover?.policies">{{p.name}}<br /></span>
      </td>
      <td>
        <span *ngFor="let p of cover?.draft?.policies">{{p.draft.name}}<br /></span>
      </td>
    </tr>
    <tr [ngClass]="{'match':cover.note===cover.draft?.note}">
      <td>Description</td>
      <td>{{cover.note}}</td>
      <td>{{cover.draft?.note}}</td>
    </tr>
    <tr [ngClass]="{'match':!areUnderwritersDifferent()}">
      <td>Underwriters</td>
      <td>
        <span *ngFor="let u of cover?.classStateUnderwriters">{{u.insuranceClass.name}} - {{u.state.shortName}} - {{u.underwriter.name}}<br /></span>
      </td>
      <td>
        <span *ngFor="let u of cover?.draft?.classStateUnderwriters">{{u.insuranceClass.name}} - {{u.state.shortName}} - {{u.underwriter.name}}<br /></span>
      </td>
    </tr>
    <tr [ngClass]="{'match':!areIndustriesDifferent()}">
      <td>Industries</td>
      <td>
        <app-industry-multiselect [(ngModel)]="cover.industries" [enableSearch]="false" [readOnly]="true" ></app-industry-multiselect>
      </td>
      <td>
        <app-industry-multiselect [(ngModel)]="cover.draft.industries" [enableSearch]="false" [readOnly]="true" *ngIf="cover.draft"></app-industry-multiselect>
      </td>
    </tr>
  </tbody>
  </nz-table>
