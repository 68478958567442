import { HeartOutline } from '@ant-design/icons-angular/icons';
var icons = [HeartOutline];
var ɵ0 = icons;
var ContactModule = /** @class */ (function () {
    function ContactModule() {
    }
    return ContactModule;
}());
export { ContactModule };
export { ɵ0 };
