<nz-layout class="layout">
  <header>
    <div routerLink="" class="logo"><img src="assets/logo-themarket.svg" /></div>
    <app-menu></app-menu>
  </header>
  <nz-content>
    <router-outlet></router-outlet>
  </nz-content>
  <footer>
    <p class="copyright">
      © 1999-2020 LMI Group Pty Ltd | ABN 69 086 256 171 | AFS Licence No. 283777
    </p>
    <ul class="social-links">
      <li>
        <a
          href="https://www.facebook.com/pages/LMI-Group-Pty-Ltd/144988182266476"
          target="_blank"
          rel="noopener noreferrer"
        >
          <fa-icon [icon]="['fab', 'facebook-f']"></fa-icon>
        </a>
      </li>
      <li>
        <a href="https://twitter.com/lmigroup" target="_blank" rel="noopener noreferrer">
          <fa-icon [icon]="['fab', 'twitter']"></fa-icon>
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/company/lmi-group" target="_blank" rel="noopener noreferrer">
          <fa-icon [icon]="['fab', 'linkedin']"></fa-icon>
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/user/LMIGROUP" target="_blank" rel="noopener noreferrer">
          <fa-icon [icon]="['fab', 'youtube']"></fa-icon>
        </a>
      </li>
    </ul>
  </footer>
</nz-layout>
