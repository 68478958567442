<div nz-row nzType="flex" nzJustify="center" class="add-market-detail-container">
  <div nz-col nzXs="23" nzSm="23" nzMd="20" nzLg="18" nzXl="16">
    <h3>Add a Market</h3>
    <!-- Progress speps header -->
    <ul class="steps">
      <li><a>Market Details</a></li>
      <li class="dots">
        <i class="fa fa-circle" style="font-size:10px"></i>
        <i class="fa fa-circle" style="font-size:8px"></i>
        <i class="fa fa-circle" style="font-size:6px;"></i>
      </li>
      <li><a class="active">Underwriters</a></li>
      <li class="dots">
        <i class="fa fa-circle" style="font-size:10px"></i>
        <i class="fa fa-circle" style="font-size:8px"></i>
        <i class="fa fa-circle" style="font-size:6px;"></i>
      </li>
      <li><a>Industry Details</a></li>
    </ul>
    <!-- End Progress speps header -->

    <nz-table nz-col nzXs="24" #borderedTable nzBordered [nzData]="cover.classStateUnderwriters"
      [nzShowPagination]="false" *ngFor="let c of cover?.getCovers()">
      <thead>
        <tr class="row-head">
          <th colspan="4">
            <img src="{{c.getIcon()}}" alt="cover logo" />
            {{ c.name }}
          </th>
        </tr>
      </thead>
      <tbody *ngFor="let ucs of cover.getUnderwritersForClass(c.id)">
        <tr class="row-state">
          <td colspan="4">{{ ucs.state.shortName }}</td>
        </tr>
        <tr class="row-data" *ngIf="editUnderwriter != ucs && isMobile === false">
          <td *ngIf="ucs.underwriter.name">{{ ucs.underwriter.name }}</td>
          <td *ngIf="!ucs.underwriter.name"><span style="color:red">No underwriter selected!</span></td>
          <td><i nz-icon type="mail" theme="outline"></i>{{ ucs.underwriter.email }}</td>
          <td><i nz-icon type="phone" theme="outline"></i>{{ ucs.underwriter.phone }}</td>
          <td>
            <button nz-button nzShape="circle" (click)="setEditUnderwriter(ucs)">
              <i nz-icon type="edit"></i>
            </button>
          </td>
        </tr>
        <tr class="row-data" *ngIf="editUnderwriter === ucs && isMobile === false">
          <td>
            <nz-select [(ngModel)]="editUnderwriter.underwriter" style="width: 100%" [compareWith]="compareFn">
              <nz-option *ngFor="let u of allUnderwriters" [nzLabel]="u.name" [nzValue]="u"></nz-option>
            </nz-select>
          </td>
          <td><button nz-button nzType="primary" (click)="addUnderwriterVisible = true">Add Underwriter</button></td>
          <td><button nz-button nzType="primary" (click)="editUnderwriter = null">Save</button></td>
          <td><button nz-button nzType="primary" (click)="cancelEdit()">Cancel</button></td>
        </tr>

        <!--mobile table styling-->

        <tr class="row-data" *ngIf="editUnderwriter != ucs && isMobile === true">
          <td colspan="4" *ngIf="ucs.underwriter.name">{{ ucs.underwriter.name }}</td>
        </tr>
        <tr>
          <td colspan="4" *ngIf="!ucs.underwriter.name && isMobile === true"><span style="color:red">No underwriter
              selected!</span></td>
        </tr>
        <tr class="row-data" *ngIf="isMobile === true">
          <td colspan="4"><i nz-icon type="mail" theme="outline"></i>{{ ucs.underwriter.email }}</td>
        </tr>
        <tr class="row-data" *ngIf="isMobile === true">
          <td colspan="4"><i nz-icon type="phone" theme="outline"></i>{{ ucs.underwriter.phone }}</td>
        </tr>
        <tr class="row-data" *ngIf="isMobile === true">
          <td colspan="4">
            <button nz-button nzShape="circle" (click)="setEditUnderwriter(ucs)">
              <i nz-icon type="edit"></i>
            </button>
          </td>
        </tr>


        <tr class="row-data" *ngIf="editUnderwriter === ucs && isMobile === true">
          <td colspan="4">
            <nz-select [(ngModel)]="editUnderwriter.underwriter" style="width: 100%" [compareWith]="compareFn">
              <nz-option *ngFor="let u of allUnderwriters" [nzLabel]="u.name" [nzValue]="u"></nz-option>
            </nz-select>
          </td>
        </tr>
        <tr class="row-data market-detail-mobile-btn" *ngIf="editUnderwriter === ucs &&  isMobile === true">
          <td colspan="2"><button nz-button nzType="primary" (click)="addUnderwriterVisible = true">Add
              Underwriter</button></td>
          <td><button nz-button nzType="primary" (click)="editUnderwriter = null">Save</button></td>
          <td><button nz-button class="btn-cancel" (click)="cancelEdit()">Cancel</button></td>
        </tr>
        <!--end mobile table styling-->
      </tbody>
      <tbody>
        <tr class="row-footer">
          <td>
            <button nz-button nzType="primary" nzTitle="Edit states" (click)="showAddStates(c)">Edit states</button>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <button nz-button nzType="primary" nzTitle="Edit classes" (click)="showAddClasses()">Edit classes</button>

    <div class="btn-container">
      <button nz-button nzSize="large" class="btn-cancel" (click)="cancel()">Cancel</button>
      <button nz-button nzSize="large" (click)="saveAndBack()" class="btn-back"
        style="margin-left:10px;margin-right:auto;">
        <i nz-icon type="left"></i>Back
      </button>
      <button nz-button nzSize="large" (click)="saveAndExit()" style="margin-left:auto;margin-right:10px;">
        Save & Exit
      </button>
      <button nz-button nzType="primary" nzSize="large" (click)="saveAndNext()">
        Next<i nz-icon type="right"></i>
      </button>
    </div>

    <nz-modal [(nzVisible)]="addStatesVisible" nzTitle="Edit states" (nzOnCancel)="cancelStates()"
      (nzOnOk)="addStatesOk()">
      <app-location-search [(ngModel)]="activeStates"></app-location-search>
      <nz-alert *ngIf="stateError" nzType="warning" nzMessage="You must select at least 1 state"></nz-alert>
    </nz-modal>

    <nz-modal [(nzVisible)]="addClassesVisible" nzTitle="Edit classes" (nzOnCancel)="addClassesCancel()"
      (nzOnOk)="addClassesOk()">
      <app-cover-search [(ngModel)]="activeClasses"></app-cover-search>
      <nz-alert *ngIf="classError" nzType="warning" nzMessage="You must select at least 1 class"></nz-alert>
    </nz-modal>

    <app-add-underwriter-modal [visible]="addUnderwriterVisible" (save)="onSaveUnderwriter($event)"
      (cancel)="onCancelUnderwriter($event)" [isOkLoading]="underwriterLoading"></app-add-underwriter-modal>
  </div>
</div>