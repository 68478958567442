import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { UserService } from '../shared/user.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../shared/user.service";
import * as i2 from "@angular/router";
var AdminAuthGuard = /** @class */ (function () {
    function AdminAuthGuard(userService, router) {
        this.userService = userService;
        this.router = router;
    }
    AdminAuthGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        var url = next.url[0].path;
        var params = next.paramMap.get("id");
        if (params) {
            url = url + '/' + params;
        }
        if (this.checkLogin(url)) {
            if (this.userService.currentUser.insurerId) {
                return true;
            }
            return this.userService.getAssociatedInsurerId().pipe(map(function (res) {
                if (!res) {
                    _this.userService.loginMessage = "No insurer associated with this user account - please contact techsupport@lmigroup.com";
                    _this.router.navigate(['login']);
                    console.log('No insurer associated');
                    return false;
                }
                console.log('Has associated insurer');
                return true;
            }));
        }
        return false;
    };
    AdminAuthGuard.prototype.checkLogin = function (url) {
        if (this.userService.isAuthenticated() && ((this.userService.isUserInRole('Administrator') || this.userService.isUserInRole('MarketAdmin')))) {
            return true;
        }
        else {
            this.userService.redirectUrl = url;
            this.router.navigate(['login']);
            return false;
        }
    };
    AdminAuthGuard.prototype.canLoad = function (route) {
        return this.checkLogin("/" + route.path);
    };
    AdminAuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AdminAuthGuard_Factory() { return new AdminAuthGuard(i0.inject(i1.UserService), i0.inject(i2.Router)); }, token: AdminAuthGuard, providedIn: "root" });
    return AdminAuthGuard;
}());
export { AdminAuthGuard };
