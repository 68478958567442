import { OnInit, EventEmitter } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Underwriter } from 'src/app/shared/underwriter';
var AddUnderwriterModalComponent = /** @class */ (function () {
    function AddUnderwriterModalComponent(fb) {
        this.fb = fb;
        this._underwriter = new Underwriter(0, '', '', '');
        this.save = new EventEmitter();
        this.cancel = new EventEmitter();
    }
    Object.defineProperty(AddUnderwriterModalComponent.prototype, "underwriter", {
        get: function () { return this._underwriter; },
        set: function (underwriter) {
            this._underwriter = underwriter;
            this.setValues();
        },
        enumerable: true,
        configurable: true
    });
    AddUnderwriterModalComponent.prototype.ngOnInit = function () {
        this.validateForm = this.fb.group({
            email: [null, [Validators.email, Validators.required]],
            name: [null, [Validators.required]],
            phone: [null, [Validators.required]],
        });
    };
    AddUnderwriterModalComponent.prototype.setValues = function () {
        if (this.validateForm) {
            this.validateForm.get('name').setValue(this.underwriter.name);
            this.validateForm.get('email').setValue(this.underwriter.email);
            this.validateForm.get('phone').setValue(this.underwriter.phone);
        }
    };
    AddUnderwriterModalComponent.prototype.onSubmit = function () {
        for (var i in this.validateForm.controls) {
            this.validateForm.controls[i].markAsDirty();
            this.validateForm.controls[i].updateValueAndValidity();
        }
        if (this.validateForm.valid) {
            this.underwriter.name = this.validateForm.get('name').value;
            this.underwriter.email = this.validateForm.get('email').value;
            this.underwriter.phone = this.validateForm.get('phone').value;
            this.save.emit(this.underwriter);
            this.validateForm.reset();
        }
    };
    AddUnderwriterModalComponent.prototype.resetForm = function () {
        this.cancel.emit(true);
        this.validateForm.reset();
    };
    return AddUnderwriterModalComponent;
}());
export { AddUnderwriterModalComponent };
