import { Underwriter } from "./underwriter";
import { Cover } from "./cover";
import { State } from "./state";
import { Serializable } from "./serializable";

export class UnderwriterClassState implements Serializable<UnderwriterClassState> {
  constructor(
    public underwriter?: Underwriter,
    public insuranceClass?: Cover,
    public state?: State
  ) { }

  deserialize(object: any): UnderwriterClassState{
    this.underwriter = new Underwriter().deserialize(object.underwriter);
    this.insuranceClass = new Cover().deserialize(object.insuranceClass);
    this.state = new State().deserialize(object.state);
    return this;
  }
}
