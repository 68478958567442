import { Component, OnInit } from '@angular/core';
import { MarketService } from 'src/app/shared/market.service';
import { Market } from 'src/app/shared/market';

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.scss']
})
export class ApprovalsComponent implements OnInit {
  markets: Market[];
  selectedMarket: Market = undefined;
  isApprove: boolean = undefined;
  //checked: boolean = false;

  constructor(private marketService: MarketService) { }

  ngOnInit() {
    //this.reloadMarkets();
      this.getMarketsAwaitingApproval();
  }

  
  getMarketsAwaitingApproval(): void {
    this.marketService.getMarketsAwaitingApproval()
      .subscribe(markets => {
        this.markets = markets;
      });
  }

  //reloadMarkets(): void {
    //if (this.checked) {
    //  this.getMarkets();
    //}
    //else {
      //this.getMarketsAwaitingApproval();
    //}
  //}

  //Get all markets
  //getMarkets(): void {
  //  this.marketService.getMarkets()
  //    .subscribe(markets => {
  //      this.markets = markets;
  //    });
  //}

}
