import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var InsurerService = /** @class */ (function () {
    function InsurerService(http) {
        this.http = http;
    }
    InsurerService.prototype.getInsurers = function (countryId, hasCovers) {
        var options = {
            headers: { 'X-Legacy': 'true' },
            params: new HttpParams()
                .set('countryId', countryId.toString())
                .set('hasCovers', hasCovers.toString())
        };
        return this.http.get(environment.legacyRiskCoachApiBase + "/Market/GetInsurers", options);
    };
    InsurerService.ngInjectableDef = i0.defineInjectable({ factory: function InsurerService_Factory() { return new InsurerService(i0.inject(i1.HttpClient)); }, token: InsurerService, providedIn: "root" });
    return InsurerService;
}());
export { InsurerService };
