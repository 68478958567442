<div class="banner-container">
  <div nz-row nzType="flex" nzJustify="center" nzAlign="middle">
    <div nz-col nzXs="24" nzSm="20" nzMd="16"  nzLg="16" nzXl="16">
      <div class="banner-header">
        <h1>FIND THE PERFECT <span>HOME FOR THAT RISK</span></h1>
        <p>
          Match your client's industry or business activity to an insurer by searching our extensive database of insurance providers and their risk appetite.
        </p>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</div>
