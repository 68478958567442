<h2>Approvals</h2>
<p>
  Here you can review and approve new market submissions and edits from underwriters before they appear live. 
</p>
<p>
  Note that fields are editable so you can make changes as part of the review process.
</p>
<!--<div class="btn-container">
  <button nz-button
          nzType="primary"
          [nzSize]="'large'"
          routerLink="/admin/add-market">
    <i nz-icon type="plus"></i>ADD NEW
  </button>
</div>-->
<!--<label nz-checkbox [(ngModel)]="checked" (click)="reloadMarkets()">Show all markets</label>-->
<app-market-table [markets]="markets" [allowApprovals]="true" [showView]="true"></app-market-table>
