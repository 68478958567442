import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MarketService } from 'src/app/shared/market.service';
import { UnderwriterClassState } from 'src/app/shared/underwriter-class-state';
import { State } from 'src/app/shared/state';
import { Cover } from 'src/app/shared/cover';
import { Market } from 'src/app/shared/market';
import {
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';
import { UserService } from '../../shared/user.service';
import { Insurer } from '../../shared/insurer';

@Component({
    selector: 'app-add-market',
    templateUrl: './add-market.component.html',
    styleUrls: ['./add-market.component.scss']
})

export class AddMarketComponent implements OnInit {
    validateForm: FormGroup;
    cover: Market = new Market(0);
    insuranceClasses: Cover[];
    states: State[];


    constructor(
        private marketService: MarketService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.activatedRoute.params.subscribe((params: Params) => {
            if (params['id']) {
                this.getCover(Number(params['id']));
            }
        });

        this.activatedRoute.queryParams.subscribe((params => {
            if (params['insurer'] && this.isAdmin()) {
                this.cover.insurer = new Insurer(Number(params['insurer']));
            }
        }));

        this.validateForm = this.fb.group({
            name: [null, [Validators.required]],
            notes: [null]
        });
    }

    isAdmin(): boolean {
        return this.userService.isUserInRole("Administrator");
    }


    createMarketCover(nextPage: boolean): void {
        //Generate starting set of class/state mappings for cover
        this.cover.classStateUnderwriters = [];
        this.insuranceClasses.forEach(c => {
            this.states.forEach(s => {
                this.cover.classStateUnderwriters.push(new UnderwriterClassState(null, c, s));
            })
        });
        this.marketService.createMarketCover(this.cover)
            .subscribe(m => {
                this.cover.id = m.id;
                this.navigate(nextPage);
            });
    }

    updateCover(nextPage: boolean): void {
        this.marketService.updateMarketCover(this.cover)
            .subscribe(a => {
                this.navigate(nextPage);
            });
    }

    navigate(nextPage: boolean): void {
        if (nextPage) {
            this.router.navigate(['admin/add-market-detail', this.cover.id]);
        }
        else {
            this.router.navigate(['admin']);
        }
    }

    getCover(id: number): void {
        this.marketService.getMarket(id)
            .subscribe(cov => {
                this.cover = cov.getEditVersion();
                this.setValues();
            });
    }

    setValues(): void {
        this.validateForm.get('name').setValue(this.cover.name);
        this.validateForm.get('notes').setValue(this.cover.note);
    }


    submit(event): void {
        for (const i in this.validateForm.controls) {
            this.validateForm.controls[i].markAsDirty();
            this.validateForm.controls[i].updateValueAndValidity();
        }
        if (this.validateForm.valid) {
            this.cover.name = this.validateForm.get('name').value;
            this.cover.note = this.validateForm.get('notes').value;

            let navNext = event === 'next' ? true : false;

            if (!this.cover.id) {
                this.createMarketCover(navNext);
            }
            else {
                this.updateCover(navNext);
            }
        }
    }


    cancel(): void {
        this.router.navigate(['admin']);
    }


}
