import { Industry } from './industry';
import { Cover } from './cover';
import { State } from './state';

export class SearchRequest {
  industries: Industry[];
  states: State[];
  covers: Cover[];
  pageSize: number;
  page: number;

  constructor() {
    this.pageSize = 20;
    this.page = 1;
  }

  clear() {
    this.industries = [];
    this.states = [];
    this.covers = [];
    this.page = 1;
  }
}
