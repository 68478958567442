import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../shared/user.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../shared/user.service";
import * as i2 from "@angular/router";
var HttpResponseInterceptor = /** @class */ (function () {
    function HttpResponseInterceptor(userService, router) {
        this.userService = userService;
        this.router = router;
    }
    HttpResponseInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        return next.handle(request).pipe(tap(function (event) {
            if (event instanceof HttpResponse) {
                // normal response (ie. 200)
            }
        }, function (err) {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401 && _this.router.url != "/login") {
                    _this.userService.redirectUrl = _this.router.url;
                    _this.router.navigate(['login']);
                }
            }
        }));
    };
    HttpResponseInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function HttpResponseInterceptor_Factory() { return new HttpResponseInterceptor(i0.inject(i1.UserService), i0.inject(i2.Router)); }, token: HttpResponseInterceptor, providedIn: "root" });
    return HttpResponseInterceptor;
}());
export { HttpResponseInterceptor };
