import { OnInit } from '@angular/core';
import { SearchFiltersService } from '../../shared/search-filters.service';
import { SearchRequest } from '../../shared/search-request';
import { MarketService } from '../../shared/market.service';
import { ActivatedRoute } from '@angular/router';
import { IndustryService } from 'src/app/shared/industry.service';
import { SearchResults } from 'src/app/shared/search-results';
import { GoogleAnalyticsService } from '../../shared/google-analytics.service';
import { UtilityService } from '../../shared/utility.service';
import { UserService } from '../../shared/user.service';
import { Location } from '@angular/common';
var ResultsComponent = /** @class */ (function () {
    function ResultsComponent(searchFiltersService, marketService, route, industryService, googleAnalyticsService, utilityService, userService, location) {
        this.searchFiltersService = searchFiltersService;
        this.marketService = marketService;
        this.route = route;
        this.industryService = industryService;
        this.googleAnalyticsService = googleAnalyticsService;
        this.utilityService = utilityService;
        this.userService = userService;
        this.location = location;
        this.marketResults = new SearchResults();
        this.loading = false;
        //get diagnostic() { return JSON.stringify(this.searchRequest); }
        this.openMap = {
            sub1: true,
            sub2: false,
            sub3: false
        };
        this.isCollapsed = true;
        this.isMobile = false;
    }
    Object.defineProperty(ResultsComponent.prototype, "searchRequest", {
        get: function () {
            return this._searchRequest;
        },
        set: function (searchRequest) {
            this.marketResults.clear();
            this._searchRequest = searchRequest;
            this._searchRequest.page = 1;
            this.search();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResultsComponent.prototype, "covers", {
        get: function () {
            return this._searchRequest.covers;
        },
        set: function (covers) {
            this.marketResults.clear();
            this._searchRequest.covers = covers;
            this._searchRequest.page = 1;
            this.search();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResultsComponent.prototype, "states", {
        get: function () {
            return this._searchRequest.states;
        },
        set: function (states) {
            this.marketResults.clear();
            this._searchRequest.states = states;
            this._searchRequest.page = 1;
            this.search();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResultsComponent.prototype, "industries", {
        get: function () {
            return this._searchRequest.industries;
        },
        set: function (industries) {
            this.marketResults.clear();
            this._searchRequest.industries = industries;
            this._searchRequest.page = 1;
            this.search();
        },
        enumerable: true,
        configurable: true
    });
    ResultsComponent.prototype.log = function (value) {
        //console.log(value);
    };
    ResultsComponent.prototype.search = function () {
        var _this = this;
        this.loading = true;
        this.marketService.searchMarkets(this.searchRequest).subscribe(function (a) {
            _this.loading = false;
            _this.marketResults = a;
            _this.sendStats();
        });
    };
    ResultsComponent.prototype.sendStats = function () {
        var eventLabel = [];
        if (this.searchRequest.industries) {
            eventLabel = eventLabel.concat(this.searchRequest.industries.map(function (i) { return i.name; }));
        }
        if (this.searchRequest.covers) {
            eventLabel = eventLabel.concat(this.searchRequest.covers.map(function (c) { return c.name; }));
        }
        if (this.searchRequest.states) {
            eventLabel = eventLabel.concat(this.searchRequest.states.map(function (s) { return s.shortName; }));
        }
        this.googleAnalyticsService.eventEmitter('Search', eventLabel.toString());
    };
    ResultsComponent.prototype.clearFilters = function () {
        this.searchRequest.clear();
        this.search();
    };
    ResultsComponent.prototype.toggleCollapsed = function () {
        this.isCollapsed = !this.isCollapsed;
    };
    ResultsComponent.prototype.getIsMobile = function () {
        var w = document.documentElement.clientWidth;
        var breakpoint = 1024;
        //console.log(w);
        if (w < breakpoint) {
            return true;
        }
        else {
            return false;
        }
    };
    ResultsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var linkgen = this.utilityService.getUrlParameter(this.location.path(), "linkgen");
        if (linkgen) {
            var linkgenDecoded = atob(linkgen);
            var payload = this.utilityService.getParameterFromParameterList(linkgenDecoded, 'EncryptedData');
            this.userService.getTokenFromLinkgen(payload).subscribe(function () {
                _this.userService.refreshUserFromToken();
            });
        }
        this.isMobile = this.getIsMobile();
        window.onresize = function () {
            _this.isMobile = _this.getIsMobile();
        };
        this.route.queryParams.subscribe(function (params) {
            var industryId = params['industryId'];
            if (industryId) {
                var s_1 = new SearchRequest();
                s_1.industries = [];
                s_1.states = [];
                s_1.covers = [];
                _this.industryService.getIndustry(industryId).subscribe(function (i) {
                    s_1.industries.push(i);
                    _this.googleAnalyticsService.eventEmitter('Querystring Industry', i.name + ',' + i.id);
                    _this.searchRequest = s_1;
                });
            }
            else {
                _this.searchRequest = _this.searchFiltersService.searchRequest;
            }
        });
    };
    ResultsComponent.prototype.open = function () {
        this.visible = true;
        //console.log ("alert")
    };
    ResultsComponent.prototype.close = function () {
        this.visible = false;
    };
    return ResultsComponent;
}());
export { ResultsComponent };
