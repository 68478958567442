import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var IndustryService = /** @class */ (function () {
    function IndustryService(http) {
        this.http = http;
    }
    IndustryService.prototype.searchIndustries = function (term) {
        var options = {
            headers: {
                'NoAuth': 'true'
            }
        };
        return this.http.get(environment.apiBase + "/industries?q=" + term, options).pipe(map(function (industries) { return industries.filter(function (i) { return !i.alias; }); }));
    };
    IndustryService.prototype.getIndustry = function (id) {
        var options = {
            headers: {
                'NoAuth': 'true'
            }
        };
        return this.http.get(environment.apiBase + "/industries/" + id, options);
    };
    IndustryService.ngInjectableDef = i0.defineInjectable({ factory: function IndustryService_Factory() { return new IndustryService(i0.inject(i1.HttpClient)); }, token: IndustryService, providedIn: "root" });
    return IndustryService;
}());
export { IndustryService };
