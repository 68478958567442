import { NgModule } from '@angular/core';
import { ContactComponent } from './contact.component';

import { NZ_ICONS } from 'ng-zorro-antd';
import { SharedModule } from '../shared/shared.module';
import { IconDefinition } from '@ant-design/icons-angular';
import { HeartOutline } from '@ant-design/icons-angular/icons';
const icons: IconDefinition[] = [HeartOutline];

@NgModule({
  imports: [
    SharedModule
  ],
  providers: [
    { provide: NZ_ICONS, useValue: icons }
  ],
  declarations: [
    ContactComponent
  ]
})
export class ContactModule { }
