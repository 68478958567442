import { Component, OnInit } from '@angular/core';
import { SearchFiltersService } from '../../shared/search-filters.service';
import { SearchRequest } from '../../shared/search-request';
import { MarketService } from '../../shared/market.service';
import { Cover } from '../../shared/cover';
import { Industry } from '../../shared/industry';
import { State } from 'src/app/shared/state';
import { ActivatedRoute } from '@angular/router';
import { IndustryService } from 'src/app/shared/industry.service';
import { SearchResults } from 'src/app/shared/search-results';
import { GoogleAnalyticsService } from '../../shared/google-analytics.service';
import { UtilityService } from '../../shared/utility.service';
import { UserService } from '../../shared/user.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
  private _searchRequest: SearchRequest;
  set searchRequest(searchRequest: SearchRequest) {
    this.marketResults.clear();
    this._searchRequest = searchRequest;
    this._searchRequest.page = 1;
    this.search();
  }
  get searchRequest(): SearchRequest {
    return this._searchRequest;
  }
  set covers(covers: Cover[]) {
    this.marketResults.clear();
    this._searchRequest.covers = covers;
    this._searchRequest.page = 1;
    this.search();
  }
  get covers(): Cover[] {
    return this._searchRequest.covers;
  }
  set states(states: State[]) {
    this.marketResults.clear();
    this._searchRequest.states = states;
    this._searchRequest.page = 1;
    this.search();
  }
  get states(): State[] {
    return this._searchRequest.states;
  }
  set industries(industries: Industry[]) {
    this.marketResults.clear();
    this._searchRequest.industries = industries;
    this._searchRequest.page = 1;
    this.search();
  }
  get industries(): Industry[] {
    return this._searchRequest.industries;
  }

  marketResults: SearchResults = new SearchResults();

  loading: Boolean = false;
  //get diagnostic() { return JSON.stringify(this.searchRequest); }

  openMap = {
    sub1: true,
    sub2: false,
    sub3: false
  };

  constructor(private searchFiltersService: SearchFiltersService,
    private marketService: MarketService,
    private route: ActivatedRoute,
    private industryService: IndustryService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private utilityService: UtilityService,
    private userService: UserService,
  private location:Location) { }

  log(value: string[]): void {
    //console.log(value);
  }


  search() {
    this.loading = true;
    this.marketService.searchMarkets(this.searchRequest).subscribe(a => {
      this.loading = false;
      this.marketResults = a;
      this.sendStats();
    });
  }

  sendStats() {
    var eventLabel=[];
    if (this.searchRequest.industries) {
      eventLabel = eventLabel.concat(this.searchRequest.industries.map(i => i.name));
    }
    if (this.searchRequest.covers) {
      eventLabel = eventLabel.concat(this.searchRequest.covers.map(c => c.name));
    }
    if (this.searchRequest.states) {
      eventLabel = eventLabel.concat(this.searchRequest.states.map(s => s.shortName));
    }
    this.googleAnalyticsService.eventEmitter('Search', eventLabel.toString());
  }

  clearFilters() {
    this.searchRequest.clear();
    this.search();
  }

  isCollapsed = true;

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  isMobile = false;
  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 1024;
    //console.log(w);
    if (w < breakpoint) {
      return true;
    } else {
      return false;
    }
  
  }

  ngOnInit() {
    let linkgen = this.utilityService.getUrlParameter(this.location.path(), "linkgen");
    if (linkgen) {
      let linkgenDecoded = atob(linkgen);
      let payload = this.utilityService.getParameterFromParameterList(linkgenDecoded, 'EncryptedData');
      this.userService.getTokenFromLinkgen(payload).subscribe(() => {
        this.userService.refreshUserFromToken();
      });
    }


    this.isMobile = this.getIsMobile();
    window.onresize = () => {
      this.isMobile = this.getIsMobile();
    };

    this.route.queryParams.subscribe(params => {
      const industryId = params['industryId'];
      if (industryId) {
        let s = new SearchRequest();
        s.industries = [];
        s.states = [];
        s.covers = [];
        this.industryService.getIndustry(industryId).subscribe(i => {
          s.industries.push(i);
          this.googleAnalyticsService.eventEmitter('Querystring Industry', i.name + ',' + i.id);
          this.searchRequest = s;
        });
      }
      else {
        this.searchRequest = this.searchFiltersService.searchRequest;
      }
    });
  }
  
  visible: boolean;

  open(): void  {
    this.visible = true;
    //console.log ("alert")
  }

  close(): void {
    this.visible = false;
  }

  
}
