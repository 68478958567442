<app-banner></app-banner>
<div nz-row nzType="flex" nzJustify="center" class="layout results">

  <div *ngIf="isMobile === false" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="8" nzXl="5" class="results-sider">
    <ul nz-menu [nzMode]="'inline'">
      <li class="filter">
        <h5>Filter and Refine</h5>
        <a (click)="clearFilters()">Reset All</a>
      </li>
      <li nz-submenu [(nzOpen)]="openMap.sub1">
        <span class="submenu-title" title><i nz-icon type="environment"></i>Location</span>
        <app-location-filter [(ngModel)]="states" class="submenu-inner"></app-location-filter>
      </li>
      <li nz-submenu [(nzOpen)]="openMap.sub2">
        <span class="submenu-title" title><i nz-icon type="shop"></i>Industry</span>
        <app-industry-search [(ngModel)]="industries" size="default" class="submenu-inner"></app-industry-search>
      </li>
      <li nz-submenu [(nzOpen)]="openMap.sub3">
        <span class="submenu-title" title><i nz-icon type="warning"></i>Insurance Cover</span>
        <app-cover-filter [(ngModel)]="covers" class="submenu-inner"></app-cover-filter>
      </li>
    </ul>
  </div>

  <!-- menu for mobile screens <div *ngIf="isMobile === true" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="8" nzXl="5" class="results-sider-mobile">-->

 
    <div *ngIf="isMobile === true" class="drawer-handle" (click)="open()"><i nz-icon type="filter"></i> <span></span></div>
    <nz-drawer [nzClosable]="true" [nzVisible]="visible" nzPlacement="left" nzTitle="Filter and Refine"
      (nzOnClose)="close()" class="drawer-content">
      <ul nz-menu [nzMode]="'inline'" class="drawer">
        <li class="filter">
          <a (click)="clearFilters()">Reset All</a>
        </li>
        <li nz-submenu [(nzOpen)]="openMap.sub1">
          <span class="submenu-title" title><i nz-icon type="environment"></i>Location</span>
          <app-location-filter [(ngModel)]="states" class="submenu-inner"></app-location-filter>
        </li>
        <li nz-submenu [(nzOpen)]="openMap.sub2">
          <span class="submenu-title" title><i nz-icon type="shop"></i>Industry</span>
          <app-industry-search [(ngModel)]="industries" size="default" class="submenu-inner"></app-industry-search>
        </li>
        <li nz-submenu [(nzOpen)]="openMap.sub3">
          <span class="submenu-title" title><i nz-icon type="warning"></i>Insurance Cover</span>
          <app-cover-filter [(ngModel)]="covers" class="submenu-inner"></app-cover-filter>
        </li>
      </ul>

    </nz-drawer>
  

  <!-- menu for mobile screens -->


  <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="16" nzXl="19" class="results-content">

    <div class="results-container">
      <div nz-row>
        <div nz-col nzSpan="12">
          <h5>Market Results</h5>
          <p>You have {{ marketResults.totalResults }} matching markets</p>
        </div>
        <div nz-col nzSpan="12">
          <a routerLink="/saved-markets" class="saved-link"><i nz-icon type="star"></i>Saved Markets</a>
        </div>
        <div nz-col nzSpan="24">
          <app-search-tags [(ngModel)]="searchRequest"></app-search-tags>
        </div>
      </div>
    </div>
    <nz-list [nzDataSource]="marketResults.covers" [nzRenderItem]="item" [nzGrid]="{ gutter: 20, xs: 24, xl: 12 }"
      [nzLoading]="loading">
      <ng-template #item let-item>
        <nz-list-item [nzContent]="nzContent">
          <ng-template #nzContent>
            <app-market-card [market]="item"></app-market-card>
          </ng-template>
        </nz-list-item>
      </ng-template>
    </nz-list>
    <div nz-row nzJustify="center" nzType="flex">
      <nz-pagination [(nzPageIndex)]="searchRequest.page" [nzHideOnSinglePage]="true"
        [nzTotal]="marketResults.totalResults" [nzPageSize]="searchRequest.pageSize" [nzShowTotal]="rangeTemplate"
        (nzPageIndexChange)="search()">
      </nz-pagination>
      <ng-template #rangeTemplate let-range="range" let-total> </ng-template>
    </div>
  </div>

</div>