<nz-select style="width: 100%"
           nzSize="large"
           nzMode="multiple"
           nzPlaceHolder="Select covers"
           [ngModel]="getSelectedCovers()"
           (ngModelChange)="setSelectedCovers($event)"
           [compareWith]="compareFn"
           >
  <nz-option nzCustomContent nzLabel="" ><span (click)="selectAll()">Select All</span></nz-option>
  <nz-option *ngFor="let option of coverOptions" [nzLabel]="option.name" [nzValue]="option"></nz-option>
</nz-select>
