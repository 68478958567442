/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cover-search.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ng-zorro-antd/select/ng-zorro-antd-select.ngfactory";
import * as i3 from "ng-zorro-antd/select";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/common";
import * as i8 from "./cover-search.component";
import * as i9 from "../../shared/cover.service";
var styles_CoverSearchComponent = [i0.styles];
var RenderType_CoverSearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CoverSearchComponent, data: {} });
export { RenderType_CoverSearchComponent as RenderType_CoverSearchComponent };
function View_CoverSearchComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nz-option", [], null, null, null, i2.View_NzOptionComponent_0, i2.RenderType_NzOptionComponent)), i1.ɵdid(1, 573440, [[1, 4]], 0, i3.NzOptionComponent, [], { nzLabel: [0, "nzLabel"], nzValue: [1, "nzValue"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CoverSearchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "nz-select", [["nzMode", "multiple"], ["nzPlaceHolder", "Select covers"], ["nzSize", "large"], ["style", "width: 100%"]], [[2, "ant-select-lg", null], [2, "ant-select-sm", null], [2, "ant-select-enabled", null], [2, "ant-select-no-arrow", null], [2, "ant-select-disabled", null], [2, "ant-select-allow-clear", null], [2, "ant-select-open", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).toggleDropDown() !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = (_co.setSelectedCovers($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_NzSelectComponent_0, i2.RenderType_NzSelectComponent)), i1.ɵprd(512, null, i3.NzSelectService, i3.NzSelectService, []), i1.ɵdid(2, 5488640, null, 2, i3.NzSelectComponent, [i1.Renderer2, i3.NzSelectService, i1.ChangeDetectorRef, i4.FocusMonitor, i5.Platform, i1.ElementRef, [8, null]], { nzSize: [0, "nzSize"], nzPlaceHolder: [1, "nzPlaceHolder"], nzMode: [2, "nzMode"], compareWith: [3, "compareWith"] }, null), i1.ɵqud(603979776, 1, { listOfNzOptionComponent: 1 }), i1.ɵqud(603979776, 2, { listOfNzOptionGroupComponent: 1 }), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.NzSelectComponent]), i1.ɵdid(6, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(8, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 3, "nz-option", [["nzCustomContent", ""], ["nzLabel", ""]], null, null, null, i2.View_NzOptionComponent_0, i2.RenderType_NzOptionComponent)), i1.ɵdid(10, 573440, [[1, 4]], 0, i3.NzOptionComponent, [], { nzLabel: [0, "nzLabel"], nzCustomContent: [1, "nzCustomContent"] }, null), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Select All"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CoverSearchComponent_1)), i1.ɵdid(14, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_14 = "large"; var currVal_15 = "Select covers"; var currVal_16 = "multiple"; var currVal_17 = _co.compareFn; _ck(_v, 2, 0, currVal_14, currVal_15, currVal_16, currVal_17); var currVal_18 = _co.getSelectedCovers(); _ck(_v, 6, 0, currVal_18); var currVal_19 = ""; var currVal_20 = ""; _ck(_v, 10, 0, currVal_19, currVal_20); var currVal_21 = _co.coverOptions; _ck(_v, 14, 0, currVal_21); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).nzSize === "large"); var currVal_1 = (i1.ɵnov(_v, 2).nzSize === "small"); var currVal_2 = !i1.ɵnov(_v, 2).nzDisabled; var currVal_3 = !i1.ɵnov(_v, 2).nzShowArrow; var currVal_4 = i1.ɵnov(_v, 2).nzDisabled; var currVal_5 = i1.ɵnov(_v, 2).nzAllowClear; var currVal_6 = i1.ɵnov(_v, 2).open; var currVal_7 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_8 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_9 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_10 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_11 = i1.ɵnov(_v, 8).ngClassValid; var currVal_12 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_13 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 0, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13]); }); }
export function View_CoverSearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-cover-search", [], null, null, null, View_CoverSearchComponent_0, RenderType_CoverSearchComponent)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.CoverSearchComponent]), i1.ɵdid(2, 114688, null, 0, i8.CoverSearchComponent, [i9.CoverService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CoverSearchComponentNgFactory = i1.ɵccf("app-cover-search", i8.CoverSearchComponent, View_CoverSearchComponent_Host_0, { _selected: "_selected" }, {}, []);
export { CoverSearchComponentNgFactory as CoverSearchComponentNgFactory };
