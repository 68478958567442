import { Component, OnInit } from '@angular/core';
import { MarketService } from 'src/app/shared/market.service';
import { Market } from 'src/app/shared/market';
import { UserService } from '../../shared/user.service';
import { Insurer } from '../../shared/insurer';
import { Router } from '@angular/router';

@Component({
    selector: 'app-markets',
    templateUrl: './markets.component.html',
    styleUrls: ['./markets.component.scss']
})
export class MarketsComponent implements OnInit {
    markets: Market[];
    private _insurer: Insurer;
    set insurer(insurer: Insurer) {
        this._insurer = insurer;
        this.getMarkets();
    }
    get insurer(): Insurer {
        return this._insurer;
    }

    

    constructor(private marketService: MarketService, public userService: UserService, private router:Router) { }

    ngOnInit() {
        this.getMarkets();
        this.insurer = new Insurer(this.userService.currentUser.insurerId);

    }

    getMarkets(): void {
        if (this.userService.isUserInRole('Administrator') && this.insurer) {
            this.marketService.getMarketsForInsurerId(this.insurer.id)
                .subscribe(markets => {
                    this.markets = markets;
                });
        }
        else {
            this.marketService.getMarketsForInsurer()
                .subscribe(markets => {
                    this.markets = markets;
                });
        }
    }

    addMarket(): void {
        
        if (this.userService.isUserInRole('Administrator') && this.insurer) {
            this.router.navigate(['/admin/add-market'], { queryParams: { insurer: this.insurer.id } });
        }
        else {
            this.router.navigate(['/admin/add-market']);
        }
    }

}
