import { Component, OnInit } from '@angular/core';
import { Market } from 'src/app/shared/market';
import { MarketService } from 'src/app/shared/market.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { UserService } from '../../shared/user.service';

@Component({
  selector: 'app-add-market-industry',
  templateUrl: './add-market-industry.component.html',
  styleUrls: ['./add-market-industry.component.scss']
})
export class AddMarketIndustryComponent implements OnInit {
  cover: Market = new Market(0, '');

  constructor(private marketService: MarketService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService)
  { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.getCover(Number(params['id']));
    });
  }

  //get diagnostic() { return JSON.stringify(this.cover.industries); }


  getCover(coverId:number): void {
    this.marketService.getMarket(coverId)
      .subscribe(c => {
        this.cover = c.getEditVersion();
      });
  }


  updateCover(): void {
    this.marketService.updateMarketCover(this.cover)
      .subscribe();
  }

  submitCover(coverId: number, finish: boolean): void {
    this.marketService.submitCover(coverId)
      .subscribe(() => {
        if (finish) {
          this.navigateFinish();
        }
        else {
          this.router.navigate(['/admin/add-market-detail', this.cover.id]);
        }
      });
  }

  save(finish: boolean): void {
    this.updateCover();
    this.submitCover(this.cover.id, finish);
  }

  cancel(): void {
    this.navigateFinish();
  }


  navigateFinish(): void {
    if (this.userService.isAdmin()) {
      this.router.navigate(['/admin/approvals']);
    }
    else {
      this.router.navigate(['admin']);
    }
  }


}
