import { Serializable } from "./serializable";

export class Insurer implements Serializable<Insurer> {
    constructor(
        public id: number,
        public name?: string,
        public logoUrl?: string,
        public email?: string,
        public phone?: string,
        public websiteUrl?: string
    ) { }

    deserialize(object: any): Insurer {
        this.id = object.id;
        this.name = object.name;
        this.logoUrl = object.logoUrl;
        this.email = object.email;
        this.phone = object.phone;
        this.websiteUrl = object.websiteUrl;
        return this;
    }

}
