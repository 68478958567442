import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { Industry } from './industry';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class IndustryService {

  constructor(private http: HttpClient) { }

  searchIndustries(term: string): Observable<Industry[]> {
    const options = {
      headers: {
        'NoAuth': 'true'
      }
    };
    return this.http.get<Industry[]>(`${environment.apiBase}/industries?q=${term}`, options).pipe(
      map(industries => industries.filter(i => !i.alias))
    );
  }

  getIndustry(id: number): Observable<Industry> {
    const options = {
      headers: {
        'NoAuth': 'true'
      }
    };
    return this.http.get<Industry>(`${environment.apiBase}/industries/${id}`, options);
  }
}
