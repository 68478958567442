import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../shared/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let url: string = next.url[0].path;
    let params: string = next.paramMap.get("id");
    let linkgen: string = next.queryParamMap.get('linkgen');
    let industryId: string = next.queryParamMap.get('industryId');
    if (params) {
      url = url + '/' + params;
    }
    //console.log(linkgen);
    //console.log('authenticated? '+this.userService.isAuthenticated())
    this.checkLinkgen(linkgen, url, industryId);
    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    if (this.userService.isAuthenticated() && ((this.userService.isUserInRole('Subscriber') || this.userService.isUserInRole('MarketAdmin')))) {
      return true;
    }
    else {
      this.userService.redirectUrl = url;
      this.router.navigate(['login']);
      return false;
    }
  }

  //Workaround to recheck authentication once linkgen login has completed
  checkLinkgen(linkgen: string, url: string, industryId: string): void {
    if (linkgen && !this.userService.isAuthenticated()) {
      console.log('Waiting for linkgen authentication');
        setTimeout(() => {
          //console.log('Checking authentication' + new Date().getSeconds());
          //console.log('authenticated ' + this.userService.isAuthenticated())
          if (this.userService.isAuthenticated()) {
            this.router.navigate([url], { queryParams: { industryId: industryId } });
            return;
          }
        },
          2000);
    }
  }

}
