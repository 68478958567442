/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search-tags.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ng-zorro-antd/tag/ng-zorro-antd-tag.ngfactory";
import * as i3 from "ng-zorro-antd/core";
import * as i4 from "ng-zorro-antd/tag";
import * as i5 from "@angular/common";
import * as i6 from "@angular/forms";
import * as i7 from "./search-tags.component";
var styles_SearchTagsComponent = [i0.styles];
var RenderType_SearchTagsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchTagsComponent, data: {} });
export { RenderType_SearchTagsComponent as RenderType_SearchTagsComponent };
function View_SearchTagsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "nz-tag", [["nzMode", "closeable"]], [[40, "@fadeMotion", 0], [4, "background-color", null]], [[null, "nzAfterClose"], ["component", "@fadeMotion.done"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("component:@fadeMotion.done" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).afterAnimation($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).updateCheckedStatus() !== false);
        ad = (pd_1 && ad);
    } if (("nzAfterClose" === en)) {
        var pd_2 = (_co.handleClose(_v.context.$implicit, "cover") !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_NzTagComponent_0, i2.RenderType_NzTagComponent)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(2, 638976, null, 0, i4.NzTagComponent, [i1.Renderer2, i1.ElementRef, i3.NzUpdateHostClassService], { nzMode: [0, "nzMode"] }, { nzAfterClose: "nzAfterClose" }), (_l()(), i1.ɵted(3, 0, ["", ""]))], function (_ck, _v) { var currVal_2 = "closeable"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = undefined; var currVal_1 = (i1.ɵnov(_v, 2).presetColor ? null : i1.ɵnov(_v, 2).nzColor); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_3); }); }
function View_SearchTagsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "nz-tag", [["nzMode", "closeable"]], [[40, "@fadeMotion", 0], [4, "background-color", null]], [[null, "nzAfterClose"], ["component", "@fadeMotion.done"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("component:@fadeMotion.done" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).afterAnimation($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).updateCheckedStatus() !== false);
        ad = (pd_1 && ad);
    } if (("nzAfterClose" === en)) {
        var pd_2 = (_co.handleClose(_v.context.$implicit, "industry") !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_NzTagComponent_0, i2.RenderType_NzTagComponent)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(2, 638976, null, 0, i4.NzTagComponent, [i1.Renderer2, i1.ElementRef, i3.NzUpdateHostClassService], { nzMode: [0, "nzMode"] }, { nzAfterClose: "nzAfterClose" }), (_l()(), i1.ɵted(3, 0, ["", ""]))], function (_ck, _v) { var currVal_2 = "closeable"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = undefined; var currVal_1 = (i1.ɵnov(_v, 2).presetColor ? null : i1.ɵnov(_v, 2).nzColor); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_3); }); }
function View_SearchTagsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "nz-tag", [["nzMode", "closeable"]], [[40, "@fadeMotion", 0], [4, "background-color", null]], [[null, "nzAfterClose"], ["component", "@fadeMotion.done"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("component:@fadeMotion.done" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).afterAnimation($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).updateCheckedStatus() !== false);
        ad = (pd_1 && ad);
    } if (("nzAfterClose" === en)) {
        var pd_2 = (_co.handleClose(_v.context.$implicit, "state") !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_NzTagComponent_0, i2.RenderType_NzTagComponent)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(2, 638976, null, 0, i4.NzTagComponent, [i1.Renderer2, i1.ElementRef, i3.NzUpdateHostClassService], { nzMode: [0, "nzMode"] }, { nzAfterClose: "nzAfterClose" }), (_l()(), i1.ɵted(3, 0, ["", ""]))], function (_ck, _v) { var currVal_2 = "closeable"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = undefined; var currVal_1 = (i1.ɵnov(_v, 2).presetColor ? null : i1.ɵnov(_v, 2).nzColor); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.shortName; _ck(_v, 3, 0, currVal_3); }); }
export function View_SearchTagsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "selected-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchTagsComponent_1)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchTagsComponent_2)), i1.ɵdid(4, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchTagsComponent_3)), i1.ɵdid(6, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co._values == null) ? null : _co._values.covers); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co._values == null) ? null : _co._values.industries); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co._values == null) ? null : _co._values.states); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_SearchTagsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-search-tags", [], null, null, null, View_SearchTagsComponent_0, RenderType_SearchTagsComponent)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.SearchTagsComponent]), i1.ɵdid(2, 114688, null, 0, i7.SearchTagsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SearchTagsComponentNgFactory = i1.ɵccf("app-search-tags", i7.SearchTagsComponent, View_SearchTagsComponent_Host_0, { _values: "_values" }, {}, []);
export { SearchTagsComponentNgFactory as SearchTagsComponentNgFactory };
