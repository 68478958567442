/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./approvals.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../market-table/market-table.component.ngfactory";
import * as i3 from "../market-table/market-table.component";
import * as i4 from "../../shared/market.service";
import * as i5 from "./approvals.component";
var styles_ApprovalsComponent = [i0.styles];
var RenderType_ApprovalsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ApprovalsComponent, data: {} });
export { RenderType_ApprovalsComponent as RenderType_ApprovalsComponent };
export function View_ApprovalsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Approvals"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Here you can review and approve new market submissions and edits from underwriters before they appear live. "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Note that fields are editable so you can make changes as part of the review process.\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-market-table", [], null, null, null, i2.View_MarketTableComponent_0, i2.RenderType_MarketTableComponent)), i1.ɵdid(7, 114688, null, 0, i3.MarketTableComponent, [i4.MarketService], { markets: [0, "markets"], allowApprovals: [1, "allowApprovals"], showView: [2, "showView"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.markets; var currVal_1 = true; var currVal_2 = true; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ApprovalsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-approvals", [], null, null, null, View_ApprovalsComponent_0, RenderType_ApprovalsComponent)), i1.ɵdid(1, 114688, null, 0, i5.ApprovalsComponent, [i4.MarketService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApprovalsComponentNgFactory = i1.ɵccf("app-approvals", i5.ApprovalsComponent, View_ApprovalsComponent_Host_0, {}, {}, []);
export { ApprovalsComponentNgFactory as ApprovalsComponentNgFactory };
