import { Injectable } from '@angular/core';
import { State } from './state';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor() { }

  GetAustralianStates(): State[] {
    let states = [];
    states.push(new State("ACT", "Australian Capital Territory"));
    states.push(new State("NT", "Northern Territory"));
    states.push(new State("NSW", "New South Wales"));
    states.push(new State("QLD", "Queensland"));
    states.push(new State("SA", "South Australia"));
    states.push(new State("TAS", "Tasmania"));
    states.push(new State("VIC", "Victoria"));
    states.push(new State("WA", "Western Australia"));
    return states;
  }
}
