<div class="header-nav">
  
<div *ngIf="isMobile === false">        
          <ul nz-menu class="menu-horizontal" [nzMode]="'horizontal'">
            <li nz-menu-item class="menu-item" routerLink="/" [nzSelected]="isSelected('/')">HOME</li>
            <li nz-menu-item *ngIf="isUserInRole('MarketAdmin') || isUserInRole('Administrator')" class="menu-item"
              (click)="adminLink()" [nzSelected]="isSelected('/admin/my-markets') || isSelected('/admin/approvals') || isSelected('/admin/underwriters')">
              ADMIN
            </li>
            <li nz-menu-item class="menu-item" routerLink="/contact" [nzSelected]="isSelected('/contact')">CONTACT</li>
            <li nz-menu-item *ngIf="!isAuthenticated()" class="menu-item menu-login" [routerLink]="['/login']">
              <i nz-icon type="login"></i> LOGIN
            </li>
         
          </ul>       
  </div>
 
    <!-- menu for mobile screens -->
    <div *ngIf="isMobile === true" class="menu-mobile" style="height: 58px;">
        <ul nz-menu nzMode="horizontal" class="menu-horizontal-mobile">
            <li nz-menu-item class="mobile-menu-item"> 
        <nz-dropdown  [nzPlacement]="'bottomCenter'" >
            <a nz-dropdown nzTrigger="click"> <span title><i nz-icon type="menu"></i></span> </a>
            <ul nz-menu class="mobile-menu-dropdown"  nzSelectable>
              
                    <li nz-menu-item class="menu-item" routerLink="/" [nzSelected]="isSelected('/')">HOME</li>
                    <li nz-menu-item *ngIf="isUserInRole('MarketAdmin') || isUserInRole('Administrator')" class="menu-item"
                      (click)="adminLink()" [nzSelected]="isSelected('/admin/my-markets') || isSelected('/admin/approvals') || isSelected('/admin/underwriters')">
                      ADMIN
                    </li>
                    <li nz-menu-item class="menu-item" routerLink="/contact" [nzSelected]="isSelected('/contact')">CONTACT</li>                
            </ul>
          </nz-dropdown>
        </li>
        <li nz-menu-item *ngIf="!isAuthenticated()" class="menu-item menu-login" [routerLink]="['/login']">
          <i nz-icon type="login"></i> LOGIN
        </li>
      </ul>
      </div>
    <!-- end menu for mobile screens -->
  <div *ngIf="isAuthenticated()" class="header-user">
    <nz-avatar [nzShape]="'square'" [nzSize]="'large'" [nzText]="getUserName().charAt(0).toUpperCase()"
      class="header-avatar"></nz-avatar>
    <div *ngIf="isMobile === false" class="header-name">{{ getUserName() }}</div>
    <nz-dropdown [nzPlacement]="'bottomRight'" [nzTrigger]="'click'">
      <a nz-dropdown class="header-dropdown">
        <i class="fa fa-ellipsis-v header-icon"></i>
      </a>
      <ul nz-menu>
        <li nz-menu-item>
          <a (click)="logout()">LOGOUT</a>
        </li>
      </ul>
    </nz-dropdown>
  </div>
</div>
