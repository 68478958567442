import { Component, Input } from "@angular/core";
import { Market } from "src/app/shared/market";
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../shared/google-analytics.service';

@Component({
  selector: "app-market-card",
  templateUrl: "./market-card.component.html",
  styleUrls: ["./market-card.component.scss"]
})

export class MarketCardComponent {
  @Input() market: Market;

  constructor(private router:Router, private googleAnalyticsService: GoogleAnalyticsService) { }

  goToProfile(market: Market) {
    this.router.navigate(['/profile', market.id]);
    this.googleAnalyticsService.eventEmitter('View Cover Details', market.name + ',' + market.id);
  }
}
