import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Insurer } from '../../shared/insurer';
import { InsurerService } from '../../shared/insurer.service';

@Component({
    selector: 'app-insurer-select',
    templateUrl: './insurer-select.component.html',
    styleUrls: ['./insurer-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InsurerSelectComponent),
            multi: true
        }
    ]
})

export class InsurerSelectComponent implements OnInit, ControlValueAccessor {
    @Input()
    _selected: Insurer = undefined;

    insurerOptions: Insurer[] = [];

    private _checked: boolean = false;
    set checked(checked: boolean) {
        this._checked = checked;
        this.getInsurers();
    }
    get checked(): boolean {
        return this._checked;
    }

    constructor(private insurerService: InsurerService) {
        this.onChange = (_: any) => { };
    }

    ngOnInit() {
        this.getInsurers();
    }

    getInsurers(): void {
        this.insurerService.getInsurers(1, this.checked)
            .subscribe(i => this.insurerOptions = i);
    }

    private onChange: (_: any) => void;

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched() { }

    getSelectedInsurer() {
        return this._selected;
    }

    writeValue(value: any) {
        if (value !== undefined) {
            this._selected = value;
        }
    }

    setSelectedInsurer(_: any) {
        this._selected = _;
        if (typeof _ === "object") {
            this.onChange(this._selected);
        }
    }

    compareFn = (i1: Insurer, i2: Insurer) => i1 && i2 ? i1.id === i2.id : i1 === i2;



}
