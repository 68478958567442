import { UserRole } from "./user-role";

export class User {
  public userName: string;
  public password: string;
  public newPassword: string;
  public confirmPassword: string;
  public personName: string;
  public email: string;
  public logoUrl: string;
  public profileLogo: any;
  public companyName: string;
  public question: string;
  public firstName: string;
  public lastName: string;
  public phoneNumber: string;
  public rememberMe: boolean;
  public userId: string;
  public passwordResetId: string;
    public userRoles: UserRole[];
    public insurerId: number;
}
