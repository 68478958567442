import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PolicyService = /** @class */ (function () {
    function PolicyService(http) {
        this.http = http;
    }
    PolicyService.prototype.getPoliciesForInsurer = function (insurerId) {
        var options = {
            headers: { 'X-Legacy': 'true' }
        };
        return this.http.get(environment.legacyRiskCoachApiBase + "/Market/GetPolicies?insurerId=" + insurerId, options);
    };
    PolicyService.ngInjectableDef = i0.defineInjectable({ factory: function PolicyService_Factory() { return new PolicyService(i0.inject(i1.HttpClient)); }, token: PolicyService, providedIn: "root" });
    return PolicyService;
}());
export { PolicyService };
