import { Component, OnInit } from '@angular/core';
import { Underwriter } from 'src/app/shared/underwriter';
import { Cover } from 'src/app/shared/cover';
import { MarketService } from 'src/app/shared/market.service';
import { CoverService } from 'src/app/shared/cover.service';
import { UserService } from '../../shared/user.service';
import { Insurer } from '../../shared/insurer';

@Component({
    selector: 'app-underwriters',
    templateUrl: './underwriters.component.html',
    styleUrls: ['./underwriters.component.scss']
})
export class UnderwritersComponent implements OnInit {
    underwriters: Underwriter[];
    underwritersSelect: Underwriter[];
    underwriterDelete: Underwriter;
    newUnderwriter: Underwriter;
    insuranceClasses: Cover[];
    defaultUnderwriters: { [id: number]: Underwriter };
    replaceUnderwriterVisible: boolean = false;

    underwriterDefaultsVisible: boolean = false;

    addUnderwriterVisible: boolean = false;
    underwriterLoading: boolean = false;
    underwriterEdit: Underwriter;

    private _insurer: Insurer;
    set insurer(insurer: Insurer) {
        this._insurer = insurer;
        this.getMarketUnderwriters();
    }
    get insurer(): Insurer {
        return this._insurer;
    }


    constructor(private marketService: MarketService, private coverService: CoverService, public userService: UserService) { }
    isMobile = false;
    getIsMobile(): boolean {
        const w = document.documentElement.clientWidth;
        const breakpoint = 992;
        console.log(w);
        if (w < breakpoint) {
            return true;
        } else {
            return false;
        }
    }

    ngOnInit() {
        this.isMobile = this.getIsMobile();
        window.onresize = () => {
            this.isMobile = this.getIsMobile();
        };

        this.getMarketUnderwriters();
        this.getInsuranceClasses();
        this.insurer = new Insurer(this.userService.currentUser.insurerId);
    }

    //get diagnostic() { return JSON.stringify(this.defaultUnderwriters); }

    getInsuranceClasses(): void {
        this.coverService.getCovers()
            .subscribe(ic => this.insuranceClasses = ic);
    }

    getMarketUnderwriters(): void {
        let insurerid = this.insurer ? this.insurer.id : null;
        this.marketService.getUnderwritersForInsurer(insurerid)
            .subscribe(u => {
                this.underwriters = u;
                this.setDefaultUnderwriters(u);
            });
    }

    setDefaultUnderwriters(underwriters: Underwriter[]): void {
        this.defaultUnderwriters = {};
        for (let u of underwriters) {
            for (let ic of u.insuranceClasses) {
                this.defaultUnderwriters[ic.id] = u;
            }
        }
    }

    replaceUnderwriterModal(underwriter: Underwriter): void {
        this.underwritersSelect = this.underwriters.filter(u => u !== underwriter);
        this.underwriterDelete = underwriter;
        this.replaceUnderwriterVisible = true;
    }

    confirmUserDelete(underwriter: Underwriter): void {
        if (this.underwriters.length === 1 && underwriter.hasMarketsAssigned) {
            alert("This underwriter is assigned to a market and cannot be deleted until another underwriter is created.");
            return;
        }
        if (this.underwriters.length > 1 && underwriter.hasMarketsAssigned) {
            this.replaceUnderwriterModal(underwriter)
            return;
        }
        if (confirm("Are you sure you wish to delete this underwriter?")) {
            this.underwriters = this.underwriters.filter(u => u !== underwriter);
            this.marketService.deleteMarketUnderwriter(underwriter.id).subscribe();
        }

    }

    deleteUnderwriter(): void {
        this.underwriters = this.underwriters.filter(u => u !== this.underwriterDelete);
        let u = this.underwriterDelete.id;
        this.marketService.replaceUnderwriter(this.underwriterDelete.id, this.newUnderwriter.id)
            .subscribe(a => {
                this.marketService.deleteMarketUnderwriter(u).subscribe();
                this.newUnderwriter.hasMarketsAssigned = true;
            });
        this.underwriterDelete = null;
        this.replaceUnderwriterVisible = false;
    }

    //Update underwriter defaults where changed
    saveDefaultUnderwriters(): void {
        let unds: Underwriter[] = [];
        for (let ic of this.insuranceClasses) {
            if (this.defaultUnderwriters[ic.id]) //if the class has a default underwriter
            {
                if (this.defaultUnderwriters[ic.id].insuranceClasses.filter(a => a.id == ic.id).length == 0) { //check if insurance class is mapped to underwriter, if not, add it
                    this.defaultUnderwriters[ic.id].insuranceClasses.push(ic);
                    if (unds.filter(a => a == this.defaultUnderwriters[ic.id]).length == 0) { //if we don't already have this underwriter
                        unds.push(this.defaultUnderwriters[ic.id]);
                    }
                }
            }
        }
        unds.map(a => this.marketService.updateMarketUnderwriter(a)
            .subscribe());

        this.underwriterDefaultsVisible = false
    }


    onSaveUnderwriter(u: Underwriter): void {
        this.underwriterLoading = true;
        if (u.id) {
            this.marketService.updateMarketUnderwriter(u).subscribe();
            this.underwriterLoading = false;
            this.addUnderwriterVisible = false;
        }
        else {
            let insurerId = this.insurer ? this.insurer.id : null;
            this.marketService.addMarketUnderwriter(u, insurerId)
                .subscribe(und => {
                    this.underwriterLoading = false;
                    this.addUnderwriterVisible = false;
                    this.underwriters = [...this.underwriters, und];
                });
        }
    }


    onCancelUnderwriter(c: boolean): void {
        this.addUnderwriterVisible = false;
    }

    addUnderwriter(): void {
        this.underwriterEdit = new Underwriter(0, '', '', '');
        this.addUnderwriterVisible = true;
    }

    editUnderwriter(u: Underwriter): void {
        this.underwriterEdit = u;
        this.addUnderwriterVisible = true;
    }


    cancelDefaultUnderwriters(): void {
        this.underwriterDefaultsVisible = false;
        this.setDefaultUnderwriters(this.underwriters);
    }
}
