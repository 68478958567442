<app-banner></app-banner>
<nz-layout class="profile">
  <div nz-row nzType="flex" nzJustify="center" nzAlign="middle">
    <div nz-col nzXs="23" nzSm="20" nzMd="20" nzLg="20" nzXl="16">
      <div class="btn-back" routerLink="/results"><i nz-icon type="left"></i><span>Back to search results</span></div>
    </div>
  </div>
  <nz-divider class="yellow-divider"></nz-divider>
  <div nz-row nzType="flex" nzJustify="center" nzAlign="middle" class="profile-content">
    <div nz-col nzXs="23" nzSm="20" nzMd="20" nzLg="20" nzXl="16">
      <nz-card [nzTitle]="market?.insurer.name" [nzBordered]="false" [nzExtra]="extraTemplate" class="profile-card">
        <div class="market-info">
          <div class="logo">
            <img src="{{ market?.insurer.logoUrl }}" alt="insurer logo" />
          </div>
          <div class="market-info-text">
            <h3>{{ market?.name }}</h3>
            <div class="content">
              <p class="content-link">
                <a href="{{market?.insurer.websiteUrl | fixUrl}}" target="_blank">
                  <i class="fa fa-hand-pointer "></i>
                  {{market?.insurer.websiteUrl}}
                </a>
                <a href="tel:{{market?.insurer.phone}}">
                  <i class="fa fa-phone "></i>
                  {{market?.insurer.phone}}
                </a>
                <a href="mailto: {{market?.insurer.email}}">
                  <i class="fa fa-envelope "></i>
                  {{market?.insurer.email}}
                </a>
              </p>
            </div>
          </div>
        </div>
        <nz-divider></nz-divider>
        <div nz-row nzType="flex">
          <div nz-col nzSpan="24">
            <div class="section">
              <strong class="section-title">Policies:</strong>
              <div class="section-content">
                <span *ngIf="!market || !market.policies || market.policies.length == 0">None specified</span>
                <span *ngFor="let p of market?.policies; index as i">
                  <a href="{{ p.wording }}" target="_blank">{{ p.name }}</a>
                  <nz-divider *ngIf="i + 1 < market.policies.length" nzType="vertical"></nz-divider>
                </span>
              </div>
            </div>
            <div class="section">
              <strong class="section-title">Covers:</strong>
              <div class="section-content">
                <span *ngIf="!market || !market.getCovers() || market.getCovers().length == 0">None
                  specified</span>
                <span *ngFor="let c of market?.getCovers(); index as i">
                  <img src="{{ c.getIcon() }}" alt="market logo" height="17" />
                  {{ c.name }}
                  <nz-divider *ngIf="i + 1 < market.getCovers().length" nzType="vertical"></nz-divider>
                </span>
              </div>
            </div>
            <div class="section">
              <strong class="section-title">States:</strong>
              <div class="section-content">
                <span *ngIf="!market || !market.getStates() || market.getStates().length == 0">None
                  specified</span>
                <span *ngFor="let s of market?.getStates(); index as i">
                  {{ s.name }}
                  <nz-divider *ngIf="i + 1 < market.getStates().length" nzType="vertical"></nz-divider>
                </span>
              </div>
            </div>
          </div>

        </div>
        <nz-divider></nz-divider>
        <p>
          <span *ngIf="market?.note == ''">No description available.</span>
          {{ market?.note }}
        </p>
        <nz-divider></nz-divider>
        <nz-collapse nzAccordion>
          <nz-collapse-panel *ngFor="let c of market?.getCovers()" [nzHeader]="c.name">
            <nz-table #table #borderedTable nzBordered [nzData]="getUnderwriters(c.id)" [nzShowPagination]="false">
              <thead>
                <tr class="row-head">
                  <th colspan="3"><img src="{{ c.getIcon() }}" alt="market logo" />{{ c.name }}</th>
                </tr>
              </thead>
              <tbody *ngFor="let ucs of table.data">
                <tr class="row-state" *ngIf="isMobile === false && ucs.name">
                  <td><i class="fa fa-user"></i>{{ ucs.name }}</td>
                  <td>
                    <a href="mailto:{{ ucs.email }}"><i class="fa fa-envelope"></i>{{ ucs.email }}</a>
                  </td>
                  <td>
                    <a href="tel:{{ ucs.phone }}"><i class="fa fa-phone"></i>{{ ucs.phone }}</a>
                  </td>
                </tr>
                <!--Mobile screen table-->
                <tr class="row-state" *ngIf="isMobile === true && ucs.name">
                  <td colspan="3"><i class="fa fa-user"></i>{{ ucs.name }}</td>
                </tr>
                <tr class="row-state" *ngIf="isMobile === true">
                  <td colspan="3">
                    <a href="mailto:{{ ucs.email }}"><i class="fa fa-envelope"></i>{{ ucs.email }}</a>
                  </td>
                </tr>
                <tr class="row-state" *ngIf="isMobile === true">
                  <td colspan="3">
                    <a href="tel:{{ ucs.phone }}"><i class="fa fa-phone"></i>{{ ucs.phone }}</a>
                  </td>
                </tr>
                <!--end Mobile screen table-->
                <tr class="row-data">
                  <td colspan="3">
                    <span *ngFor="let state of ucs.states" class="ant-tag market-state-tag {{ state.toLowerCase() }}">
                      {{ state }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </nz-collapse-panel>
        </nz-collapse>
      </nz-card>
      <ng-template #extraTemplate>
        <app-save-cover [cover]="market"></app-save-cover>
      </ng-template>
    </div>
  </div>
</nz-layout>