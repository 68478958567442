import { OnInit } from '@angular/core';
import { Underwriter } from 'src/app/shared/underwriter';
import { MarketService } from 'src/app/shared/market.service';
import { CoverService } from 'src/app/shared/cover.service';
import { UserService } from '../../shared/user.service';
import { Insurer } from '../../shared/insurer';
var UnderwritersComponent = /** @class */ (function () {
    function UnderwritersComponent(marketService, coverService, userService) {
        this.marketService = marketService;
        this.coverService = coverService;
        this.userService = userService;
        this.replaceUnderwriterVisible = false;
        this.underwriterDefaultsVisible = false;
        this.addUnderwriterVisible = false;
        this.underwriterLoading = false;
        this.isMobile = false;
    }
    Object.defineProperty(UnderwritersComponent.prototype, "insurer", {
        get: function () {
            return this._insurer;
        },
        set: function (insurer) {
            this._insurer = insurer;
            this.getMarketUnderwriters();
        },
        enumerable: true,
        configurable: true
    });
    UnderwritersComponent.prototype.getIsMobile = function () {
        var w = document.documentElement.clientWidth;
        var breakpoint = 992;
        console.log(w);
        if (w < breakpoint) {
            return true;
        }
        else {
            return false;
        }
    };
    UnderwritersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isMobile = this.getIsMobile();
        window.onresize = function () {
            _this.isMobile = _this.getIsMobile();
        };
        this.getMarketUnderwriters();
        this.getInsuranceClasses();
        this.insurer = new Insurer(this.userService.currentUser.insurerId);
    };
    //get diagnostic() { return JSON.stringify(this.defaultUnderwriters); }
    UnderwritersComponent.prototype.getInsuranceClasses = function () {
        var _this = this;
        this.coverService.getCovers()
            .subscribe(function (ic) { return _this.insuranceClasses = ic; });
    };
    UnderwritersComponent.prototype.getMarketUnderwriters = function () {
        var _this = this;
        var insurerid = this.insurer ? this.insurer.id : null;
        this.marketService.getUnderwritersForInsurer(insurerid)
            .subscribe(function (u) {
            _this.underwriters = u;
            _this.setDefaultUnderwriters(u);
        });
    };
    UnderwritersComponent.prototype.setDefaultUnderwriters = function (underwriters) {
        this.defaultUnderwriters = {};
        for (var _i = 0, underwriters_1 = underwriters; _i < underwriters_1.length; _i++) {
            var u = underwriters_1[_i];
            for (var _a = 0, _b = u.insuranceClasses; _a < _b.length; _a++) {
                var ic = _b[_a];
                this.defaultUnderwriters[ic.id] = u;
            }
        }
    };
    UnderwritersComponent.prototype.replaceUnderwriterModal = function (underwriter) {
        this.underwritersSelect = this.underwriters.filter(function (u) { return u !== underwriter; });
        this.underwriterDelete = underwriter;
        this.replaceUnderwriterVisible = true;
    };
    UnderwritersComponent.prototype.confirmUserDelete = function (underwriter) {
        if (this.underwriters.length === 1 && underwriter.hasMarketsAssigned) {
            alert("This underwriter is assigned to a market and cannot be deleted until another underwriter is created.");
            return;
        }
        if (this.underwriters.length > 1 && underwriter.hasMarketsAssigned) {
            this.replaceUnderwriterModal(underwriter);
            return;
        }
        if (confirm("Are you sure you wish to delete this underwriter?")) {
            this.underwriters = this.underwriters.filter(function (u) { return u !== underwriter; });
            this.marketService.deleteMarketUnderwriter(underwriter.id).subscribe();
        }
    };
    UnderwritersComponent.prototype.deleteUnderwriter = function () {
        var _this = this;
        this.underwriters = this.underwriters.filter(function (u) { return u !== _this.underwriterDelete; });
        var u = this.underwriterDelete.id;
        this.marketService.replaceUnderwriter(this.underwriterDelete.id, this.newUnderwriter.id)
            .subscribe(function (a) {
            _this.marketService.deleteMarketUnderwriter(u).subscribe();
            _this.newUnderwriter.hasMarketsAssigned = true;
        });
        this.underwriterDelete = null;
        this.replaceUnderwriterVisible = false;
    };
    //Update underwriter defaults where changed
    UnderwritersComponent.prototype.saveDefaultUnderwriters = function () {
        var _this = this;
        var unds = [];
        var _loop_1 = function (ic) {
            if (this_1.defaultUnderwriters[ic.id]) //if the class has a default underwriter
             {
                if (this_1.defaultUnderwriters[ic.id].insuranceClasses.filter(function (a) { return a.id == ic.id; }).length == 0) { //check if insurance class is mapped to underwriter, if not, add it
                    this_1.defaultUnderwriters[ic.id].insuranceClasses.push(ic);
                    if (unds.filter(function (a) { return a == _this.defaultUnderwriters[ic.id]; }).length == 0) { //if we don't already have this underwriter
                        unds.push(this_1.defaultUnderwriters[ic.id]);
                    }
                }
            }
        };
        var this_1 = this;
        for (var _i = 0, _a = this.insuranceClasses; _i < _a.length; _i++) {
            var ic = _a[_i];
            _loop_1(ic);
        }
        unds.map(function (a) { return _this.marketService.updateMarketUnderwriter(a)
            .subscribe(); });
        this.underwriterDefaultsVisible = false;
    };
    UnderwritersComponent.prototype.onSaveUnderwriter = function (u) {
        var _this = this;
        this.underwriterLoading = true;
        if (u.id) {
            this.marketService.updateMarketUnderwriter(u).subscribe();
            this.underwriterLoading = false;
            this.addUnderwriterVisible = false;
        }
        else {
            var insurerId = this.insurer ? this.insurer.id : null;
            this.marketService.addMarketUnderwriter(u, insurerId)
                .subscribe(function (und) {
                _this.underwriterLoading = false;
                _this.addUnderwriterVisible = false;
                _this.underwriters = _this.underwriters.concat([und]);
            });
        }
    };
    UnderwritersComponent.prototype.onCancelUnderwriter = function (c) {
        this.addUnderwriterVisible = false;
    };
    UnderwritersComponent.prototype.addUnderwriter = function () {
        this.underwriterEdit = new Underwriter(0, '', '', '');
        this.addUnderwriterVisible = true;
    };
    UnderwritersComponent.prototype.editUnderwriter = function (u) {
        this.underwriterEdit = u;
        this.addUnderwriterVisible = true;
    };
    UnderwritersComponent.prototype.cancelDefaultUnderwriters = function () {
        this.underwriterDefaultsVisible = false;
        this.setDefaultUnderwriters(this.underwriters);
    };
    return UnderwritersComponent;
}());
export { UnderwritersComponent };
