<div nz-row nzType="flex" nzJustify="center" class="add-market-container">
  <div nz-col nzXs="22" nzSm="22" nzMd="20" nzLg="18" nzXl="16">
    <h3>Add a Market</h3>
    <!-- Progress speps header -->
    <ul class="steps">
      <li><a class="active">Market Details</a></li>
      <li class="dots">
        <i class="fa fa-circle" style="font-size:10px"></i>
        <i class="fa fa-circle" style="font-size:8px"></i>
        <i class="fa fa-circle" style="font-size:6px;"></i>
      </li>
      <li><a>Underwriters</a></li>
      <li class="dots">
        <i class="fa fa-circle" style="font-size:10px"></i>
        <i class="fa fa-circle" style="font-size:8px"></i>
        <i class="fa fa-circle" style="font-size:6px;"></i>
      </li>
      <li><a>Industry Details</a></li>
    </ul>
    <!-- End Progress speps header -->
    <form nz-form [formGroup]="validateForm">
      <fieldset>
        <nz-form-item>
          <nz-form-label nzRequired nzFor="name" class="form-item-label">Market Name</nz-form-label>
          <nz-form-control>
            <input nz-input nzSize="large" formControlName="name" />
            <nz-form-explain *ngIf="validateForm.get('name').dirty && validateForm.get('name').errors">
              Market name is required
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <div nzFor="policies" class="form-item-label">
            Associate policies with this market (optional)
          </div>
          <nz-form-control>
            <app-policy-select [(ngModel)]="cover.policies" [ngModelOptions]="{ standalone: true }"
              [insurerId]="cover?.insurer?.id"></app-policy-select>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="!cover.id">
          <div class="form-item-label">Select classes of insurance for this market</div>
          <app-cover-search [(ngModel)]="insuranceClasses" [ngModelOptions]="{ standalone: true }"></app-cover-search>
        </nz-form-item>

        <nz-form-item *ngIf="!cover.id">
          <nz-form-label class="form-item-label">Select state(s) offered</nz-form-label>
          <app-location-search [(ngModel)]="states" [ngModelOptions]="{ standalone: true }"></app-location-search>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="name" class="form-item-label">Market Description</nz-form-label>
          <nz-form-control>
            <textarea nz-input placeholder="Add a short description of your Market" formControlName="notes"></textarea>
          </nz-form-control>
        </nz-form-item>
      </fieldset>
      <!--new Buttons Styling-->
      <div class="btn-container">
        <button nz-button nzSize="large" class="btn-cancel" (click)="cancel()">Cancel</button>
        <button nz-button nzSize="large" (click)="submit('exit')" [disabled]="!validateForm.valid"
          style="margin-left:auto;margin-right:10px;">
          Save & Exit
        </button>
        <button nz-button nzType="primary" nzSize="large" (click)="submit('next')" [disabled]="!validateForm.valid">
          Next<i nz-icon type="right"></i>
        </button>
      </div>
    </form>
  </div>
</div>
