import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ContactUs } from '../shared/contact-us';
import { MarketService } from '../shared/market.service';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {

  validateForm: FormGroup;

  constructor(private fb: FormBuilder, private marketService: MarketService, private message: NzMessageService) {
  }

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.valid) {
      let contactForm = new ContactUs();
      contactForm.name = this.validateForm.get('userName').value;
      contactForm.comment = this.validateForm.get('comment').value;
      contactForm.email = this.validateForm.get('email').value;
      contactForm.phone = this.validateForm.get('phoneNumber').value;
      this.marketService.contactUs(contactForm).subscribe();
      this.successMessage();
      this.validateForm.reset();
    }
  }

  

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required]],
      email: [null, [Validators.email]],
      comment: [null, [Validators.required]],
      phoneNumber : [ null, [ Validators.required ] ],

      remember: [true]
    });
  }

  successMessage(): void {
    this.message.create('success', 'Message sent!');
  }
}
