<app-industry-search [(ngModel)]="searchIndustries" name="industry" *ngIf="enableSearch"></app-industry-search>

<span *ngIf="isLoading"><i nz-icon type="loading"></i> Loading industries...</span>
<nz-tree class="industry-multiselect"
        [nzData]="nodes"
         nzCheckable="true"
         nzMultiple="true"
         (nzCheckBoxChange)="setCheckedIndustries($event)"
         (nzExpandChange)="nzEvent($event)"
         >
</nz-tree>
