import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/user.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(private userService: UserService) { }

  ngOnInit() {
  
  }


  isAuthenticated(): boolean {
    return this.userService.isAuthenticated();
  }

  isUserInRole(role: string): boolean {
    if (!this.isAuthenticated()) {
      return false;
    }
    return this.userService.isUserInRole(role);
  }


}
