import { HttpClient } from '@angular/common/http';
import { Cover } from './cover';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CoverService = /** @class */ (function () {
    function CoverService(http) {
        this.http = http;
    }
    CoverService.prototype.getCovers = function () {
        return this.http.get(environment.legacyRiskCoachApiBase + "/InsuranceClasses/GetParentInsuranceClasses?countryId=1", { headers: { 'X-Legacy': 'true' } }).pipe(map(function (d) {
            return d.map(function (data) {
                return new Cover().deserialize(data);
            });
        }));
    };
    CoverService.ngInjectableDef = i0.defineInjectable({ factory: function CoverService_Factory() { return new CoverService(i0.inject(i1.HttpClient)); }, token: CoverService, providedIn: "root" });
    return CoverService;
}());
export { CoverService };
