import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { UnderwritersComponent } from './underwriters/underwriters.component';
import { MarketsComponent } from './markets/markets.component';
import { AddMarketComponent } from './add-market/add-market.component';
import { AddMarketDetailComponent } from './add-market-detail/add-market-detail.component';
import { AddMarketIndustryComponent } from './add-market-industry/add-market-industry.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { CoverChangesComponent } from './cover-changes/cover-changes.component';
import { AdminAuthGuard } from '../search/admin-auth.guard';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    children: [
        {
            path: '',
            redirectTo: 'my-markets',
            pathMatch:'full'
        },
        {
        path: 'my-markets',
            component: MarketsComponent,
        canActivate:[AdminAuthGuard]
      },
      {
        path: 'underwriters',
          component: UnderwritersComponent,
          canActivate: [AdminAuthGuard]
      },
      {
        path: 'add-market',
          component: AddMarketComponent,
          canActivate: [AdminAuthGuard]
        },
        {
            path: 'add-market/:insurer',
            component: AddMarketComponent,
            canActivate: [AdminAuthGuard]
        },
      {
        path: 'edit-market/:id',
          component: AddMarketComponent,
          canActivate: [AdminAuthGuard]
      },
      {
        path: 'add-market-detail/:id',
          component: AddMarketDetailComponent,
          canActivate: [AdminAuthGuard]
      },
      {
        path: 'add-market-industry/:id',
          component: AddMarketIndustryComponent,
          canActivate: [AdminAuthGuard]
      },
      {
        path: 'approvals',
          component: ApprovalsComponent,
          canActivate: [AdminAuthGuard]
      },
      {
        path: 'cover-changes/:id',
          component: CoverChangesComponent,
          canActivate: [AdminAuthGuard]
      }
    ]
    
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
