import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { UserService } from '../user.service';
import { User } from '../user';
import { Router } from '@angular/router';
import { MarketService } from '../market.service';
import { GoogleAnalyticsService } from '../google-analytics.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  validateForm: FormGroup;
  message: string;

  constructor(private fb: FormBuilder, private userService: UserService, private router: Router, private marketService: MarketService, private googleAnalyticsService: GoogleAnalyticsService) {

  }



  ngOnInit(): void {
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]]//,
      //remember: [true]
    });
  }

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    let u = new User();
    u.userName = this.validateForm.get('userName').value;
    u.password = this.validateForm.get('password').value;
    this.userService.login(u)
      .subscribe(d => {
        this.marketService.getSavedCovers()
          .subscribe(c=>this.marketService.savedCovers=c);
        this.userService.getUser()
          .subscribe(u => {
            this.googleAnalyticsService.eventEmitter('Login', 'Credentials');
            this.message = '';
            let url: string = this.userService.redirectUrl;
            if (url) {
              this.router.navigate([url]);
            }
            else {
              this.router.navigate(['/']);
            }
          });
      },
        error => this.errorMessage(error));
  }

  errorMessage(error: any): void {
    if (error.error.error == "invalid_grant") {
      this.message = "Invalid credentials - please try again";
    }
    else {
      this.message = "An error has occured - please try again";
    }

  }


}

