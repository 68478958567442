import { Component, OnInit, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { SearchRequest } from '../../shared/search-request';

@Component({
  selector: 'app-search-tags',
  templateUrl: './search-tags.component.html',
  styleUrls: ['./search-tags.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchTagsComponent),
      multi: true
    }]
})

export class SearchTagsComponent implements OnInit, ControlValueAccessor {
  @Input() _values: SearchRequest;

  constructor() {
    this.onChange = (_: any) => { };
  }

  ngOnInit() {
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this._values = value;
    }
  }

  private onChange: (_: any) => void;

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched() { }

  handleClose(tag: any, type: string) {
    if (type === 'cover') {
      this._values.covers = this._values.covers.filter(a => a.id != tag.id);
    }
    if (type === 'industry') {
      this._values.industries = this._values.industries.filter(a => a.id != tag.id);
    }
    if (type === 'state') {
      this._values.states = this._values.states.filter(a => a.shortName != tag.shortName);
    }
    this.onChange(this._values);
  }
}
