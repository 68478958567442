import * as i0 from "@angular/core";
var GoogleAnalyticsService = /** @class */ (function () {
    function GoogleAnalyticsService() {
    }
    GoogleAnalyticsService.prototype.eventEmitter = function (eventAction, eventLabel, eventCategory, eventValue) {
        if (eventLabel === void 0) { eventLabel = null; }
        if (eventCategory === void 0) { eventCategory = null; }
        if (eventValue === void 0) { eventValue = null; }
        gtag('event', eventAction, {
            event_label: eventLabel,
            event_category: eventCategory,
            value: eventValue
        });
    };
    GoogleAnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function GoogleAnalyticsService_Factory() { return new GoogleAnalyticsService(); }, token: GoogleAnalyticsService, providedIn: "root" });
    return GoogleAnalyticsService;
}());
export { GoogleAnalyticsService };
