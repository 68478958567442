import { OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Insurer } from '../../shared/insurer';
import { InsurerService } from '../../shared/insurer.service';
var InsurerSelectComponent = /** @class */ (function () {
    function InsurerSelectComponent(insurerService) {
        this.insurerService = insurerService;
        this._selected = undefined;
        this.insurerOptions = [];
        this._checked = false;
        this.compareFn = function (i1, i2) { return i1 && i2 ? i1.id === i2.id : i1 === i2; };
        this.onChange = function (_) { };
    }
    Object.defineProperty(InsurerSelectComponent.prototype, "checked", {
        get: function () {
            return this._checked;
        },
        set: function (checked) {
            this._checked = checked;
            this.getInsurers();
        },
        enumerable: true,
        configurable: true
    });
    InsurerSelectComponent.prototype.ngOnInit = function () {
        this.getInsurers();
    };
    InsurerSelectComponent.prototype.getInsurers = function () {
        var _this = this;
        this.insurerService.getInsurers(1, this.checked)
            .subscribe(function (i) { return _this.insurerOptions = i; });
    };
    InsurerSelectComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    InsurerSelectComponent.prototype.registerOnTouched = function () { };
    InsurerSelectComponent.prototype.getSelectedInsurer = function () {
        return this._selected;
    };
    InsurerSelectComponent.prototype.writeValue = function (value) {
        if (value !== undefined) {
            this._selected = value;
        }
    };
    InsurerSelectComponent.prototype.setSelectedInsurer = function (_) {
        this._selected = _;
        if (typeof _ === "object") {
            this.onChange(this._selected);
        }
    };
    return InsurerSelectComponent;
}());
export { InsurerSelectComponent };
