import { Market } from "./market";

export class SearchResults {
  covers: Market[];
  totalResults: number;

  constructor() {
    this.covers = [];
    this.totalResults = 0;
  }

  clear(): void {
    this.covers.length = 0;
    this.totalResults = 0;
  }
}


