import { Component, OnInit } from '@angular/core';
//import { Menu, Icon, Button } from 'antd';
import { UserService } from '../user.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

 
  constructor(private userService: UserService, private router: Router) {}

  log(): void {
    console.log('click dropdown button');
  }


  isMobile = false;
  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 992;
    console.log(w);
    if (w < breakpoint) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.isMobile = this.getIsMobile();
    window.onresize = () => {
      this.isMobile = this.getIsMobile();
    };
  }
  
  visible: boolean;

  clickMe(): void {
    this.visible = false;
  }

  change(value: boolean): void {
    console.log(value);
  }




  logout(): void {
    this.userService.logout();
    this.router.navigate(['/']);
  }

  isAuthenticated(): boolean {
    return this.userService.isAuthenticated();
  }

  isUserInRole(role: string): boolean {
    if (!this.isAuthenticated()) {
      return false;
    }
    return this.userService.isUserInRole(role);
  }

  adminLink(): void {
    if (this.isUserInRole('Administrator')) {
      this.router.navigate(['/admin/approvals']);
    } else {
      this.router.navigate(['/admin/my-markets']);
    }
  }

  getUserName(): string {
    if (this.isAuthenticated && this.userService.currentUser) {
      return this.userService.currentUser.userName;
    }
    return '';
  }

  isSelected(url: string): boolean {
    return this.router.url === url;
  }






  
}
